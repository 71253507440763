import { AxiosResponse } from "axios";
import { Notes } from "./types"
import { User } from "../user/types";
import http from '../http'
import {auth} from '../../adapters/Firebase'
import {APIPaths} from '../paths';

interface NotesAPI {
    getIncomingNotes: () => Promise<AxiosResponse<Notes[]>>,
    getOutgoingNotes: () => Promise<AxiosResponse<Notes[]>>,
    createNote: (newnote:Notes) => Promise<AxiosResponse>,
    setRead: (id: string) => Promise<AxiosResponse>,
    checkClosed: (id: string, status:boolean) => Promise<AxiosResponse>,
    checkCompleted: (id: string, status:boolean) => Promise<AxiosResponse>
    userNotes: () => Promise<AxiosResponse<User[]>>,
    getNotesStats: () => Promise<AxiosResponse>,
}

export const notesAPI: NotesAPI = {
    getIncomingNotes: async () => {
        let idToken = await auth.currentUser!.getIdToken();
        console.log(idToken);
        let response = http.get(APIPaths.incomingNotes, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    getOutgoingNotes: async () => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.get(APIPaths.outgoingNotes, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    createNote: async (newnote) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.post(APIPaths.createNote, newnote, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    setRead: async (id) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.post(APIPaths.setRead, {uuid: id}, {headers: {'Authorization': 'Bearer ' + idToken}})
        
        return response
    },
    checkClosed: async (id, status) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.post(APIPaths.checkClosed, {uuid: id, status: status}, {headers: {'Authorization': 'Bearer ' + idToken}})
        
        return response
    },
    checkCompleted: async (id, status) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.post(APIPaths.checkCompleted, {uuid: id, status: status}, {headers: {'Authorization': 'Bearer ' + idToken}})
        
        return response
    },
    userNotes: async () => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.get(APIPaths.userNotes, {headers: {'Authorization': 'Bearer ' + idToken}})
        
        return response
    },
    getNotesStats: async () => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.get(APIPaths.getNotesStats, {headers: {'Authorization': 'Bearer ' + idToken}})
        
        return response
    }
}