import { motion } from 'framer-motion'
import React from 'react'
import { MasterInit } from './MasterInit'

export const PageAnimate:React.FC = ({children}) => {
    return (
        <>
        <motion.div initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}>
            {children}
        </motion.div>
        </>
    )
}
