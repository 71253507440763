import React, { useEffect, useState } from 'react'
import {Modal, Button, Spinner }from 'react-bootstrap';
import ModalWrapper from './ModalWrapper';
import { DateTime, Settings } from 'luxon';
import { useGlobalModalContext } from '../../context/GlobalModals';
import {delay} from '../../tools/delay'
import {Link } from 'react-router-dom'

interface showData {
    showGlobal: boolean
} 

export default function DataA({showGlobal=false}:showData) {
    const [show, setShow] = useState(showGlobal);

    const { hideModal } = useGlobalModalContext()

    const handleClose = async () => {
        setShow(false);
        await delay(500);
        hideModal();
    }
    

    const showModal = () => {
        setShow(true);
    }

    Settings.defaultLocale = "id";
    const dt = DateTime.now()
    dt.setLocale('id')


    function clearNotes() {
    }

    useEffect(() => {
        // getUser();
    }, [])

    

    return (
        <>
        <ModalWrapper>
            <Modal title={'Data Lengkap'}
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered
            size="xl"
            keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title style={{width:"100%"}}>
                        <div className="d-flex justify-content-between align-items-baseline">
                            <span>
                            Data Lengkap
                            </span>
                            <span className="ml-auto">
                            <Link to="/register/data" className="btn btn-sm btn-active-light-primary me-lg-1" onClick={(e) => handleClose()}>Edit Data </Link>
                            </span>        
                        </div>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-5">
                        <table>
                            <tbody>
                            <tr>
                                <td width="30%">
                                    <div className="fs-5 text-gray-400">No. WO</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td width="70%">
                                    <div className="fs-5 text-gray-800">SK00121001</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">JENIS KEND.</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">MOBL UMUM</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">KELAS KEND.</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">STANDARD</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">MERK</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">TOYOTA</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">TYPE</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">AGYA 1.2 G A/T</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">Nama Mobil</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">AGYA</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">No. Polisi</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">B-9912-PEI</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">NAMA PEMILIK</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">PT UTAMA RAYA</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">NO. RANGKA</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">MJESG8JE1KJE13225</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">NO. MESIN</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">J08EUFR05472</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">TAHUN</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">2019</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">KODE AGEN</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">
                                        <table>
                                            <tr>
                                                <td width="30%" className="text-danger">A001</td>
                                                <td className="fs-5 text-gray-400">Nama Agen:</td>
                                                <td width="30%" className="text-danger">SETO(MAZDA)</td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">JENIS CUSTOMER</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">REGULAR</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">WARNA</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">HIJAU TUA</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">STATUS TJH</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">H 2569 FG</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                        <div className="col-md-7">
                        <table>
                            <tbody>
                            <tr>
                                <td width="30%">
                                    <div className="fs-5 text-gray-400">DITANGGUNG OLEH</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td width="70%">
                                    <div className="fs-5 text-gray-800">ASURANSI (NAMA)</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">NO. POLIS</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">JK-AM0-0010371-00000-2020-06</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">HARGA PERTANGGUNGAN</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">250.000.000</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">NOMINAL 1 OR</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">300.000</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">PERIODE POLIS</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800"><table>
                                            <tr>
                                                <td className="fs-5 text-gray-400 px-2">DARI:</td>
                                                <td width="30%" className="text-center">10/5/21</td>
                                                <td className="fs-5 text-gray-400 px-2">SAMPAI:</td>
                                                <td width="30%" className="text-center">10/5/22</td>
                                            </tr>
                                        </table>
                                        </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">PERIODE STNK 5 TAHUN</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">
                                        <table>
                                            <tr>
                                                <td className="fs-5 text-gray-400 px-2">DARI:</td>
                                                <td width="30%" className="text-center">10/5/21</td>
                                                <td className="fs-5 text-gray-400 px-2">SAMPAI:</td>
                                                <td width="30%" className="text-center">10/5/22</td>
                                            </tr>
                                        </table>
                                        </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">PERIODE STNK PAJAK</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800"><table>
                                            <tr>
                                                <td className="fs-5 text-gray-400 px-2">DARI:</td>
                                                <td width="30%" className="text-center">10/5/21</td>
                                                <td className="fs-5 text-gray-400 px-2">SAMPAI:</td>
                                                <td width="30%" className="text-center">10/5/22</td>
                                            </tr>
                                        </table></div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">PERIODE SIM</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800"><table>
                                            <tr>
                                                <td className="fs-5 text-gray-400 px-2 ">DARI:</td>
                                                <td width="30%" className="text-center">10/5/21</td>
                                                <td className="fs-5 text-gray-400 px-2">SAMPAI:</td>
                                                <td width="30%" className="text-center">10/5/22</td>
                                            </tr>
                                        </table></div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">JENIS SIM</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">A</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">NO. HP 1</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800"><table>
                                            <tr>
                                                <td width="42%" >081655668221</td>
                                                <td className="fs-5 text-gray-400 px-2" width="27%">No. HP 2</td>
                                                <td width="40%" >086134658721</td>
                                            </tr>
                                        </table></div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">TANGGAL REGISTER</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">11/05/21</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">TANGGAL MOBIL MASUK</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">12/05/21</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">TANGGAL JANJI KELUAR</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800">16/05/21</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">KELAS KERUSAKAN</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800"></div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="fs-5 text-gray-400">NO. KLAIM</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-400">:</div>
                                </td>
                                <td>
                                    <div className="fs-5 text-gray-800"></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </ModalWrapper>
        </>
    )
}
