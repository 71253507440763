import React, { useEffect, useState } from 'react';
import { Accordion, useAccordionButton } from 'react-bootstrap';
import { notesAPI } from '../../api/notes';
import { Notes } from '../../api/notes/types';
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { SantosoStatus } from '../../store/const';
import { fetchAllIncomingNotes } from '../../store/notes-incoming';
import { fetchAllOutgoingNotes } from '../../store/notes-outgoing';
import CounterBox from '../dashboard/CounterBox';
import NoteCard from '../dashboard/NoteCard';
import SantosoSpinner from '../SantosoSpinner';

export default function NotesCard() {
    useEffect(() => {
        dispatch(fetchAllOutgoingNotes());
        dispatch(fetchAllIncomingNotes());
        getStats();
    }, [])
    const dispatch = useAppDispatch();
    const incoming = useAppSelector((state) => state.notesIncoming);
    const outgoing = useAppSelector((state) => state.notesOutgoing);
    const [incomingNotes, setIncomingNotes] = useState<Notes[]>([])
    const [outgoingNotes, setOutgoingNotes] = useState<Notes[]>([])
    const [isLoadingOut, setLoadingOut] = useState(false);
    const [isLoadingIn, setLoadingIn] = useState(false);
    const [isLoadingStats, setLoadingStats] = useState(true);
    const [readAccordionState, setReadAccordionState] = useState('1');
    const [notesStats, setNotesStats] = useState<any>({});

    const decoratedOnClick = useAccordionButton(
        'notesIncoming',
        () => {
            setReadAccordionState(readAccordionState==='notesIncoming'?'1':'notesIncoming');
        },
      );

    async function getStats() {
        setLoadingStats(true);
        const res = await notesAPI.getNotesStats();
        setNotesStats(res.data);
        setLoadingStats(false);
    }



    async function handleCloseCheck(id: string, index:number) {
        try{ 

            var outgoing = [...outgoingNotes];
            outgoing[index].closedAt = (outgoing[index].closedAt!== null && outgoing[index].closedAt!==undefined) ? undefined : new Date();
            setOutgoingNotes(outgoing);
            setLoadingOut(true);
            await notesAPI.checkClosed(id, (outgoing[index].closedAt?true:false));
            refreshNotes();
        } catch (e) {
            console.log(e);
        }
    }

    async function handleCompleteCheck(id: string, index:number) {
        try {
            console.log("diconteng");
            var incoming = [...incomingNotes];
            incoming[index].completedAt = (incoming[index].completedAt!==null && incoming[index].completedAt!==undefined) ? undefined : new Date();
            setIncomingNotes(incoming);
            setLoadingIn(true);
            await notesAPI.checkCompleted(id, (incoming[index].completedAt?true:false));
            refreshNotes();
        }catch (e) {
            console.log(e);
        }
    
    }

    function handleAddClick(event:any) {
        event.stopPropagation();
        showModal(MODAL_TYPES.CREATE_MODAL, {
            title: "Kirim Notes Baru",
            confirmBtn: "Save",
            afterSubmit: refreshNotes
          });
    }

    function refreshNotes(force=false) {
        if (force) {
            setIncomingNotes([]);
            setOutgoingNotes([]);
        }
        dispatch(fetchAllOutgoingNotes());
        dispatch(fetchAllIncomingNotes());
        getStats();
    }

    const { showModal } = useGlobalModalContext();
  return <>
  <div className="card ">
    {/* <!--begin::Header--> */}
    <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
            <span className="fw-bolder mb-2 text-dark">Notes</span>
        </h3>
        <div className="card-toolbar">
            <button onClick={() => refreshNotes(true)} className="btn btn-sm btn-light-secondary">
            <span className="svg-icon svg-icon-primary svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z" fill="#000000" fill-rule="nonzero"/>
                </g>
                </svg>
            </span>
            </button>
        </div>
    </div>
    {/* <!--end::Header--> */}
    {/* <!--begin::Body--> */}
    <div className="card-body pt-5">
        <div className="row">
            <div className="col-3">
                <CounterBox subtitle='Belum Dilihat (Notes Masuk)' number={notesStats['unread']??0} isLoading={isLoadingStats} borderTag='border-warning'/>
            </div>
            <div className="col-3">
                <CounterBox subtitle='Belum Selesai  (Notes Masuk)' number={notesStats['notDone']??0} isLoading={isLoadingStats} borderTag='border-warning'/>
            </div>
            <div className="col-3">
                <CounterBox subtitle='Notes Dilihat  (Notes Keluar)' number={notesStats['seen']??0} isLoading={isLoadingStats} borderTag='border-primary'/>
            </div>
            <div className="col-3">
                <CounterBox subtitle='Notes Selesai  (Notes Keluar)' number={notesStats['completed']??0} isLoading={isLoadingStats} borderTag='border-primary'/>
            </div>
        </div>
        <Accordion defaultActiveKey='1'>
            <Accordion.Item eventKey="notesIncoming">
                <Accordion.Button className="" onClick={decoratedOnClick}>
                    <div className="acc-btn fw-bold">
                    <span className="fs-4">NOTES MASUK</span>
                    <span className="pull-right float-right">({incomingNotes.length})</span>
                    </div>
                </Accordion.Button>
                <Accordion.Body className="scroll-card">
                        {
                            {
                                [SantosoStatus.LOADED]: incoming.data.length==0? <p className="text-center">Hore, belum ada notes masuk!</p>: incoming.data.map((val:Notes, index) => {
                                    return <NoteCard key={val.uuid} note={val}  handleCloseCheck={() => {handleCompleteCheck(val!.uuid!, index)}} isOutgoing={false}/>
                                    }),
                                [SantosoStatus.LOADING]: <SantosoSpinner />,
                                [SantosoStatus.ERROR]: <p className="text-center">Error: {incoming.error?.message ?? JSON.stringify(outgoing.error)}</p>
                            } [incoming.status]
                        }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey='1'>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <div className="acc-btn fw-bold">
                        <span className="fs-4">NOTES KELUAR</span>
                        <span><button className="btn btn-sm btn-secondary" onClick={e=> handleAddClick(e)}><i className="fa fa-plus"></i></button>&nbsp;&nbsp;({outgoingNotes.length})</span>
                    </div>
                </Accordion.Header>
                <Accordion.Body className="scroll-card">
                        {
                            {
                                [SantosoStatus.LOADED]: outgoing.data.length==0? <p className="text-center">Hore, belum ada notes keluar!</p>: outgoing.data.map((val:Notes, index) => {
                                    return <NoteCard key={val.uuid} note={val}  handleCloseCheck={() => handleCloseCheck(val!.uuid!, index)} isOutgoing={true}/>
                                    }),
                                [SantosoStatus.LOADING]: <SantosoSpinner />,
                                [SantosoStatus.ERROR]: <p className="text-center">Error: {outgoing.error?.message ?? JSON.stringify(outgoing.error)}</p>
                            } [outgoing.status]
                        }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
    </div>
  </>;
}
