import React, { useState } from 'react'
import {Modal, Spinner }from 'react-bootstrap';
import ModalWrapper from './ModalWrapper';
import { useGlobalModalContext } from '../../context/GlobalModals';
import Select, { SingleValue } from 'react-select'
import {delay} from '../../tools/delay'
import http from '../../api/http';
import { asuransiAPI } from '../../api/asuransi';
import { useNavigate } from "react-router-dom";
import { PageAnimate } from '../../components/PageAnimate'
import { Asuransi } from '../../api/asuransi/types';


interface showData {
    showGlobal: boolean
    afterSubmit: Function
} 

export default function InsertAsuransiModal({showGlobal=false, afterSubmit}:showData) {
    const [show, setShow] = useState(showGlobal);

    const { hideModal } = useGlobalModalContext()

    const handleClose = async () => {
        setShow(false);
        await delay(500);
        hideModal();
    }

    function callDelete() {
        // return http.post(url,{id: id})
        return delay(1000);
    }

    const showModal = () => {
        setShow(true);
    }

    const [kodeAsuransi, setKodeAsuransi] = useState("");
    const [namaAsuransi, setNamaAsuransi] = useState("");
    const [statusFotoBB, setStatusFotoBB] = useState("");
    const [statusSalvage, setStatusSalvage] = useState("");
    const [telpKantor, setTelpKantor] = useState("");
    const [staffKlaim, setStaffKlaim] = useState("");
    const [noHpStaffKlaim, setNoHpStaffKlaim] = useState("");
   

    const statusFotoBBOpts:any[] =[
		{
			value: "JEJER",
			label: "JEJER"
		},
		{
			value: "JEJER PLAT NOMOR",
			label: "JEJER PLAT NOMOR"
		},
	]

    const statusSalvageOpts:any[] =[
        {
			value: "KIRIM",
			label: "KIRIM"
		},
        {
			value: "KIRIM TENDER",
			label: "KIRIM TENDER"
		},
        {
			value: "KADANG KIRIM",
			label: "KADANG KIRIM"
		},
        {
			value: "TIDAK KIRIM",
			label: "TIDAK KIRIM"
		},
    ]

    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsSubmitting(true);

        const jsonInput:Asuransi = {
            kodeAsuransi: kodeAsuransi,
            namaAsuransi: namaAsuransi,
            statusFotoBB: statusFotoBB,
            statusSalvage: statusSalvage,
            telpKantor: telpKantor,
            staffKlaim: staffKlaim,
            noHpStaffKlaim: noHpStaffKlaim,
        };

        const AddAsuransi = await asuransiAPI.createAsuransi(jsonInput);
        setIsSubmitting(false);
        afterSubmit();
        handleClose();


    }

    const [isSubmitting, setIsSubmitting] = useState(false);

    async function submitDelete(){
        try {
            setIsSubmitting(true);
            await callDelete();
            setIsSubmitting(false);
            handleClose();
        } catch (e) {
            setIsSubmitting(false);
        }
        
    }

    const [selectedStatus, setSelectedStatus] = useState<SingleValue<string>|null>()
    const [selectedSalvage, setSelectedSalvage] = useState<SingleValue<string>|null>()

    type selectValue = {
		value: string,
		label: string
	}

	async function handleSelectedStatus(e:SingleValue<string>) {
		setSelectedStatus(e);
		const val = ((e as unknown) as selectValue).value
        setStatusFotoBB(val)
		// await setPrefix(val)
		// console.log(val);
	}
	async function handleSelectedSalvage(e:SingleValue<string>) {
		setSelectedSalvage(e);
		const val = ((e as unknown) as selectValue).value
        setStatusSalvage(val)
		// await setPrefix(val)
		// console.log(val);
	}



    return (
        <>
        <ModalWrapper>
            <Modal title={'INSERT DATA'}
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered
            size="lg"
            keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>TAMBAH ASURANSI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form className="form mb-15" id="kt_careers_form">
                            {/* <!--begin::Input group--> */}
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 col-6">
                                
                                {/* <!--begin::Table body--> */}
                                <tbody>
                                    <tr>
                                        <td>
                                            <a   className="text-dark fw-bolder text-hover-primary fs-6">KODE ASURANSI :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setKodeAsuransi(e.target.value)} value={kodeAsuransi} required />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a   className="text-dark fw-bolder text-hover-primary fs-6">NAMA ASURANSI :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setNamaAsuransi(e.target.value)} value={namaAsuransi} required />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a   className="text-dark fw-bolder text-hover-primary fs-6">STATUS FOTO BB :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                        <Select options={statusFotoBBOpts} value={selectedStatus} onChange={data =>handleSelectedStatus(data)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a   className="text-dark fw-bolder text-hover-primary fs-6">STATUS SALVAGE :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                        <Select options={statusSalvageOpts} value={selectedSalvage} onChange={data =>handleSelectedSalvage(data)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a   className="text-dark fw-bolder text-hover-primary fs-6">TELPON KANTOR :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setTelpKantor(e.target.value)} value={telpKantor} required />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a   className="text-dark fw-bolder text-hover-primary fs-6">STAFF KLAIM :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setStaffKlaim(e.target.value)} value={staffKlaim} required />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a   className="text-dark fw-bolder text-hover-primary fs-6">NO HP STAFF KLAIM :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                        <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setNoHpStaffKlaim(e.target.value)} value={noHpStaffKlaim} required/>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                                {/* <!--end::Table body--> */}
                            </table>
                </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={handleClose}>Batal</button>
                    <button type="button" className="btn btn-primary" disabled={kodeAsuransi ==='' || isSubmitting} onClick={handleSubmit}>{isSubmitting? <Spinner animation="border" style={{
                // marginLeft:"50vw",
                // display:"flex",
                // flex: 1,
                // marginTop:"50vh",
                justifyContent: 'center',
                alignItems:'center'}} /> : 'SIMPAN'}</button>
                </Modal.Footer>
            </Modal>
        </ModalWrapper>
        </>
    )
                    }
