import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Modal,Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


interface IMultiImgUpload {
    target: string
    images: any[],
    onChangeCallback: any;
    maxNumber: number;
}

export default function MultiImgUpload({target, images=[], onChangeCallback, maxNumber}:IMultiImgUpload) {
 
    const onImageClick = (imgUrl:string) => {
        setImgToShow(imgUrl);
        setImageShow(true);
    }

    const [uplLater, setUplLater] = useState(false);
    
    const [imageShow, setImageShow] = useState(false);
	const [imgToShow, setImgToShow] = useState("");

    const handleImageClose = async () => {
        setImageShow(false);
        setImgToShow("");
    }

    const onChange = (img:any, id:any) => {

        onChangeCallback(target, img);


    }

    
 
 
 return <>
 <h1 className="required">{target.replace(/_/g," ")}</h1>
  <ImageUploading
  multiple
  value={images}
  onChange={onChange}
  maxNumber={maxNumber}
  dataURLKey="data_url"
  >
  {({
  imageList,
  onImageUpload,
  onImageRemoveAll,
  onImageUpdate,
  onImageRemove,
  isDragging,
  dragProps,
  }) => (
  // write your building UI
  <div className="d-flex flex-wrap">
      {!uplLater && <button
      style={isDragging ? { color: 'red' } : undefined}
      onClick={onImageUpload}
      className="btn btn-lg btn-secondary my-3 mx-2"
      {...dragProps}
      >
      <FontAwesomeIcon icon={faPlus} />
      </button> }
      {images.length<=0 && <button
      style={isDragging ? { color: 'red' } : undefined}
      onClick={e => setUplLater(!uplLater)}
      className="btn btn-lg btn-secondary my-3 mx-2"
      {...dragProps}
      >
      SUSUL
      </button> }
      &nbsp;
      {imageList.map((image, index) => (
          // {/* <!--begin::Image input--> */}
          <>
              <div className="image-input image-input-outline mr-6 px-2 my-3" data-kt-image-input="true" style={{backgroundImage : `url(${image['data_url']})`}}>
                  {/* <!--begin::Image preview wrapper--> */}
                              <div className="image-input-wrapper w-200px h-130px " style={{backgroundImage: `url(${image['data_url']})`, backgroundPosition:'center'}} onClick={e => onImageClick(image['data_url'])}></div>
                              {/* <!--end::Image preview wrapper--> */}
                  
                  {/* <!--begin::Edit button--> */}
                  <label onClick={() => onImageUpdate(index)} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                  data-kt-image-input-action="change"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Change image">
                      <i className="bi bi-pencil-fill fs-7"></i>

                      {/* <!--begin::Inputs--> */}
                      <input type="file" name="avatar" accept=".png, .jpg, .jpeg" multiple/>
                      <input type="hidden" name="avatar_remove" />
                      {/* <!--end::Inputs--> */}
                  </label>
                  {/* <!--end::Edit button--> */}

                  {/* <!--begin::Cancel button--> */}
                  <span onClick={() => onImageRemove(index)} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                  data-kt-image-input-action="cancel"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Cancel avatar">
                      <i className="bi bi-x fs-2"></i>
                  </span>
                  {/* <!--end::Cancel button--> */}

                  {/* <!--begin::Remove button--> */}
                  <span onClick={() => onImageRemove(index)} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                  data-kt-image-input-action="remove"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Remove image">
                      <i className="bi bi-x fs-2"></i>
                  </span>
              {/* <!--end::Remove button--> */}
          </div>
      {/* // <!--end::Image input--> */}
      {/* <div key={index} className="image-item">
          <img src={image['data_url']} alt="" width="100" />
          <div className="image-item__btn-wrapper">
          <button >Update</button>
          </div>
      </div> */}
      </>
      ))}
  </div>
  )}
  </ImageUploading>
  <Modal title={'Preview image'}
            show={imageShow}
            onHide={handleImageClose}
            backdrop
            centered
            size="xl"
            keyboard>
                <Modal.Body>
                    <Image src={imgToShow} style={{maxWidth:'100%', maxHeight: '100%'}}/>
                </Modal.Body>
            </Modal>
  <div className="mb-5 pb-5"></div>
</>;
}
