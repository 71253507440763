import React, { Component, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLayout } from '../../context/core';
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';
import DefaultKlaimButtons from '../buttons/DefaultKlaimButtons';
import NumberFormat from 'react-number-format';
import Select, {PropsValue, SingleValue, StylesConfig } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
class UmumOld extends Component {
    divRef: any;
    setDivRefs: (element: any) => void;

    
    constructor(props:any) {
        super(props)

        this.state = {
            height: 0
        }

        this.divRef = null;

        this.setDivRefs = element => {
            this.divRef = element;
        };
    }

    componentDidMount(){
        const height = this.divRef.clientHeight
        this.setState({ height });

    }
    

    render() {
        const children  = this.props.children;

        return (
            <>
            <div className="toolbar d-flex flex-fill flex-row flex-grow" id="kt_toolbar" style={{height:"fit-content"}} ref={this.setDivRefs}>
            {/* <!--begin::Navbar--> */}
            <div className="card d-flex flex-fill flex-grow">
                <div className="card-body pt-9 pb-0 ">
                    {/* <!--begin::Details--> */}
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                        {/* <!--begin::Wrapper--> */}
                        <div className="flex-grow-1">
                            {/* <!--begin::Head--> */}
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                {/* <!--begin::Details--> */}
                                <div className="d-flex flex-column flex-fill flex-grow">
                                    {/* <!--begin::Status--> */}
                                    {/* <div className="d-flex align-items-center mb-1"> */}
                                        {/* <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3">DATA UMUM</a>                                </div> */}
                                    {/* <!--end::Status--> */}
                                    <div className="row mobile-capped-height">
                                        <div className="col-md-6">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td width="50%">
                                                        <div className="fs-5 text-gray-400 text-ellipsis">No. WO</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td width="60%">
                                                        <div className="fs-5 text-gray-800 text-ellipsis">SK00121001</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400 text-ellipsis">Nama Mobil</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400 ">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800 text-ellipsis">AGYA</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400 text-ellipsis">No. Polisi</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800 text-ellipsis">B-9912-PEI</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400 text-ellipsis">Ditanggung Oleh</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800 text-ellipsis">Pribadi</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400 text-ellipsis">Nama Pemilik</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800 text-ellipsis">PT Utama Raya</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6 text-ellipsis">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width="50%">
                                                            <div className="fs-5 text-gray-400 text-ellipsis">Jenis Customer</div>
                                                        </td>
                                                        <td>
                                                            <div className="fs-5 text-gray-400">:</div>
                                                        </td>
                                                        <td width="60%">
                                                            <div className="fs-5 text-gray-800">Regular</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="fs-5 text-gray-400 text-ellipsis">Nama Agen</div>
                                                        </td>
                                                        <td>
                                                            <div className="fs-5 text-gray-400">:</div>
                                                        </td>
                                                        <td>
                                                            <div className="fs-5 text-gray-800 text-ellipsis">SETO</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="fs-5 text-gray-400 text-ellipsis">Tanggal Masuk</div>
                                                        </td>
                                                        <td>
                                                            <div className="fs-5 text-gray-400">:</div>
                                                        </td>
                                                        <td>
                                                            <div className="fs-5 text-gray-800 text-ellipsis">12/05/2021</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="fs-5 text-gray-400 text-ellipsis">Tanggal Keluar</div>
                                                        </td>
                                                        <td>
                                                            <div className="fs-5 text-gray-400">:</div>
                                                        </td>
                                                        <td>
                                                            <div className="fs-5 text-gray-800 text-ellipsis">12/05/2021</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="fs-5 text-gray-400 text-ellipsis">Kelas Kerusakan</div>
                                                        </td>
                                                        <td>
                                                            <div className="fs-5 text-gray-400">:</div>
                                                        </td>
                                                        <td>
                                                            <div className="fs-5 text-gray-800 text-ellipsis">Sedang</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end::Details--> */}
                                {/* <!--begin::Actions--> */}
                                <div className="d-flex mb-4">
                                    <a href="#" className="btn btn-sm btn-bg-light btn-active-color-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Download</a>
                                    <a href="#" className="btn btn-sm btn-bg-light btn-active-color-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Print</a>
                                    <a href="#" className="btn btn-sm btn-bg-light btn-active-color-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Info Detail</a>
                                </div>
                                {/* <!--end::Actions--> */}
                            </div>
                            {/* <!--end::Head--> */}
                            <div className="divider separator my-3" />
                            {/* <!--begin::Info--> */}
                            <div className="d-flex flex-wrap flex-fill flex-grow flex-stack pt-3">
                                {children}
                            </div>
                            {/* <!--end::Info--> */}
                        </div>
                        {/* <!--end::Wrapper--> */}
                    </div>
                    {/* <!--end::Details--> */}
                </div>
            </div>
            {/* <!--end::Navbar--> */}
            </div>
            </>
        )
    }
}

type UmumType = {
    onHeightChange: Function
    topButtons?: React.ReactNode
}

const Umum:React.FC<UmumType> = ({children, onHeightChange, topButtons=<DefaultKlaimButtons />}) => {

    const divRef = useRef<HTMLDivElement|null>(null!);
    const [height, setHeight] = useState(0);

    const getMyHeight = () => {
        const h:number  = divRef.current?.clientHeight??0;
        setHeight(h);
        console.log("GOTCHA: ", h);
    }

    useEffect(() => {
        getMyHeight();
        window.addEventListener("resize", getMyHeight);
    },[])

    const {config} = useLayout();

    useEffect(() => {
        getMyHeight();
    }, [config]);

    useEffect(() => {
        onHeightChange(height)
    }, [height]);


    const statusLapangan = [{value: 'KENTENG', label: "KENTENG"}, {value: 'CAT', label: "CAT"}];
    const statusAdmin = [{value: 'INPUT ADMIN.', label: "INPUT ADMIN."}];
    const statusWO = [{value: 'EKSEKUSI WO', label: "EKSEKUSI WO"}, {value: 'PERSIAPAN KLAIM', label: "PERSIAPAN KLAIM"}, {value: 'CEK A', label: "CEK A"}, {value: 'REVISI CEK A', label: "REVISI CEK A"}, {value: 'KLAIM', label: "KLAIM"}, {value: 'REVISI KLAIM', label: "REVISI KLAIM"}, {value: 'CEK B', label: "CEK B"}, {value: 'REVISI CEK B', label: "REVISI CEK B"}, {value: 'TUNGGU SPK', label: "TUNGGU SPK"}];

    const selectStyles: StylesConfig = {
        control: (styles) => ({...styles, padding:'0px', minHeight:'none', }),
        option: (styles) => ({...styles, padding: '0px', minHeight:'none', }),
        input: (styles) => ({...styles, padding: '0px', minHeight:'none', }),
        singleValue: (styles) => ({...styles, padding: '0px', minHeight:'none', }),
        valueContainer: (styles) => ({...styles, padding: '0px', minHeight:'none', }),
        indicatorsContainer: (styles) => ({...styles, padding: '0px', minHeight:'none', }),
        dropdownIndicator: (styles) => ({...styles, padding: '0px', minHeight:'none', }),
        container: (styles) => ({...styles, marginLeft:'5px', minWidth:'125px', display:'block'}),
        menuList: (styles) => ({...styles, zIndex:'150', position:'static'}),
        menu: (styles) => ({...styles, zIndex:'149', position:'static'})
        
    }


    return (
        <>
        <div className="toolbar d-flex flex-fill flex-row flex-grow" id="kt_toolbar" style={{height:"fit-content"}} ref={divRef}>
        {/* <!--begin::Navbar--> */}
        <div className="card d-flex flex-fill flex-grow">
            <div className={`card-body pt-3 pb-0 toggle-pinned-content ${!config.toolbar.showPinnedContent?'disabled-pinned-content':''} `}>
                {/* <!--begin::Details--> */}
                <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                    {/* <!--begin::Wrapper--> */}
                    <div className="flex-grow-1">
                        {/* <!--begin::Head--> */}
                        {config.toolbar.showPinnedContent===true && <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            {/* <!--begin::Details--> */}
                            <div className="d-flex flex-column flex-fill flex-grow" style={{minWidth:'65vw'}}>
                                {/* <!--begin::Status--> */}
                                {/* <div className="d-flex align-items-center mb-1"> */}
                                    {/* <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3">DATA UMUM</a>                                </div> */}
                                {/* <!--end::Status--> */}
                                <div className="row mobile-capped-height text-ellipsis">
                                    <div className="col-md-6 col-xl-3 order-1">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td width="50%">
                                                    <div className="fs-5 text-gray-400">No. WO</div>
                                                </td>
                                                <td>
                                                    <div className="fs-5 text-gray-400">:</div>
                                                </td>
                                                <td width="60%">
                                                    <div className="fs-5 text-gray-800">SK00121001</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="fs-5 text-gray-400">Nama Mobil</div>
                                                </td>
                                                <td>
                                                    <div className="fs-5 text-gray-400">:</div>
                                                </td>
                                                <td>
                                                    <div className="fs-5 text-gray-800">AGYA</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="fs-5 text-gray-400">No. Polisi</div>
                                                </td>
                                                <td>
                                                    <div className="fs-5 text-gray-400">:</div>
                                                </td>
                                                <td>
                                                    <div className="fs-5 text-gray-800">B-9912-PEI</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="fs-5 text-gray-400">No. Rangka</div>
                                                </td>
                                                <td>
                                                    <div className="fs-5 text-gray-400">:</div>
                                                </td>
                                                <td>
                                                    <div className="fs-5 text-gray-800 c-pointer text-hover-primary">MJESG8JE1KJE13225</div>
                                                </td>
                                            </tr>
                                            
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6 col-xl-3 order-3 order-xl-2">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">Ditanggung Oleh</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800">Pribadi</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">Nama Pemilik</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800">PT Utama Raya</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%">
                                                        <div className="fs-5 text-gray-400">Jenis Customer</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td width="60%">
                                                        <div className="fs-5 text-gray-800">Regular</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">Nama Agen</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800">SETO</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                    </div>
                                    <div className="col-md-6 col-xl-3 order-2 order-xl-3">
                                        <table>
                                            <tbody>
                                            <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">Tanggal Masuk</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800">12/05/2021</div>
                                                    </td>
                                                </tr>
                                            <tr>
                                                    <td width={'50%'}>
                                                        <div className="fs-5 text-gray-400">Tanggal Keluar</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td width={'60%'}>
                                                        <div className="fs-5 text-gray-800">12/05/2021</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">Kelas Kerusakan</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800">Sedang</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400 text-ellipsis">Prediksi Est</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5"><NumberFormat thousandSeparator={'.'} displayType='text' decimalSeparator=',' disabled value={45000000}/></div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                    </div>
                                    <div className="col-md-6 col-xl-3 order-4 pull-col-20px">
                                        <table>
                                            <tbody>
                                            <tr>
                                                    <td width={'50%'}>
                                                        <div className="fs-5 text-gray-400 text-ellipsis">St. WO</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td width={'60%'}>
                                                        <div className="fs-5 text-gray-800 text-ellipsis  d-inline-flex align-items-stretch"><Select styles={selectStyles} isSearchable={false} onChange={(data) =>{console.log(data)}} options={statusWO} value={(statusWO.at(0) as unknown) as PropsValue<{value:string, label:string}>}></Select>&nbsp;<button className='btn btn-sm btn-icon btn-primary btn-history-dropdown'><FontAwesomeIcon icon={faHistory} /></button></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400 text-ellipsis">St. Lapgn.</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800 text-ellipsis d-inline-flex align-items-stretch"><Select styles={selectStyles} isSearchable={false} onChange={(data) =>{console.log(data)}} options={statusLapangan} value={(statusLapangan.at(0) as unknown) as PropsValue<{value:string, label:string}>}></Select>&nbsp;<button className='btn btn-sm btn-icon btn-primary btn-history-dropdown'><FontAwesomeIcon icon={faHistory} /></button></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="fs-5 text-gray-400 text-ellipsis">St. Admin</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-400">:</div>
                                                    </td>
                                                    <td>
                                                        <div className="fs-5 text-gray-800 text-ellipsis d-inline-flex align-items-stretch"><Select styles={selectStyles} isSearchable={false} onChange={(data) =>{console.log(data)}} options={statusAdmin} value={(statusAdmin.at(0) as unknown) as PropsValue<{value:string, label:string}>}></Select>&nbsp;<button className='btn btn-sm btn-icon btn-primary btn-history-dropdown'><FontAwesomeIcon icon={faHistory} /></button></div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end::Details--> */}
                            {/* <!--begin::Actions--> */}
                            <div className="d-flex flex-sm-column flex-sm-fill flex-sm-grow align-items-end ">
                            <div className="d-flex flex-xxl-column flex-row mt-5 mt-lg-0 align-items-end align-items-xxl-stretch">
                                {topButtons}
                            </div>
                            </div>
                            {/* <!--end::Actions--> */}
                        </div>}
                        {/* <!--end::Head--> */}
                        {config.toolbar.showPinnedContent===true && <div className="divider separator my-3" /> }
                        {/* <!--begin::Info--> */}
                        <div className="d-flex flex-wrap flex-fill flex-grow flex-stack pt-3">
                            {children}
                        </div>
                        {/* <!--end::Info--> */}
                    </div>
                    {/* <!--end::Wrapper--> */}
                </div>
                {/* <!--end::Details--> */}
            </div>
        </div>
        {/* <!--end::Navbar--> */}
        </div>
        </>
    )
}


export default Umum;