import React from 'react';
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';

export default function DefaultKlaimButtons() {

    const {showModal} = useGlobalModalContext();


    function handleInfoDetail(){
        showModal(MODAL_TYPES.DATA_A_MODAL, {});
    }

    function handleDownload(){
        showModal(MODAL_TYPES.DOWNLOAD_WO, {});
    }
    function handlePrint(){
        showModal(MODAL_TYPES.PRINT_WO, {});
    }


    return <>
          <button className="btn btn-sm btn-bg-light mb-1 btn-active-color-primary me-3" onClick={handleDownload}>Download</button>
          <button className="btn btn-sm btn-bg-light mb-1 btn-active-color-primary me-3" onClick={handlePrint} >Print</button>
          <button className="btn btn-sm btn-bg-light mb-1 btn-active-color-primary me-3" onClick={handleInfoDetail}>Info Detail</button>
        </>;
}
