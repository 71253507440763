import React, { useCallback, useEffect, useState } from 'react'
import Sketsa from './sketsa'

export default function SketsaController() {
    const carStateTypes: {[key:string]: string} = {};
    const [carState, setCarState] = useState(carStateTypes);

    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false);
    const [currentlySelected, setCurrentlySelected] = useState("");
    
    useEffect(() => {
        document.addEventListener("click", handleClickDrop);
        return () => {
          document.removeEventListener("click", handleClickDrop);
        };
      });
    
    const handleClick = useCallback(
        (event) => {
          event.preventDefault();
        const thisId = event.target.parentNode.id;
        setCurrentlySelected(thisId);
        setAnchorPoint({ x: event.clientX, y: event.clientY });
        setShow(true);
        },
        [setShow, setAnchorPoint]
      );

    const handleMenuClick = useCallback(
        (event) => {
          event.preventDefault();
          const thisId = event.target.id;
        setCarState({
            ...carState,
            [currentlySelected]: thisId
        });
        setShow(false);
        console.log(carState);
        },
        [show]
      );

      const handleClickDrop = useCallback((event) => (show ? setShow(false) : null), [show]);

    const getStatus = (id:string) => {
        if (carState && carState[id]) {
            return carState[id];
        }
        return null;
    }

    const getColor = (id:string) => {
        if (carState) {
            if(carState[id] === 'NR') { return "#FF8C00" }
            else if (carState[id] === 'G/C' ||  carState[id] === 'GANTI') { return '#FF0000'}
            else if (carState[id] === 'K/C') {return '#FFFF00'}
        }
        return "#FEFEFE"
    }

    const getMenuOptionsClass = (id: string) => {
        if (carState && carState[currentlySelected] && carState[currentlySelected] === id) {
            return "clickableSvg clickMenu__list clickMenu__selected"
        }
        return "clickableSvg clickMenu__list"
    }
    return (
        <div>
            <Sketsa handleClick={handleClick} getColor={getColor} getStatus={getStatus}></Sketsa>
            {show ? (
                <ul
                className="clickMenu"
                style={{
                    top: anchorPoint.y,
                    left: anchorPoint.x
                }}
                >
                <li id="NR" className={getMenuOptionsClass("NR")}  onMouseDown={handleMenuClick}>NR&nbsp;</li>
                <li id="K/C" className={getMenuOptionsClass("K/C")} onMouseDown={handleMenuClick}>K/C&nbsp;</li>
                <li id="G/C" className={getMenuOptionsClass("G/C")} onMouseDown={handleMenuClick}>G/C&nbsp;</li>
                <li id="GANTI" className={getMenuOptionsClass("GANTI")} onMouseDown={handleMenuClick}>Ganti</li>
                {getStatus(currentlySelected) ? <><hr className="dividerMenu" /><li id="" className={getMenuOptionsClass("")} onMouseDown={handleMenuClick}>Batalkan</li></> : ''}
                </ul>
            ) : (
                <> </>
            )}
        </div>
    )
}
