import React, { useEffect } from 'react'
import ScrollTop from '../../components/Scroll_Top'
import KlaimBaru from '../../components/modals/KlaimBaru'
import Navbar from '../../components/Navbar'
import Asides from '../../components/Asides'
import Topbar from '../../components/Topbar'
import Footers from '../../components/Footers'
import { Outlet } from 'react-router-dom'
import { MasterInit } from '../../components/MasterInit'
import { useLayout, ILayout, PageDataProvider } from '../../context/core'

export const DefaultLayout: React.FC = ({children}) => {
	const {config, setLayout} = useLayout();

    let newLayoutConfig:ILayout = {
        ...config,
    };
    newLayoutConfig.toolbar.layout = 'toolbar1';

	useEffect(() => {
		console.log("setting useLayout");
		setLayout(newLayoutConfig);

	},[]);

    return (
        <>
		<PageDataProvider>
        {/* <!--begin::Head--> */}
        
        {/* <!--end::Head--> */}
        {/* <!--begin::Body--> */}
	{/* <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed" 
	style={{['--kt-toolbar-height' as any]:"0px", ['--kt-toolbar-height-tablet-and-mobile' as any]:"0px"}}
	> */}
		{/* <!--begin::Main--> */}
		{/* <!--begin::Root--> */}
		{/* <div className="d-flex flex-column flex-root"> */}
			{/* <!--begin::Page--> */}
			<div className="page d-flex flex-row flex-column-fluid">
				 <Asides />
				{/* <!--begin::Wrapper--> */}
				<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
					{/* <!--begin::Header--> */}
					<div id="kt_header"  className="header align-items-stretch">
						{/* <!--begin::Container--> */}
						<div className="container-fluid d-flex align-items-stretch justify-content-between">
							{/* <!--begin::aside mobile toggle--> */}
							<div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
								<div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
									{/* <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg--> */}
									<span className="svg-icon svg-icon-2x mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
											<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
										</svg>
									</span>
									{/* <!--end::Svg Icon--> */}
								</div>
							</div>
							{/* <!--end::aside mobile toggle--> */}
							{/* <!--begin::Mobile logo--> */}
							<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
								<a href="../../demo1/dist/index.html" className="d-lg-none">
									{/* <img alt="Logo" src="assets/media/logos/logo-2.svg" className="h-30px" /> */}
                                    <h1 className="h-25px logo text-white">SANTOSO</h1>
								</a>
							</div>
							{/* <!--end::Mobile logo--> */}
							{/* <!--begin::Wrapper--> */}
							<div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
								<Navbar />
								<Topbar />
							</div>
							{/* <!--end::Wrapper--> */}
						</div>
						{/* <!--end::Container--> */}
					</div>
					{/* <!--end::Header--> */}
					{/* <!--begin::Content--> */}
					<div className="content d-flex flex-column flex-column-fluid flex-grow flex-fill" id="kt_content">
                        <Outlet />
					</div>
					{/* <!--end::Content--> */}
					<Footers />
				</div>
				{/* <!--end::Wrapper--> */}
			</div>
			{/* <!--end::Page--> */}
		{/* </div> */}
		{/* <!--end::Root--> */}
		<MasterInit />
		<ScrollTop />
		{/* <!--end::Main--> */}
		<KlaimBaru />
	{/*</div>*/}
	{/* <!--end::Body--> */}
	</PageDataProvider>
        </>
    )
}
