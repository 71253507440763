import React, { useState, useEffect } from 'react';
import { sparepartAPI } from '../../api/sparepart';
import {Sparepart} from '../../api/sparepart/types'
import { Dropdown, NavLink } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PageAnimate } from '../../components/PageAnimate'
import { PageLink, PageTitle } from '../../context/core';
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';
import { APIPaths } from '../../api/paths';
import { masterDataRoutes } from '../../routes/page-route-list';

export default function DatabaseSparepart() {

	const [dataResponse, setDataResponse] = useState<Sparepart[]>([]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    getSpare();
  }, []);

  async function getSpare(){
	const dataRespond =  await sparepartAPI.getSparepart();
	console.log("data api =>", dataRespond.data)
	setDataResponse(dataRespond.data);
	// return dataResponse.data
  }
  const BreadCrumbs: Array<PageLink> = [
	{
	  title: 'Santoso',
	  path: '/',
	  isSeparator: false,
	  isActive: false,
	},
	{
	  title: '',
	  path: '',
	  isSeparator: true,
	  isActive: false,
	},
	{
		title: 'Master Data',
		path: '',
		isSeparator: false,
		isActive: false,
	  },
	  {
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	  },
  ]
  const {showModal} = useGlobalModalContext();
  function handleDeleteModal(id: string) {
	  showModal(MODAL_TYPES.DELETE_CONFIRMATION, {apiFunction: sparepartAPI.deleteSparepart, id: id, afterSubmit: getSpare});

  }

  function handleTambahList() {
	  showModal(MODAL_TYPES.INSERT_SPAREPART_MODAL, {afterSubmit: () => {
		  getSpare();
	  }});
  }

    return (
		<PageAnimate>
			<PageTitle breadcrumbs={BreadCrumbs}>Sparepart</PageTitle>
        {/* // <!--begin::Toolbar--> */}
						<div className="toolbar d-flex flex-fill flex-row flex-grow" id="kt_toolbar">
							<div className="d-flex flex-column flex-fill flex-grow">
							{/* <!--begin::Container--> */}
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-wrap flex-fill flex-grow flex-stack">
								{/* <!--begin::Page title--> */}
								<div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex d-block flex-row align-items-start me-3 mb-5 mb-lg-0">
									{/* <!--begin::Title--> */}
									{/* <!--begin::Trigger--> */}
									<button type="button" className="btn btn-light"
										data-kt-menu-trigger="click"
										data-kt-menu-placement="bottom-start">
										Sparepart
										<span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
									</button>
									{/* <!--end::Trigger--> */}

									{/* <!--begin::Menu--> */}
									<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
										data-kt-menu="true">
										{masterDataRoutes.map((routeLink) => {
											return <>
											{/* <!--begin::Menu item--> */}
											<div className="menu-item px-3">
												<Link to={routeLink.path} className="menu-link px-3">
													{routeLink.label}
												</Link>
											</div>
											{/* <!--end::Menu item--> */}
											</>
										})}
									</div>
									{/* <!--end::Menu--> */}

									{/* <!--begin::Input Form--> */}
									<div className="input-group mx-2">
											<input type="text" className="form-control" placeholder="Masukkan Nomor Rangka" />
											<div className="input-group-append">
											  <button className="btn btn-outline-secondary" type="button">
												<i className="fa fa-search"></i>
											  </button>
											</div>
									</div>
									{/* <!--end::Form--> */}

									{/* <!--end::Title--> */}
								</div>
								{/* <!--end::Page title--> */}
								{/* <!--begin::Action group--> */}
								<div className="d-flex align-items-center overflow-auto">
									{/* <!--begin::Wrapper--> */}
									<div className="d-flex align-items-center flex-shrink-0">
										
										<div className="flex-shrink-0">
											
											<button className="btn btn-sm btn-danger me-lg-1"  id="kt_toolbar_primary_button" onClick={handleTambahList}>
												Tambah List
											</button>
                                        
											<a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Download </a>
                                            <a href="#" className="btn btn-sm btn-success me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">OK </a>
											
										</div>
									</div>
									{/* <!--end::Wrapper--> */}
									
								</div>
								{/* <!--end::Action group--> */}
							</div>
							
							{/* <!--end::Container--> */}
							</div>
						</div>
						
						{/* <!--end::Toolbar--> */}
						{/* <!--begin::Post--> */}
						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
								<div className="card mb-5 mb-xl-8">
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											{/* <!--begin::Table container--> */}
											<div className="table-responsive">
												{/* <!--begin::Table--> */}
												<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
													{/* <!--begin::Table head--> */}
													<thead>
														<tr className="fw-bolder text-muted">
															<th className="w-25px">
																NO
															</th>
															<th className="min-w-150px">Keterangan</th>
															<th className="min-w-140px">Kode</th>
															<th className="min-w-120px">Number Part</th>
															<th className="min-w-120px">Harga Baru</th>
															<th className="min-w-100px text-end">Actions</th>
														</tr>
													</thead>
													{/* <!--end::Table head--> */}
													{/* <!--begin::Table body--> */}
													<tbody>
														{
															dataResponse.map((item: Sparepart, index) => {
																return <tr key={item.uuid}>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
																{index+1}
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">{item.namaSparepart}</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{item.kodeSparepart}</a>
																{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">{item.noPart}</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">{item.hargaBaru}</td>
															<td className="text-end">
																
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																	{/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																			<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
																<button onClick={e => handleDeleteModal(item.uuid!.toString())} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																	{/* <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																			<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																			<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</button>
															</td>
														</tr>
															})
														}
														
														
													</tbody>
													{/* <!--end::Table body--> */}
												</table>
												{/* <!--end::Table--> */}
											</div>
											{/* <!--end::Table container--> */}
										</div>
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
						</div>
						{/* <!--end::Post--> */}
						</PageAnimate>
    )
}
