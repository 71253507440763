import React, {useContext, useState, useEffect} from 'react'
import {auth, errCodes} from '../adapters/Firebase';
import {signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth/'
import SantosoSpinner  from '../components/SantosoSpinner';

const AuthContext = React.createContext<any|null>(null);

interface Props {
    // any props that come into the component
} 

export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider({children}:any) {


    const [currentUser, setCurrentUser]= useState<any|null>();
    const [loading, setLoading] = useState(true);
    
    const signInWithEmailFirebase = async (mail: string, pwd: string) => await signInWithEmailAndPassword(auth, mail, pwd).catch((e) => {
        var code:string = e.code;
        code = code.substring(5);
        code = errCodes[code];
        throw code;
    }
    )

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, user => {
            setCurrentUser(user);
            setLoading(false);
        })
        
        return unsub;
    }, [])

    const signOutFirebase = () => signOut(auth).then(() => {
        // sign out success!
    }).catch((error) => {
        // error bro
        console.error(error);
    })

    const value = {
        currentUser,
        signOutFirebase,
        signInWithEmailFirebase
    }

    return (
        <AuthContext.Provider value={value}>
            {loading? <SantosoSpinner wholePage={true} /> : children}
        </AuthContext.Provider>
    )
}
