import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SantosoStatus } from './const'
import { notesAPI } from '../api/notes'
import { Notes } from '../api/notes/types'

interface INotesRedux {
    status: string,
    data: Notes[],
    error: any
}

const initialNotesRedux:INotesRedux = {
    status: SantosoStatus.LOADING,
    data: [],
    error: null,
};

const notesOutgoingSlice = createSlice({
    name: 'notesOutgoing',
    initialState: initialNotesRedux,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllOutgoingNotes.fulfilled, (state, action) => {
            console.log("FULFILLED");
            console.log(action, action.payload);
            state.data = action.payload
            state.status = SantosoStatus.LOADED
            state.error = null
        })
        builder.addCase(fetchAllOutgoingNotes.rejected, (state, action) => {
            console.log("REJECTED");
            state.status = SantosoStatus.ERROR
            state.error = action.error
        })
        builder.addCase(fetchAllOutgoingNotes.pending, (state, action) => {
            console.log("PENDING");
            state.status = SantosoStatus.LOADING
            state.error = null
        })
    }
})

export const fetchAllOutgoingNotes = createAsyncThunk(
    'notes/fetchAllOutgoingNotes',
    async (userId, thunkAPI) => {
      const response = await notesAPI.getOutgoingNotes()
      return response.data
    }
  )

export const newOutgoingNotes = createAsyncThunk(
    'notes/newOutgoingNotes',
    async (note:Notes, thunkAPI) => {
      const response = await notesAPI.createNote(note);
      return response.data
    }
  )

export {notesOutgoingSlice}