import React from 'react'
import {Spinner} from 'react-bootstrap';

interface ISpinner {
    wholePage?: boolean
    centerDiv?: boolean
}

export default function SantosoSpinner({wholePage=false, centerDiv=true}:ISpinner) {
    let style:any = {
        justifyContent: 'center',
        alignItems:'center'}

    if (wholePage) {
        let newStyle = {
            ...style,
            display:"flex",
            marginLeft:"50vw",
            marginTop:"50vh",
        }
        style = newStyle
    }
    if (centerDiv) {
        return (
            <div className="text-center">
                <Spinner animation="border" style={style}  className={!wholePage? 'm-5' : ''} />
            </div>
        )
    }
    return (
        <Spinner animation="border" style={style} />
    )
}
