import React, { useState } from 'react'
import {Modal, Spinner }from 'react-bootstrap';
import ModalWrapper from './ModalWrapper';
import { useGlobalModalContext } from '../../context/GlobalModals';
import {delay} from '../../tools/delay'
import http from '../../api/http';
import { agenAPI } from '../../api/agen';
import {Agen} from '../../api/agen/types'
import { useNavigate } from "react-router-dom";
import { PageAnimate } from '../../components/PageAnimate'


interface showData {
    showGlobal: boolean
    afterSubmit: Function
} 

export default function InsertSparepartModal({showGlobal=false, afterSubmit}:showData) {
    const [show, setShow] = useState(showGlobal);

    const { hideModal } = useGlobalModalContext()

    const handleClose = async () => {
        setShow(false);
        await delay(500);
        hideModal();
    }

    function callDelete() {
        // return http.post(url,{id: id})
        return delay(1000);
    }

    const showModal = () => {
        setShow(true);
    }

    const [kodeAgen, setKodeAgen] = useState("");
    const [namaAgen, setNamaAgen] = useState("");
    const [perusahaanAsuransi, setPerusahaanAsuransi] =useState("");
    const [role, setRole] = useState("");

    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsSubmitting(true);

        const jsonInput:Agen = {
            kodeAgen: kodeAgen,
            namaAgen: namaAgen,
            perusahaanAsuransi: perusahaanAsuransi,
            role: role,
        };

        const addSparepart = await agenAPI.createAgen(jsonInput);
        setIsSubmitting(false);
        afterSubmit();
        handleClose();


    }

    const [isSubmitting, setIsSubmitting] = useState(false);

    async function submitDelete(){
        try {
            setIsSubmitting(true);
            await callDelete();
            setIsSubmitting(false);
            handleClose();
        } catch (e) {
            setIsSubmitting(false);
        }
        
    }



    return (
        <>
        <ModalWrapper>
            <Modal title={'INSERT DATA'}
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered
            size="lg"
            keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>TAMBAH AGEN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form className="form mb-15" id="kt_careers_form">
                            {/* <!--begin::Input group--> */}
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 col-6">
                                
                                {/* <!--begin::Table body--> */}
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">KODE AGEN :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="Isi Nama Sparepart" onChange={(e) => setKodeAgen(e.target.value)} value={kodeAgen} required />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Nama Agen :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                        <input type="text" className="form-control form-control-solid" placeholder="Isi Kode Sparepart" onChange={(e) => setNamaAgen(e.target.value)} value={namaAgen} required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Perusahaan Asuransi :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                        <input type="text" className="form-control form-control-solid" placeholder="Isi Kode Sparepart" onChange={(e) => setPerusahaanAsuransi(e.target.value)} value={perusahaanAsuransi} required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Role :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                        <input type="text" className="form-control form-control-solid" placeholder="Isi Kode Sparepart" onChange={(e) => setRole(e.target.value)} value={role} required/>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                                {/* <!--end::Table body--> */}
                            </table>
                </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={handleClose}>Batal</button>
                    <button type="button" className="btn btn-primary" disabled={kodeAgen === '' || namaAgen ==='' || isSubmitting} onClick={handleSubmit}>{isSubmitting? <Spinner animation="border" style={{
                // marginLeft:"50vw",
                // display:"flex",
                // flex: 1,
                // marginTop:"50vh",
                justifyContent: 'center',
                alignItems:'center'}} /> : 'SIMPAN'}</button>
                </Modal.Footer>
            </Modal>
        </ModalWrapper>
        </>
    )
                    }
