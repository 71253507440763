import React, { useEffect, useState } from 'react'
import {Modal, Button, Spinner }from 'react-bootstrap';
import ModalWrapper from './ModalWrapper';
import Select, { SingleValue } from 'react-select'
import { Notes } from '../../api/notes/types';
import { notesAPI } from '../../api/notes';
import { DateTime, Settings } from 'luxon';
import { getAuth } from '@firebase/auth';
import { useGlobalModalContext } from '../../context/GlobalModals';
import {delay} from '../../tools/delay'

interface showData {
    showGlobal: boolean
    afterSubmit: Function
} 

export default function NotesWO({showGlobal=false, afterSubmit}:showData) {
    const [show, setShow] = useState(showGlobal);

    const { hideModal } = useGlobalModalContext()

    const handleClose = async () => {
        setShow(false);
        await delay(500);
        hideModal();
    }



    const showModal = () => {
        setShow(true);
    }

    Settings.defaultLocale = "id";
    const dt = DateTime.now()
    dt.setLocale('id')

    const [note, setNote] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState(false);



    async function saveNote(note:string){
        try {
            console.log()
            setIsSubmitting(true);
            await delay(1000);
            setIsSubmitting(false);
            afterSubmit();
            handleClose();
        } catch (e) {
            setIsSubmitting(false);
        }
        
    }

    function handleNote(input:string) {
        setNote(input);
    }


    return (
        <>
        <ModalWrapper>
            <Modal title={'Notes'}
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered
            size="xl"
            keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea className="form-control" style={{minHeight: "30vh"}} onChange={e=> handleNote(e.currentTarget.value)} value={note}></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={handleClose}>Batal</button>
                    <button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={e=> saveNote(note)}>{isSubmitting? <Spinner animation="border" style={{
                // marginLeft:"50vw",
                // display:"flex",
                // flex: 1,
                // marginTop:"50vh",
                justifyContent: 'center',
                alignItems:'center'}} /> : 'Simpan'}</button>
                </Modal.Footer>
            </Modal>
        </ModalWrapper>
        </>
    )
}
