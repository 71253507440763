// import './App.css';
import { AuthProvider } from './context/AuthContext';
import { LayoutProvider } from './context/core';
import {GlobalModal} from './context/GlobalModals'
import PageRoutes from './routes/PageRoutes';

function App() {
  return (
      <LayoutProvider>
        <GlobalModal>
          <AuthProvider>
              <PageRoutes />
          </AuthProvider>
        </GlobalModal>
      </LayoutProvider>
  );
}

export default App;
