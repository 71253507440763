import React from 'react'
import { notesAPI } from '../../api/notes';
import {Notes, Convert, transformNotes} from '../../api/notes/types'
import {DateTime} from 'luxon'
import { notDeepStrictEqual } from 'assert';

interface noteParams {
    note: Notes, handleCloseCheck: Function, isOutgoing?:boolean, index?:number,
}

export default function NoteCard({note, handleCloseCheck, isOutgoing=false, index=0}:noteParams) {
    async function setRead(id:string) {
        await notesAPI.setRead(id);
    }

    if(!isOutgoing){
        if (!(note.readAt) && note.uuid) {
            setRead(note.uuid!);
        }
    }
    const noteTransformed:Notes = {
        ...note,
        closedAt: note.closedAt !== null ? new Date(note.closedAt!) : undefined,
        completedAt: note.completedAt !== null ? new Date(note.completedAt!) : undefined,
    };
    const dateCreated = DateTime.fromJSDate(new Date(noteTransformed.createdAt!));

    return (
        <div className="card card-px-0">
            <div className="card-header align-items-end">
                <h2 className="card-title flex-column" style={{width:'75%!important'}}>
                    <small>{dateCreated.setLocale('id-ID').toLocaleString({ weekday: 'long', month: 'short', day: '2-digit', year:'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</small>
                    <span className="flex-row"><span className="hide-text-overflow">{noteTransformed.fromName}</span> &nbsp;<i className="bi bi-arrow-right text-gray-900"></i>&nbsp; <span className="hide-text-overflow">{noteTransformed.toName}</span> {noteTransformed.readAt && isOutgoing && <i className="fas fa-check-double text-primary"></i>}</span>
                </h2>
                <div className="card-toolbar">
                    <table className="text-center">
                        <tr>
                            {isOutgoing && <th className="px-2"><small>Selesai</small></th> }
                            <th className="px-2"><small>{isOutgoing? 'Confirmed' : 'Selesai'}</small></th>
                        </tr>
                        <tr>
                            {isOutgoing &&
                            <td>
                                <input type="checkbox" className="form-check-input mx-2 checked-red" checked={ noteTransformed.completedAt !== undefined} readOnly />
                            </td> }
                            <td>
                                <input type="checkbox" className={isOutgoing? 'form-check-input checked-green' : 'form-check-input checked-red'} checked={ isOutgoing? (noteTransformed.closedAt !== undefined) : (noteTransformed.completedAt !== undefined)} onClick={e=> handleCloseCheck()} disabled={(noteTransformed.closedAt !== undefined) && !isOutgoing} readOnly={(noteTransformed.closedAt !== undefined) && !isOutgoing}/> 
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="card-body pt-0">
                {noteTransformed.msg}
            </div>
        </div>
    )
}
