import { AxiosResponse } from "axios";
import { Agen } from "./types"
import http from '../http'
import {auth} from '../../adapters/Firebase'
import {APIPaths} from '../paths';

interface AgenAPI {
    getAgen: () => Promise<AxiosResponse<Agen[]>>,
    createAgen: (newasuransi:Agen) => Promise<AxiosResponse>,
    updateAgen: (newasuransi:Agen) => Promise<AxiosResponse>,
    deleteAgen: (uuid: string) => Promise<AxiosResponse>
}

export const agenAPI: AgenAPI = {
    getAgen: async () => {
        let idToken = await auth.currentUser!.getIdToken();
        console.log(idToken);
        let response = http.get(APIPaths.getAgenList, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    
    createAgen: async (newasuransi) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.post(APIPaths.addAgen, newasuransi, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    updateAgen: async (newasuransi) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.post(APIPaths.updateAgen, newasuransi, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    
    deleteAgen: async (uuid) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = await http.post(APIPaths.deleteAgen, {uuid: uuid}, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    
}