import React, { useState, useEffect } from 'react';
import { sparepartAPI } from '../../api/sparepart';
import {Sparepart} from '../../api/sparepart/types'
import { useNavigate } from "react-router-dom";
import {useForm} from "react-hook-form";
import axios from "axios";
import { Link } from 'react-router-dom'
import { PageAnimate } from '../../components/PageAnimate'
import { DateTime } from 'luxon';

export default function AddSparepart() {
    const [namaSparepart, setketerangan] = useState("");
    const [kodeSparepart, setkode] = useState("");
    const [noPart, setnumberApart] = useState(0);
    const [hargaBaru, sethargaBaru] = useState(0);

    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const jsonInput:Sparepart = {
            namaSparepart: namaSparepart,
            kodeSparepart: kodeSparepart,
            hargaBaru: hargaBaru,
            noPart: noPart,
        };

        const addSparepart = await sparepartAPI.createSparepart(jsonInput);

        if(addSparepart.status === 200) {
            navigate('/database/sparepart');
        }
    }

    return (
         <PageAnimate>
         {/* // <!--begin::Toolbar--> */}
                         <div className="toolbar d-flex flex-fill flex-row flex-grow" id="kt_toolbar">
                             <div className="d-flex flex-column flex-fill flex-grow">
                             {/* <!--begin::Container--> */}
                             <div id="kt_toolbar_container" className="container-fluid d-flex flex-wrap flex-fill flex-grow flex-stack">
                                 {/* <!--begin::Page title--> */}
                                 <div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex d-block flex-row align-items-start me-3 mb-5 mb-lg-0">
                                     {/* <!--begin::Title--> */}
                                     {/* <!--begin::Trigger--> */}
                                     <button type="button" className="btn btn-light"
                                         data-kt-menu-trigger="click"
                                         data-kt-menu-placement="bottom-start">
                                         Sparepart
                                         <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
                                     </button>
                                     {/* <!--end::Trigger--> */}
 
                                     {/* <!--begin::Menu--> */}
                                     <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                                         data-kt-menu="true">
                                         {/* <!--begin::Menu item--> */}
                                         <div className="menu-item px-3">
                                             <Link to="/database/jasa" className="menu-link px-3">
                                                 Jasa
                                             </Link>
                                         </div>
                                         {/* <!--end::Menu item--> */}
 
                                         {/* <!--begin::Menu item--> */}
                                         <div className="menu-item px-3">
                                             <Link to="/database/sparepart" className="menu-link px-3">
                                                 Sparepart
                                             </Link>
                                         </div>
                                         {/* <!--end::Menu item--> */}
                                     </div>
                                     {/* <!--end::Menu--> */}
 
                                     {/* <!--begin::Input Form--> */}
                                     <div className="input-group mx-2">
                                             <input type="text" className="form-control" placeholder="Masukkan Nomor Rangka" />
                                             <div className="input-group-append">
                                               <button className="btn btn-outline-secondary" type="button">
                                                 <i className="fa fa-search"></i>
                                               </button>
                                             </div>
                                     </div>
                                     {/* <!--end::Form--> */}
 
                                     {/* <!--end::Title--> */}
                                 </div>
                                 {/* <!--end::Page title--> */}
                                 {/* <!--begin::Action group--> */}
                                 <div className="d-flex align-items-center overflow-auto">
                                     {/* <!--begin::Wrapper--> */}
                                     <div className="d-flex align-items-center flex-shrink-0">
                                         
                                         <div className="flex-shrink-0">
                                             <a href="#" className="btn btn-sm btn-danger me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Tambah List </a>
                                             <a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Download </a>
                                             <a onClick={handleSubmit} className="btn btn-sm btn-success me-lg-1" id="kt_toolbar_primary_button">OK </a>
                                             
                                         </div>
                                     </div>
                                     {/* <!--end::Wrapper--> */}
                                     
                                 </div>
                                 {/* <!--end::Action group--> */}
                             </div>
                             
                             {/* <!--end::Container--> */}
                             </div>
                         </div>
                         
                         {/* <!--end::Toolbar--> */}
                         {/* <!--begin::Post--> */}
                         <div className="post d-flex flex-column-fluid" id="kt_post">
                             {/* <!--begin::Container--> */}
                             <div id="kt_content_container" className="container-fluid">
                                 {/* <!--begin::Tables Widget 13--> */}
                                 <div className="card mb-5 mb-xl-8">
                                         {/* <!--begin::Body--> */}
                                         <div className="card-body py-3">
                                         
                                             {/* <!--begin::Table container--> */} 
                                             <form className="form mb-15" id="kt_careers_form">
														{/* <!--begin::Input group--> */}
														<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 col-6">
															
															{/* <!--begin::Table body--> */}
															<tbody>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">KETERANGAN :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setketerangan(e.target.value)} value={namaSparepart} required />
																	</td>
																</tr>
                                                                <tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">KODE :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                                                    <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setkode(e.target.value)} value={kodeSparepart} required/>
																	</td>
																</tr>
                                                                <tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NUMBER PART :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setnumberApart(parseInt(e.target.value))} value={noPart} required/>
																	</td>
																</tr>
                                                                <tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">HARGA BARU :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => sethargaBaru(parseInt(e.target.value))} value={hargaBaru}required/>
																	</td>
																</tr>
																
															</tbody>
															{/* <!--end::Table body--> */}
														</table>
													</form>
                                             {/* <!--end::Table container--> */}
                                         </div>
                                         {/* <!--begin::Body--> */}
                                     </div>
                                     
                                     {/* <!--end::Tables Widget 13--> */}
                             </div>
                             {/* <!--end::Container--> */}
                         </div>
                         {/* <!--end::Post--> */}
                         </PageAnimate>
     )
 }