export const APIPaths = {
    // notes API
    notes: '/notes',
    incomingNotes: '/notes/incoming',
    outgoingNotes: '/notes/outgoing',
    createNote: '/notes/create',
    setRead: '/notes/read',
    checkClosed: '/notes/close',
    checkCompleted: '/notes/complete',
    userNotes: '/notes/recipient',
    getNotesStats: '/notes/stats',
    // jasa API
    addJasa: '/jasa/add',
    editJasa: '/jasa/edit',
    getListJasa: '/jasa/list',
    getJasaSingle: '/jasa/get',
    deleteJasa: '/jasa/delete',
    // sparepart API
    addSparepart : '/sparepartharga/add',
    updateSparepart : '/sparepartharga/edit',
    getSparepartList : '/sparepartharga/list',
    getSparepartSingle : '/sparepartharga/get',
    deleteSparepart : '/sparepartharga/delete',
    // asuransi API
    addAsuransi : '/asuransi/add',
    updateAsuransi: '/asuransi/edit',
    getAsuransiList: '/asuransi/list',
    getAsuransiSingle: '/asuransi/get',
    deleteAsuransi: '/asuransi/delete',
    // agen API
    addAgen : '/agen/add',
    updateAgen : '/agen/update',
    getAgenList : '/agen/list',
    getAgenSingle : '/agen/get',
    deleteAgen : '/agen/delete',
}