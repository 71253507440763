import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Link } from 'react-router-dom'
import { PageAnimate } from '../../components/PageAnimate'
import { PageLink, PageTitle } from '../../context/core'
import { masterDataRoutes } from '../../routes/page-route-list'

interface IKendaraan {
	noRangka: string
	kendaraan: string
	tahun: string
}

export default function DatabaseAgen() {
	const dataAgen:IKendaraan[] = [
		{
			noRangka:'__KFEINI_3______',
			kendaraan: 'AVANZA AT 2.0',
			tahun: '2015',
		},
		{
			noRangka:'__KFEGNI_3______',
			kendaraan: 'AVANZA GT 2.0',
			tahun: '2015',
		},
		{
			noRangka:'__KFEZNI_3______',
			kendaraan: 'AVANZA VT 2.0',
			tahun: '2015',
		},
		
	]

	const BreadCrumbs: Array<PageLink> = [
		{
		  title: 'Santoso',
		  path: '/',
		  isSeparator: false,
		  isActive: false,
		},
		{
		  title: '',
		  path: '',
		  isSeparator: true,
		  isActive: false,
		},
		{
			title: 'Master Data',
			path: '',
			isSeparator: false,
			isActive: false,
		  },
		  {
			title: '',
			path: '',
			isSeparator: true,
			isActive: false,
		  },
	  ]

    return (
		<PageAnimate>
		<PageTitle breadcrumbs={BreadCrumbs}>Kendaraan</PageTitle>
        {/* // <!--begin::Toolbar--> */}
        <div className="toolbar d-flex flex-fill flex-row flex-grow" id="kt_toolbar">
							{/* <!--begin::Container--> */}
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-wrap flex-fill flex-grow flex-stack">
								{/* <!--begin::Page title--> */}
								<div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex d-block flex-row align-items-start me-3 mb-5 mb-lg-0">
									{/* <!--begin::Title--> */}
									{/* <!--begin::Trigger--> */}
									<button type="button" className="btn btn-light"
										data-kt-menu-trigger="click"
										data-kt-menu-placement="bottom-start">
										Kendaraan
										<span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
									</button>
									{/* <!--end::Trigger--> */}

									{/* <!--begin::Menu--> */}
									<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
										data-kt-menu="true">
										{masterDataRoutes.map((routeLink) => {
											return <>
											{/* <!--begin::Menu item--> */}
											<div className="menu-item px-3">
												<Link to={routeLink.path} className="menu-link px-3">
													{routeLink.label}
												</Link>
											</div>
											{/* <!--end::Menu item--> */}
											</>
										})}
									</div>
									{/* <!--end::Menu--> */}

									{/* <!--begin::Input Form--> */}
									<div className="input-group mx-2">
												<input type="text" className="form-control" placeholder="Cari Data Kendaraan"/>
												<div className="input-group-append">
												  <button className="btn btn-active-secondary" type="button">
													<i className="fa fa-search"></i>
												  </button>	
												</div>
											  </div>
											{/* <!--end::Form--> */}

									{/* <!--end::Title--> */}
									
								</div>
								{/* <!--end::Page title--> */}
								{/* <!--begin::Action group--> */}
								<div className="d-flex align-items-center overflow-auto">
									{/* <!--begin::Wrapper--> */}
									<div className="d-flex align-items-center flex-shrink-0">
										
										<div className="flex-shrink-0 ml-2">
											<a href="#" className="btn btn-sm btn-danger me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Tambah List </a>
                                            <a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Download </a>
                                            <a href="#" className="btn btn-sm btn-success me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">OK </a>
											
										</div>
									</div>
									{/* <!--end::Wrapper--> */}
									
								</div>
								{/* <!--end::Action group--> */}
							</div>
							
							{/* <!--end::Container--> */}
						</div>
						
						{/* <!--end::Toolbar--> */}
						{/* <!--begin::Post--> */}
						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
									<div className="card mb-5 mb-xl-8">
										{/* <!--begin::Header--> */}

										<div className="card-header border-0 pt-5">
											<div id="kt_docs_search_handler_basic">
											
											
											</div>
											
											<div className="d-flex align-items-center py-1">
												{/* <!--begin::Wrapper--> */}
												
												{/* <!--end::Wrapper--> */}
												
											</div>
										</div>
										{/* <!--end::Header--> */}
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											<div className="row">
												<div className='col-12'>
											{/* <!--begin::Table container--> */}
											<div className=''>
												{/* <!--begin::Table--> */}
												<table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
													{/* <!--begin::Table head--> */}
													<thead>
														<tr className="fw-bolder text-muted">
															<th className="w-25px">
																No
															</th>
															<th className="max-w-80px">NOMOR RANGKA</th>
															<th className="min-w-180px">KENDARAAN</th>
															<th className="min-w-120px">TAHUN</th>
															<th className="text-end">DETAIL</th>
														</tr>
													</thead>
													{/* <!--end::Table head--> */}
													{/* <!--begin::Table body--> */}
													<tbody>
														{dataAgen.map((agen, index) => {
															return <>
																<tr>
																	<td className='text-dark fw-bolder text-hover-primary fs-6'>
																		{index+1}
																	</td>
																	<td>
																		<span  className="text-dark fw-bolder text-hover-primary fs-6">{agen.noRangka}</span>
																	</td>
																	<td>
																		<span className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{agen.kendaraan}</span>
																		
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6">{agen.tahun}</td>
																	<td className="text-end">
																		<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">
																			{/* <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg--> */}
																			<FontAwesomeIcon icon={faEye} color='#a1a5b7'/>&nbsp; Database Sparepart
																			{/* <!--end::Svg Icon--> */}
																		</a>
																	</td>
																</tr>
															</>
														})}
														
													</tbody>
													{/* <!--end::Table body--> */}
												</table>
												{/* <!--end::Table--> */}
											</div>
											{/* <!--end::Table container--> */}
											</div>
											</div>
										</div>
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
						</div>
						{/* <!--end::Post--> */}
						</PageAnimate>
    )
}
