import clsx from 'clsx'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useLayout } from '../context/core'
import KlaimBaru from './modals/KlaimBaru'
import {detailKlaimRoutes, masterDataRoutes} from '../routes/page-route-list'

export default function Asides() {
	const {config, classes, setLayout} = useLayout()
  const {aside} = config

  const toggleMinimize = () => {
	  const newConfig = {...config};

	  newConfig.aside.minimized = !newConfig.aside.minimized;

	  setLayout(newConfig);
  }
    return (
        // {/* <!--begin::Aside--> */}
				 <div id="kt_aside" 
				 className={clsx('aside', classes.aside.join(' '))} 
				 data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}"
				 data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
				 {/* <!--begin::Brand--> */}
				 <div className="aside-logo flex-column-auto" id="kt_aside_logo">
					 {/* <!--begin::Logo--> */}
					 <Link to="/">
						 <h1 className="h-25px logo text-white">SANTOSO</h1>
						 {/* <!-- <img alt="Logo" src="assets/media/logos/logo-1-dark.svg" className="h-25px logo" /> --> */}
					 </Link>
					 {/* <!--end::Logo--> */}
					 {/* <!--begin::Aside toggler--> */}
					 <div 
					 	id="kt_aside_toggle" 
						 className={ `btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle ${config.aside.minimized?'active':''}`} 
						 data-kt-toggle="true" 
						 data-kt-toggle-state="active" 
						 data-kt-toggle-target="body" 
						 data-kt-toggle-name="aside-minimize"
						 onClick={toggleMinimize}>
						 {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr079.svg--> */}
						 <span className={`svg-icon svg-icon-1 ${config.aside.minimized?'rotate-180':''}`}>
								 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									 <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="black" />
									 <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="black" />
								 </svg>
							 </span>
						 {/* <!--end::Svg Icon--> */}
					 </div>
					 {/* <!--end::Aside toggler--> */}
				 </div>
				 {/* <!--end::Brand--> */}
				 {/* <!--begin::Footer (now header!)--> */}
				 <div className="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
				 <a className="btn btn-custom btn-success w-100" data-bs-trigger="hover" title="Tambah klaim baru, input calon konsumen, atau buat komplain." data-bs-toggle="modal" data-bs-target="#kt_modal_2" >
						 <span className="btn-label">MOBIL MASUK&nbsp;</span>
						 {/* <!--begin::Svg Icon | path: icons/duotune/general/gen005.svg--> */}
						 <span className="svg-icon svg-icon-muted icon-size-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path opacity="0.3" d="M11 13H7C6.4 13 6 12.6 6 12C6 11.4 6.4 11 7 11H11V13ZM17 11H13V13H17C17.6 13 18 12.6 18 12C18 11.4 17.6 11 17 11Z" fill="black"/>
							<path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM17 11H13V7C13 6.4 12.6 6 12 6C11.4 6 11 6.4 11 7V11H7C6.4 11 6 11.4 6 12C6 12.6 6.4 13 7 13H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V13H17C17.6 13 18 12.6 18 12C18 11.4 17.6 11 17 11Z" fill="black"/>
							</svg>
						 </span>
						 {/* <!--end::Svg Icon--> */}
        </a>
					 {/* <KlaimBaru /> */}
				 </div>
				 {/* <!--end::Footer--> */}
				 {/* <!--begin::Aside menu--> */}
				 <div className="aside-menu flex-column-fluid">
					 {/* <!--begin::Aside Menu--> */}
					 <div className="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu"
						 data-kt-scroll-offset="0">
						 {/* <!--begin::Menu--> */}
						 <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true">
							 <div className="menu-item">
								 <div className="menu-content pb-2">
									 <span className="menu-section text-muted text-uppercase fs-8 ls-1">Dashboard</span>
								 </div>
							 </div>
							 <div className="menu-item">
								 <NavLink className={({isActive}) => isActive? 'menu-link active' : 'menu-link' } to="/">
									 <span className="menu-icon">
											 {/* <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg--> */}
											 <span className="svg-icon svg-icon-2">
												 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													 <rect x="2" y="2" width="9" height="9" rx="2" fill="black" />
													 <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black" />
													 <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black" />
													 <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black" />
												 </svg>
											 </span>
									 {/* <!--end::Svg Icon--> */}
									 </span>
									 <span className="menu-title">Dashboard</span>
								 </NavLink>
							 </div>
							 <div className="menu-item">
								 <div className="menu-content pb-2">
									 <span className="menu-section text-white text-uppercase fs-8 ls-1 ">Klaim </span>
								 </div>
							 </div>
							 <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
								 <span className="menu-link">
										 <span className="menu-icon">
											 {/* <!--begin::Svg Icon | path: icons/duotune/finance/fin006.svg--> */}
											 <span className="svg-icon svg-icon-2">
												 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													 <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black" />
													 <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black" />
												 </svg>
											 </span>
								 {/* <!--end::Svg Icon--> */}
								 </span>
								 <span className="menu-title">Detail Klaim</span>
								 <span className="menu-arrow"></span>
								 </span>
								 <div className="menu-sub menu-sub-accordion">
								 { detailKlaimRoutes.map((routeLink) => {
									return <div className="menu-item">
										<NavLink className={({isActive}) => isActive? 'menu-link active' : 'menu-link' } to={routeLink.path}>
											<span className="menu-bullet">
													<span className="bullet bullet-dot"></span>
											</span>
											<span className="menu-title">{routeLink.label}</span>
										</NavLink>
									</div>
									})}
									 
								 </div>
							 </div>
							 <div className="menu-item">
								 <div className="menu-content pb-2">
									 <span className="menu-section text-muted text-uppercase fs-8 ls-1">Database</span>
								 </div>
							 </div>
							 <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
								 <span className="menu-link">
										 <span className="menu-icon">
											 {/* <!--begin::Svg Icon | path: icons/duotune/finance/fin006.svg--> */}
											 <span className="svg-icon svg-icon-2">
												 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													 <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black" />
													 <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black" />
												 </svg>
											 </span>
								 {/* <!--end::Svg Icon--> */}
								 </span>
								 <span className="menu-title">Master Data</span>
								 <span className="menu-arrow"></span>
								 </span>
								 <div className="menu-sub menu-sub-accordion">
								 { masterDataRoutes.map((routeLink) => {
									return <div className="menu-item">
										<NavLink className={({isActive}) => isActive? 'menu-link active' : 'menu-link' } to={routeLink.path}>
											<span className="menu-bullet">
													<span className="bullet bullet-dot"></span>
											</span>
											<span className="menu-title">{routeLink.label}</span>
										</NavLink>
									</div>
									})}
								 </div>
							 </div>
						 </div>
						 {/* <!--end::Menu--> */}
					 </div>
					 {/* <!--end::Aside Menu--> */}
				 </div>
				 {/* <!--end::Aside menu--> */}
			 </div>
			//  {/* <!--end::Aside--> */}
    )
}
