import React from 'react'
import { createPortal } from 'react-dom'

interface ModalInput {
    children: React.ReactNode
}

export default function ModalWrapper({children}:ModalInput) {
    return createPortal(children, document.querySelector('#modal-wrapper')!)
}
