import React from 'react'

interface ChecklistProps {
    data:any, onValueChange:Function, getSelected:Function, index:Number
}

export default function ChecklistItem({data, onValueChange, getSelected, index}:ChecklistProps) {
    function handleKeyUp(event:any) {
        const e:HTMLInputElement = event.target;
        const val = e.value;
        console.log("updated value: ", val)
        onValueChange(data['id'], val);
    }
    return (
        <>
            <tr>
                <td>
                    <span className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{index}</span>
                </td>
                <td className="text-dark fw-bolder text-hover-primary fs-6">
                    <span className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{data['name']}</span>
                    {/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
                </td>
                
                <td className="text-dark fw-bolder text-hover-primary fs-6">
                    <input className="form-check-input" name={data['id']} type="radio" value="1" id="1" onMouseUp={event => onValueChange(data['id'], 1)} onKeyUp={ event => onValueChange(data['id'], 5)} checked={getSelected(data['id'], 1)?true:false}/>
                </td>
                <td className="text-dark fw-bolder text-hover-primary fs-6">
                    <input className="form-check-input" name={data['id']} type="radio" value="2" id="2" onMouseUp={event => onValueChange(data['id'], 2)} onKeyUp={ event => onValueChange(data['id'], 5)} checked={getSelected(data['id'], 2)?true:false}/>
                </td>
                <td className="text-dark fw-bolder text-hover-primary fs-6">
                    <input className="form-check-input" name={data['id']} type="radio" value="3" id="3" onMouseUp={event => onValueChange(data['id'], 3)} onKeyUp={ event => onValueChange(data['id'], 5)} checked={getSelected(data['id'], 3)?true:false}/>
                </td>
                <td className="text-dark fw-bolder text-hover-primary fs-6">
                    <input className="form-check-input" name={data['id']} type="radio" value="4" id="4" onMouseUp={event => onValueChange(data['id'], 4)} onKeyUp={ event => onValueChange(data['id'], 5)} checked={getSelected(data['id'], 4)?true:false}/>
                </td>
                <td className="text-dark fw-bolder text-hover-primary fs-6">
                    <input className="form-check-input" name={data['id']} type="radio" value="5" id="5" onMouseUp={ event => onValueChange(data['id'], 5)} onKeyUp={ event => onValueChange(data['id'], 5)} checked={getSelected(data['id'], 5)?true:false}/>
                </td>
                <td className="text-dark fw-bolder text-hover-primary fs-6">
                    <input type="number" id="quantity" name={data['id']} min="1" max="10" className="form-control form-control-sm" onChange={handleKeyUp} value={data['qty']?data['qty']:''}/>
                </td>
        
            </tr>
        </>
    )
}
