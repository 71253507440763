import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function ChecklistLain() {
	const dataModel = {
		id: '',
		namaBarang: '',
		foto: []
	}

	const [lainLain, setLainLain] = useState([dataModel]);

	function tambah(){
		setLainLain([...lainLain, dataModel]);
	}

	function hapus(index:number) {
		let newLain = [...lainLain];
		newLain.splice(index,1);
		setLainLain(newLain);
	}

	function handleKeyUp(event:any, index:number) {
        const e:HTMLInputElement = event.target;
        const val = e.value;
        let newLain = [...lainLain];
		newLain[index]['namaBarang'] = val;
		setLainLain(newLain);
    }

    const navigate = useNavigate();

    function nav(event:any) {
        navigate(event.target.dataset.link);
    }
        return (
        // <!--start: klaim baru -->
		<div className="modal fade" tabIndex={-1} id="kt_modal_3">
			<div className="modal-dialog modal-dialog-centered modal-xl">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">LAIN-LAIN</h5>
	
						{/* <!--begin::Close--> */}
						<div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
							<span className="svg-icon svg-icon-2x"></span>
						</div>
						{/* <!--end::Close--> */}
					</div>
	
					<div className="modal-body">
						<div className="d-flex flex-column">
							<form action="" className="form mb-15" method="post" id="kt_careers_form">
								<table id="tbl" className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 col-6">
									{/* <!--begin::Table head--> */}
									<thead>
										<tr className="fw-bolder">
											<th  className="min-w-140px modal_kt">No</th>
											<th  className="min-w-140px modal_kt">Nama Barang</th>
											<th  className="min-w-140px modal_kt">Foto</th>
											<th className="min-w-140px modal_kt">Action</th>
										</tr>
									</thead>
									{/* <!--end::Table head--> */}
									{/* <!--begin::Table body--> */}
									<tbody>
									{lainLain.map((singleData, index) => (
										<tr>
										<td>
											<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{index+1}</a>
										</td>
										<td className="text-dark fw-bolder text-hover-primary fs-6">
											<input type="text" className="form-control-sm form-control" placeholder="" value={singleData['namaBarang']} onChange={event => handleKeyUp(event, index)}/>
										</td>
										<td className="text-dark fw-bolder text-hover-primary fs-6">
											<a className="btn btn-primary btn-sm"><span className="fa fa-plus" /></a>
										</td>
										<td className="text-dark fw-bolder text-hover-primary fs-6">
											<a className="btn btn-danger btn-sm" onClick={event=> hapus(index)}>Delete</a>
										</td>
									</tr>
														))}
										
									</tbody>
									{/* <!--end::Table body--> */}
								</table>
								<a className="btn btn-sm btn-secondary" onClick={event => tambah()}>TAMBAH BARANG</a>
								<a className="btn btn-sm btn-success mx-2" data-bs-toggle="modal" data-bs-target="#kt_modal_3" id="kt_toolbar_primary_button">OK</a>
							</form>
						</div>
	
					</div>
				</div>
			</div>
		</div>
		// <!--end: klaim baru -->
    )
}
