import React from 'react';
import SantosoSpinner from '../SantosoSpinner';

interface ICounterBox {
    subtitle: string;
    number: number;
    isLoading: boolean;
    borderTag?: string;
}

export default function CounterBox({subtitle, number, isLoading=true, borderTag='border-gray-300'}:ICounterBox) {
  return  <div className={`border ${borderTag} border-dashed rounded w-100 py-3 px-4 me-6 mb-3 text-center`}>
        { !isLoading && <div>
            <div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value={`${number}`}>{`${number}`}</div>
        </div> }
        <div className="fw-bold fs-6 text-gray-400">{subtitle}</div> 
    </div>;
}
