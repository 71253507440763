import React from 'react'
import { useAuth } from '../context/AuthContext';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom'
import LoginPage from '../pages/login';

import { DefaultLayout } from '../pages/layouts/DefaultLayout';
import DatabaseSparepart from '../pages/database/DatabaseSparepart';
import AddSparepart from '../pages/database/AddSparepart'
import KlaimSketsa from '../pages/klaim/KlaimSketsa';
import Dashboard from '../pages/Dashboards';
import DatabaseJasa from '../pages/database/DatabaseJasa';
import { AnimatePresence } from 'framer-motion';

import RegisterProfil from '../pages/register/RegisterProfil';
import RegisterData from '../pages/register/RegisterData';
import RegisterGambar from '../pages/register/RegisterGambar';

import Checklist from '../pages/klaim/Checklist'
import EstimasiA from "../pages/klaim/EstimasiA"
import Spk from "../pages/klaim/Spk"
import SuratSalvage from "../pages/klaim/SuratSalvage"
import DatabaseAsuransi from '../pages/database/DatabaseAsuransi'
import DatabaseAgen from '../pages/database/DatabaseAgen'
import DatabaseKendaraan from '../pages/database/DatabaseKendaraan'

export default function PageRoutes() {
    const location = useLocation();
    const {currentUser} = useAuth();
    console.log(currentUser);

    return (
        <AnimatePresence exitBeforeEnter initial={false}>
            <Routes location={location} key={location.pathname}>
                {!currentUser ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                    <Route path="/auth" element={<LoginPage></LoginPage>}></Route>
                ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Route path="/auth" element={<Navigate replace to="/" />} />
                )}

                {/* <Route path='/error' component={ErrorsPage} /> */}
                {/* <Route path="/auth" element={<LoginPage></LoginPage>}></Route> */}

            {!currentUser ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Route path="*" element={<Navigate to='/auth' />} />
            ) : (
                // <Route path="/" element={<PrivateRoutes />}></Route>
                <Route path="/" element={<DefaultLayout/>}>
                    <Route path="/database/sparepart" element={<DatabaseSparepart />}></Route>
                    <Route path="/database/jasa" element={<DatabaseJasa></DatabaseJasa>}></Route>
                    <Route path="/database/asuransi" element={<DatabaseAsuransi></DatabaseAsuransi>}></Route>
                    <Route path="/database/agen" element={<DatabaseAgen></DatabaseAgen>}></Route>
                    <Route path="/database/kendaraan" element={<DatabaseKendaraan></DatabaseKendaraan>}></Route>
                    <Route path="/register/data" element={<RegisterData></RegisterData>}></Route>
                    <Route path="/register/gambar" element={<RegisterGambar></RegisterGambar>}></Route>
                    <Route path="/register/profil" element={<RegisterProfil></RegisterProfil>}></Route>
                    <Route path="/klaim/new/sketsa" element={<KlaimSketsa></KlaimSketsa>}></Route>
                    <Route path="/klaim/new/checklist" element={<Checklist></Checklist>}></Route>
                    <Route path="/klaim/new/estimasi/a" element={<EstimasiA></EstimasiA>}></Route>
                    <Route path="/klaim/new/spk" element={<Spk></Spk>}></Route>
                    <Route path="/klaim/new/surat-salvage" element={<SuratSalvage></SuratSalvage>}></Route>
                    <Route path="/" element={<Dashboard></Dashboard>}></Route>
                    <Route path="/database/sparepart/add" element={<AddSparepart></AddSparepart>}></Route>
                </Route>
            )}
        
        </Routes>
        </AnimatePresence>
    )
}
