import React from 'react'
import { Link } from 'react-router-dom'
import { PageAnimate } from '../../components/PageAnimate'
import { PageLink, PageTitle } from '../../context/core'
import { masterDataRoutes } from '../../routes/page-route-list'
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';

export default function DatabaseJasa() {
	const BreadCrumbs: Array<PageLink> = [
		{
		  title: 'Santoso',
		  path: '/',
		  isSeparator: false,
		  isActive: false,
		},
		{
		  title: '',
		  path: '',
		  isSeparator: true,
		  isActive: false,
		},
		{
			title: 'Master Data',
			path: '',
			isSeparator: false,
			isActive: false,
		  },
		  {
			title: '',
			path: '',
			isSeparator: true,
			isActive: false,
		  },
	  ]

	  const {showModal}= useGlobalModalContext();
	  function handleTambahList() {
		showModal(MODAL_TYPES.INSERT_JASA_MODAL)
	}

    return (
		<PageAnimate>
		<PageTitle breadcrumbs={BreadCrumbs}>Jasa</PageTitle>
        {/* // <!--begin::Toolbar--> */}
        <div className="toolbar d-flex flex-fill flex-row flex-grow" id="kt_toolbar">
							{/* <!--begin::Container--> */}
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-wrap flex-fill flex-grow flex-stack">
								{/* <!--begin::Page title--> */}
								<div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex d-block flex-row align-items-start me-3 mb-5 mb-lg-0">
									{/* <!--begin::Title--> */}
									{/* <!--begin::Trigger--> */}
									<button type="button" className="btn btn-light"
										data-kt-menu-trigger="click"
										data-kt-menu-placement="bottom-start">
										Jasa
										<span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
									</button>
									{/* <!--end::Trigger--> */}

									{/* <!--begin::Menu--> */}
									<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
										data-kt-menu="true">
										{masterDataRoutes.map((routeLink) => {
											return <>
											{/* <!--begin::Menu item--> */}
											<div className="menu-item px-3">
												<Link to={routeLink.path} className="menu-link px-3">
													{routeLink.label}
												</Link>
											</div>
											{/* <!--end::Menu item--> */}
											</>
										})}
									</div>
									{/* <!--end::Menu--> */}

									{/* <!--begin::Input Form--> */}
									<div className="input-group mx-2">
												<input type="text" className="form-control" placeholder="Cari Harga Jasa"/>
												<div className="input-group-append">
												  <button className="btn btn-active-secondary" type="button">
													<i className="fa fa-search"></i>
												  </button>	
												</div>
											  </div>
											{/* <!--end::Form--> */}

									{/* <!--end::Title--> */}
									
								</div>
								{/* <!--end::Page title--> */}
								{/* <!--begin::Action group--> */}
								<div className="d-flex align-items-center overflow-auto">
									{/* <!--begin::Wrapper--> */}
									<div className="d-flex align-items-center flex-shrink-0">
										{/* <!--begin::Button--> */}
										<div className="flex-shrink-0 mx-2">
										<a href="#" className="btn btn-sm btn-light-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Custom </a>
										<a href="#" className="btn btn-sm btn-light-info me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">G/C</a>
										<a href="#" className="btn btn-sm btn-light-info me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">K/C</a>
										<a href="#" className="btn btn-sm btn-light-info me-lg-1 mr-2" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">B/P</a>
										</div>
										{/* <!--end::Button--> */}
										
										<div className="flex-shrink-0 ml-2">
											<button onClick={handleTambahList} className="btn btn-sm btn-danger me-lg-1" id="kt_toolbar_primary_button">Tambah List </button>
                                            <a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Download </a>
                                            <a href="#" className="btn btn-sm btn-success me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">OK </a>
											
										</div>
									</div>
									{/* <!--end::Wrapper--> */}
									
								</div>
								{/* <!--end::Action group--> */}
							</div>
							
							{/* <!--end::Container--> */}
						</div>
						
						{/* <!--end::Toolbar--> */}
						{/* <!--begin::Post--> */}
						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
									<div className="card mb-5 mb-xl-8">
										{/* <!--begin::Header--> */}

										<div className="card-header border-0 pt-5">
											<div id="kt_docs_search_handler_basic">
											
											
											</div>
											
											<div className="d-flex align-items-center py-1">
												{/* <!--begin::Wrapper--> */}
												
												{/* <!--end::Wrapper--> */}
												
											</div>
										</div>
										{/* <!--end::Header--> */}
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											{/* <!--begin::Table container--> */}
											<div className="table-responsive">
												{/* <!--begin::Table--> */}
												<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
													{/* <!--begin::Table head--> */}
													<thead>
														<tr className="fw-bolder text-muted">
															<th className="w-25px">
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input" type="checkbox" value="1" data-kt-check="true" data-kt-check-target=".widget-13-check" />
																</div>
															</th>
															<th className="min-w-150px">Keterangan</th>
															<th className="min-w-140px">Kode</th>
															<th className="min-w-120px">Harga Asuransi</th>
															<th className="min-w-120px">Harga Standard Ringan</th>
															<th className="min-w-120px">Harga Standard Berat</th>
															<th className="min-w-120px">Harga Regular Ringan</th> 
															<th className="min-w-120px">Harga Regular Berat</th> 
															<th className="min-w-120px">Harga VIP Ringan</th> 
															<th className="min-w-120px">Harga VIP Berat</th> 
															<th className="min-w-120px">Kenteng</th> 
															<th className="min-w-120px">Cat</th> 
															<th className="min-w-120px">Poles</th> 
															<th className="min-w-120px">Pasang</th> 
															<th className="min-w-100px text-end">Actions</th>
														</tr>
													</thead>
													{/* <!--end::Table head--> */}
													{/* <!--begin::Table body--> */}
													<tbody>
														<tr>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Perbaikan Airbag</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">P0001</a>
																
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">50.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">30.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-end">
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																	{/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																			<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																	{/* <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																			<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																			<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
															</td>
														</tr>
														<tr>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Reset Airbag</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">P0002</a>
																
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">100.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">50.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">30.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-end">
																
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																	{/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																			<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																	{/* <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																			<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																			<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="red" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
															</td>
														</tr>
														<tr>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">G/C BUMPER DEPAN</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">G0001</a>
																{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">100.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">100.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">100.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">50.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">30.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-end">
																
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																	{/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path opacity="1" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="green" />
																			<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="green" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																	{/* <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																			<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																			<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
															</td>
														</tr>
														<tr>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">G/C FENDER LH</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">G0002</a>
																{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">50.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">30.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-end">
																
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																	{/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																			<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																	{/* <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																			<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																			<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
															</td>
														</tr>
														<tr>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">K/C BUMPER DEPAN</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">K0001</a>
																{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">50.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">30.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-end">
																
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																	{/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																			<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																	{/* <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																			<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																			<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
															</td>
														</tr>
														<tr>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">K/C FENDER</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">K0002</a>
																{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">50.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">30.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-end">
																
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																	{/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																			<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																	{/* <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																			<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																			<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
															</td>
														</tr>
														<tr>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">B/P BUMPER DEPAN</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">B0001</a>
																{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">50.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">30.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-end">
																
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																	{/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																			<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																	{/* <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																			<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																			<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
															</td>
														</tr>
														<tr>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">B/P FENDER LH</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">B0002</a>
																{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">50.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">30.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">10.000</td>
															<td className="text-end">
																
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
																	{/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
																			<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
																<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
																	{/* <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg--> */}
																	<span className="svg-icon svg-icon-3">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
																			<path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
																			<path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
																		</svg>
																	</span>
																	{/* <!--end::Svg Icon--> */}
																</a>
															</td>
														</tr>
													</tbody>
													{/* <!--end::Table body--> */}
												</table>
												{/* <!--end::Table--> */}
											</div>
											{/* <!--end::Table container--> */}
										</div>
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
						</div>
						{/* <!--end::Post--> */}
						</PageAnimate>
    )
}
