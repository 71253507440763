import {configureStore} from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import {notesIncomingSlice} from './notes-incoming'
import {notesOutgoingSlice} from './notes-outgoing'

  
const store = configureStore({
    reducer: {
    notesIncoming: notesIncomingSlice.reducer,
    notesOutgoing: notesOutgoingSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
})

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch