import { GoogleAuthProvider } from "@firebase/auth";
import { useState } from "react";
import { useAuth } from "../context/AuthContext";
import {useNavigate} from 'react-router-dom';
import { PageAnimate } from "../components/PageAnimate";

export default function LoginPage({ }) {
    const navigate = useNavigate();

    const [userAuthInput, setAuthInput] = useState({
        email: '',
        password: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const {signInWithEmailFirebase} = useAuth();

    async function handleLogin(e:any) {
        e.preventDefault();
        setErrorMsg('');
        setIsLoading(true);
        try {
            await signInWithEmailFirebase(userAuthInput.email, userAuthInput.password);
            setIsLoading(false)
            navigate('/',  { replace: true });
        } catch (e:any) {
            setIsLoading(false);
            setErrorMsg(e);
            // console.log(e);
        }
    }

    return (
        <PageAnimate>
{/* <!DOCTYPE html>
<!--
Author: Keenthemes
Product Name: Metronic - Bootstrap 5 HTML, VueJS, React, Angular & Laravel Admin Dashboard Theme
Purchase: https://1.envato.market/EA4JP
Website: http://www.keenthemes.com
Contact: support@keenthemes.com
Follow: www.twitter.com/keenthemes
Dribbble: www.dribbble.com/keenthemes
Like: www.facebook.com/keenthemes
License: For each use you must have a valid license purchased only from above link in order to legally use the theme for your project.
--> */}
<html lang="en">
	{/* <!--begin::Head--> */}
	<head>
		<title>SANTOSO BENGKEL SYSTEM</title>
		{/* <meta name="description" content="The most advanced Bootstrap Admin Theme on Themeforest trusted by 94,000 beginners and professionals. Multi-demo, Dark Mode, RTL support and complete React, Angular, Vue &amp; Laravel versions. Grab your copy now and get life-time updates for free." /> */}
		{/* <meta name="keywords" content="Metronic, bootstrap, bootstrap 5, Angular, VueJs, React, Laravel, admin themes, web design, figma, web development, free templates, free admin themes, bootstrap theme, bootstrap template, bootstrap dashboard, bootstrap dak mode, bootstrap button, bootstrap datepicker, bootstrap timepicker, fullcalendar, datatables, flaticon" /> */}
		<meta name="viewport" content="width=device-width, initial-scale=1" />
		<meta property="og:locale" content="id_ID" />
		{/* <meta property="og:type" content="article" /> */}
		{/* <meta property="og:title" content="Metronic - Bootstrap 5 HTML, VueJS, React, Angular &amp; Laravel Admin Dashboard Theme" /> */}
		{/* <meta property="og:url" content="https://keenthemes.com/metronic" /> */}
		{/* <meta property="og:site_name" content="Keenthemes | Metronic" /> */}
		{/* <link rel="canonical" href="Https://preview.keenthemes.com/metronic8" /> */}
		<link rel="shortcut icon" href="assets/media/logos/favicon.ico" />
		{/* <!--begin::Fonts--> */}
		{/* <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700" /> */}
		{/* <!--end::Fonts--> */}
		{/* <!--begin::Global Stylesheets Bundle(used by all pages)--> */}
		{/* <link href="assets/plugins/global/plugins.bundle.css" rel="stylesheet" type="text/css" /> */}
		{/* <link href="assets/css/style.bundle.css" rel="stylesheet" type="text/css" /> */}
		{/* <!--end::Global Stylesheets Bundle--> */}
	</head>
	{/* <!--end::Head--> */}
	{/* <!--begin::Body--> */}
	<body id="kt_body" className="bg-body">
		{/* <!--begin::Main--> */}
		<div className="d-flex flex-column flex-root">
			{/* <!--begin::Authentication - Sign-in --> */}
			<div className="d-flex flex-column flex-lg-row flex-column-fluid">
				{/* <!--begin::Aside--> */}
				<div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative" style={{backgroundColor: `#3D3D3D`}}>
					{/* <!--begin::Wrapper--> */}
					<div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y-none scroll-y-disabled" style={{backgroundColor: `#3D3D3D`}}>
						{/* <!--begin::Content--> */}
						<div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
							{/* <!--begin::Logo--> */}
							<a href="/" className="py-9 mb-5">
								<img alt="Logo" src="assets/media/logos/logo-santoso.png" className="h-150px" />
							</a>
							{/* <!--end::Logo--> */}
							{/* <!--begin::Title--> */}
							<h1 className="fw-bolder fs-2qx pb-5 pb-md-10" style={{color: `#FFFFFF`}}>WELCOME TO<br />BENGKEL SANTOSO</h1>
							{/* <!--end::Title--> */}
							{/* <!--begin::Description--> */}
							<p className="fw-bold fs-2" style={{color: `#FFFFFF`}}>SILAKAN LOGIN UNTUK MENGAKSES SISTEM</p><br /><br />
							{/* <!--end::Description--> */}
                            {/* <!--begin::Illustration--> */}
                            <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px" style={{backgroundImage: `url(assets/media/illustrations/sketchy-1/13.png`}}></div>
                            {/* <!--end::Illustration--> */}
						</div>
						{/* <!--end::Content--> */}
					</div>
					{/* <!--end::Wrapper--> */}
				</div>
				{/* <!--end::Aside--> */}
				{/* <!--begin::Body--> */}
				<div className="d-flex flex-column flex-lg-row-fluid py-10" style={{minHeight:"100vh"}}>
					{/* <!--begin::Content--> */}
					<div className="d-flex flex-center flex-column flex-column-fluid">
						{/* <!--begin::Wrapper--> */}
						<div className="w-lg-500px p-10 p-lg-15 mx-auto">
							{/* <!--begin::Form--> */}
							    <form className="form w-100" id="kt_sign_in_form">
								{/* <!--begin::Heading--> */}
								<div className="text-center mb-10">
									{/* <!--begin::Title--> */}
									<h1 className="text-dark mb-3">Masuk ke Sistem</h1>
									{/* <!--end::Title--> */}
									{/* <!--begin::Link--> */}
									{/* <div className="text-gray-400 fw-bold fs-4">New Here? */}
									{/* <a href="../../demo1/dist/authentication/flows/aside/sign-up.html" className="link-primary fw-bolder">Create an Account</a></div> */}
									{/* <!--end::Link--> */}
								</div>
								{/* <!--begin::Heading--> */}
								{/* <!--begin::Input group--> */}
								<div className="fv-row mb-10">
                                    { errorMsg != '' ?
                                    // {/* <!--begin::Alert--> */}
                                    <div className="alert alert-danger d-flex flex-row align-items-center">
                                        {/* <!--begin::Icon--> */}
                                        <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
															<path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
														</svg>
													</span>
                                        {/* <!--end::Icon--> */}

                                        {/* <!--begin::Wrapper--> */}
                                        <div className="d-flex flex-column">
                                            {/* <!--begin::Title--> */}
                                            <h4 className="mb-1 text-dark">Ooops!</h4>
                                            {/* <!--end::Title--> */}
                                            {/* <!--begin::Content--> */}
                                            <span>{errorMsg}</span>
                                            {/* <!--end::Content--> */}
                                        </div>
                                        {/* <!--end::Wrapper--> */}
                                    </div>
                                    // {/* <!--end::Alert--> */}
                                    : "" }
                                    {/* <!--begin::Wrapper--> */}
									<div className="d-flex flex-stack mb-2">
									{/* <!--begin::Label--> */}
									<label className="form-label fs-6 fw-bolder text-dark">Email</label>
									{/* <!--end::Label--> */}
                                    </div>
									{/* <!--begin::Input--> */}
									<input className="form-control form-control-lg form-control-solid" type="email" name="email" autoComplete="off" onChange={
                                        (e) => setAuthInput({
                                            ...userAuthInput,
                                            email: e.target.value})}/>
									{/* <!--end::Input--> */}
								</div>
								{/* <!--end::Input group--> */}
								{/* <!--begin::Input group--> */}
								<div className="fv-row mb-10">
									{/* <!--begin::Wrapper--> */}
									<div className="d-flex flex-stack mb-2">
										{/* <!--begin::Label--> */}
										<label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
										{/* <!--end::Label--> */}
										{/* <!--begin::Link--> */}
										{/* <a href="../../demo1/dist/authentication/flows/aside/password-reset.html" className="link-primary fs-6 fw-bolder">Forgot Password ?</a> */}
										{/* <!--end::Link--> */}
									</div>
									{/* <!--end::Wrapper--> */}
									{/* <!--begin::Input--> */}
									<input className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" onChange={
                                        (e) => setAuthInput({
                                            ...userAuthInput,
                                            password: e.target.value})} />
									{/* <!--end::Input--> */}
								</div>
								{/* <!--end::Input group--> */}
								{/* <!--begin::Actions--> */}
								<div className="text-center">
									{/* <!--begin::Submit button--> */}
									<button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5" disabled={isLoading} onClick={handleLogin}>
										{isLoading?
										<span className="indicator-progress" style={{display:'block'}}>Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span> : <span className="indicator-label">Sign in</span> }
									</button>
									{/* <!--end::Submit button--> */}
								</div>
								{/* <!--end::Actions--> */}
							</form>
							{/* <!--end::Form--> */}
						</div>
						{/* <!--end::Wrapper--> */}
					</div>
					{/* <!--end::Content--> */}
					{/* <!--begin::Footer--> */}
					<div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
						{/* <!--begin::Links--> */}
						<div className="d-flex flex-center fw-bold fs-6">
							{/* <a href="https://keenthemes.com" className="text-muted text-hover-primary px-2" target="_blank">About</a> */}
							{/* <a href="https://keenthemes.com/support" className="text-muted text-hover-primary px-2" target="_blank">Support</a> */}
							{/* <a href="https://1.envato.market/EA4JP" className="text-muted text-hover-primary px-2" target="_blank">Purchase</a> */}
						</div>
						{/* <!--end::Links--> */}
					</div>
					{/* <!--end::Footer--> */}
				</div>
				{/* <!--end::Body--> */}
			</div>
			{/* <!--end::Authentication - Sign-in--> */}
		</div>
		{/* <!--end::Main--> */}
		{/* <!--begin::Javascript--> */}
		{/* <!--begin::Global Javascript Bundle(used by all pages)--> */}
		<script src="assets/plugins/global/plugins.bundle.js"></script>
		<script src="assets/js/scripts.bundle.js"></script>
		{/* <!--end::Global Javascript Bundle--> */}
		{/* <!--begin::Page Custom Javascript(used by this page)--> */}
		<script src="assets/js/custom/authentication/sign-in/general.js"></script>
		{/* <!--end::Page Custom Javascript--> */}
		{/* <!--end::Javascript--> */}
	</body>
	{/* <!--end::Body--> */}
</html>
</PageAnimate>
)
}