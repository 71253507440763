import React, { useState } from 'react'
import {Modal, Spinner }from 'react-bootstrap';
import ModalWrapper from './ModalWrapper';
import { useGlobalModalContext } from '../../context/GlobalModals';
import {delay} from '../../tools/delay'
import http from '../../api/http';
import { sparepartAPI } from '../../api/sparepart';
import { Axios, AxiosResponse } from 'axios';

interface showData {
    showGlobal: boolean
    apiFunction : Function
    afterSubmit: Function
    id: string
} 

export default function DeleteConfirmation({showGlobal=false, apiFunction, id, afterSubmit}:showData) {
    const [show, setShow] = useState(showGlobal);

    const { hideModal } = useGlobalModalContext()

    const handleClose = async () => {
        setShow(false);
        await delay(500);
        hideModal();
    }

    const showModal = () => {
        setShow(true);
    }


    const [isSubmitting, setIsSubmitting] = useState(false);

    async function submitDelete(){
        try {
            setIsSubmitting(true);
            const deleteResponse = await apiFunction(id);
            console.log(apiFunction)
            if (deleteResponse.status === 200){
                setIsSubmitting(false);
                afterSubmit();
                handleClose();
            }
            setIsSubmitting(false);
        } catch (e) {
            setIsSubmitting(false);
        }
        
    }


    return (
        <>
        <ModalWrapper>
            <Modal title={'KONFIRMASI'}
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered
            size="lg"
            keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>KONFIRMASI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <span>APAKAH ANDA YAKIN MENGHAPUS DATA INI?</span>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={handleClose}>Batal</button>
                    <button type="button" className="btn btn-danger" disabled={isSubmitting} onClick={e=> submitDelete()}>{isSubmitting? <Spinner animation="border" style={{
                // marginLeft:"50vw",
                // display:"flex",
                // flex: 1,
                // marginTop:"50vh",
                justifyContent: 'center',
                alignItems:'center'}} /> : 'HAPUS'}</button>
                </Modal.Footer>
            </Modal>
        </ModalWrapper>
        </>
    )
}
