import axios from 'axios';

import conf from '../conf';

const http = axios.create({
  baseURL: conf.backendUrl,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer idtoken'

  },
//   xsrfHeaderName: 'X-CSRFTOKEN',
//   xsrfCookieName: 'csrftoken',
  // withCredentials: true,
});

export default http;