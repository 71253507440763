import React from 'react'
import { PageAnimate } from '../../components/PageAnimate'
import SketsaController from '../../components/klaim_sketsa/SketsaController';
import Umum from '../../components/klaim/Umum';
import { Link } from 'react-router-dom';
import { PageLink, PageTitle, useLayout } from '../../context/core';
import NotesButton from '../../components/klaim/NotesButton';
import DefaultKlaimButton from '../../components/buttons/DefaultKlaimButtons'
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';
import { detailKlaimRoutes } from '../../routes/page-route-list';

export default function KlaimSketsa() {
    const BreadCrumbs: Array<PageLink> = [
		{
		  title: 'Santoso',
		  path: '/',
		  isSeparator: false,
		  isActive: false,
		},
		{
		  title: '',
		  path: '',
		  isSeparator: true,
		  isActive: false,
		},
		{
			title: 'Klaim Baru',
			path: '',
			isSeparator: false,
			isActive: false,
		  },
		  {
			title: '',
			path: '',
			isSeparator: true,
			isActive: false,
		  },
    ];

    const {config, handleHeightChange} = useLayout();

    const {showModal} = useGlobalModalContext();

    const handleModalFotoMobil = () => {
        showModal(MODAL_TYPES.IMAGE_UPLOAD, {windowTitle: 'Foto Mobil Masuk'});
    }
    
    return (
        <PageAnimate>
            <PageTitle breadcrumbs={BreadCrumbs}>Sketsa</PageTitle>
            <Umum onHeightChange={handleHeightChange} topButtons={<DefaultKlaimButton />}>
                {/* <!--begin::Page title--> */}
                <div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex d-block flex-row align-items-start me-3 mb-5 mb-lg-0">
                {/* <!--begin::Title--> */}
                {/* <!--begin::Trigger--> */}
                <button type="button" className="btn btn-light"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-start">
                    Sketsa
                    <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
                </button>
                {/* <!--end::Trigger--> */}

                {/* <!--begin::Menu--> */}
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                    data-kt-menu="true">
                    {detailKlaimRoutes.map((routeLink) => {
						return <>
						{/* <!--begin::Menu item--> */}
						<div className="menu-item px-3">
							<Link to={routeLink.path} className="menu-link px-3">
								{routeLink.label}
							</Link>
						</div>
						{/* <!--end::Menu item--> */}
						</>
					})}
                </div>
                <Link to="/klaim/new/estimasi/a" className="btn mx-3 btn-light me-3">Estimasi</Link>
                {/* <!--end::Menu--> */}

                <button onClick={e => handleModalFotoMobil()} className="btn  btn-light me-3"  >Foto Mobil Masuk </button>



                {/* <!--end::Title--> */}
            </div>
            {/* <!--end::Page title--> */}
            {/* <!--begin::Action group--> */}
            <div className="d-flex align-items-center overflow-auto">
                {/* <!--begin::Wrapper--> */}
                <div className="d-flex align-items-center flex-shrink-0">
                    
                    <div className="flex-shrink-0">
                        <NotesButton />
                        <a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Edit </a>
                        <Link to="/klaim/new/checklist" className="btn btn-sm btn-success me-lg-1">OK </Link>
                        
                    </div>
                </div>
                {/* <!--end::Wrapper--> */}
                
            </div>
            {/* <!--end::Action group--> */}
            </Umum>
            <div className="post d-flex flex-column-fluid" id="kt_post">
            <div id="kt_content_container" className="container-fluid">

                {/* <div className="d-flex flex-column flex-fill flex-grow"> */}
                <div className="card mb-5 mb-xl-8">
                    {/* <!--begin::Header--> */}
                    
                    
                    {/* <!--end::Header--> */}
                    {/* <!--begin::Body--> */}
                    <div className="card-body py-3">
                        <SketsaController />
                    </div>
                    {/* <!--end::Body--> */}
                </div>
                {/* </div> */}
                </div>
            </div>
        </PageAnimate>
    )
}
