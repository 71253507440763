import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {IRegisterData} from '../../api/registerData/types'
import Select, { SingleValue } from 'react-select'
import { PageAnimate } from '../../components/PageAnimate'
import { delay } from '../../tools/delay'
import { useLayout } from '../../context/core'
import Umum from '../../components/klaim/Umum'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DefaultKlaimButtons from '../../components/buttons/DefaultKlaimButtons';
import { detailKlaimRoutes } from '../../routes/page-route-list'


export default function RegisterData() {
	type selectValue = {
		value: string,
		label: string
	}
	const [checked, setChecked] = React.useState(true);
	
	// const [derek, setDerek] = useState(false)

	const handleChange = () => {
		// setDerek(!derek)
		handleFormInput("derek", !(formData.derek!))
	}
	const jenisKendaraanOpts: any[] =[
		{
			value : "MOBIL",
			label : "MOBIL"
		},
		{	
			value : "BIS",
			label : "BIS"
		},
		{
			value : "TRUK",
			label : "TRUK"
		},
		{
			value : "TRUK_GANDENG",
			label : "TRUK GANDENG"
		},
		{
			value : "MOBIL_UMUM",
			label : "MOBIL UMUM"
		},
		{
			value : "BIS_UMUM",
			label : "BIS UMUM"
		},
		{
			value : "TRUK_UMUM",
			label : "TRUK UMUM"
		},
		{
			value : "TRUK_GANDENG_UMUM",
			label : "TRUK GANDENG UMUM"
		}
		]
	const jenisCustomerOpts =[
		{
			value: "STANDARD",
			label: "STANDARD"	
		},
		{
			value: "REGULER",
			label: "REGULER"	
		},
		{
			value: "VIP",
			label: "VIP"
		}
		]
	const jenisPenanggungOpts =[
		{
			value: "PRIBADI",
			label: "PRIBADI"
		},
		{
			value: "Asuransi",
			label: "Asuransi"
		}
	]
	const jenisSimOpts =[
		{
			value: "A",
			label: "A"
		},
		{
			value: "B",
			label: "B"
		},
		{
			value: "C",
			label: "C"
		},
		{
			value: "B1",
			label: "B1"
		},
		{
			value: "B2",
			label: "B2"
		},
		{
			value: "A UMUM",
			label: "A UMUM"
		},
		{
			value: "B UMUM",
			label: "B UMUM"
		},
		{
			value: "B1 UMUM",
			label: "B1 UMUM"
		},
		{
			value: "B2 UMUM",
			label: "B2 UMUM"
		}
	]
	const jenisKelasKerusakanOpts =[
		{
			value: "RINGAN",
			label: "RINGAN"
		},
		{
			value: "MEDIUM",
			label: "MEDIUM"
		},
		{
			value: "BERAT",
			label: "BERAT"
		}
	]
	const jenisDerekOpts =[
		{
			value: "true",
			label: "DEREK"
		},
		{
			value: "false",
			label: "TIDAK"
		},
	]
	const statusOpts:any[] =[
		{
			value: "PRIBADI",
			label: "PRIBADI"
		},
		{
			value: "ASURANSI UMUM",
			label: "ASURANSI UMUM"
		},
		{
			value: "ASURANSI PENANGGUNG TJH",
			label: "ASURANSI PENANGGUNG TJH"
		},
		{
			value: "ASURANSI SEBAGAI TJH",
			label: "ASURANSI SEBAGAI TJH"
		},
	]
	const asuransiDummyOpts =[
		{
			value: "ASURANSI 1",
			label: "ASURANSI 1"
		},
		{
			value: "ASURANSI 2",
			label: "ASURANSI 2"
		},
		{
			value: "ASURANSI 3",
			label: "ASURANSI 3"
		},
		{
			value: "ASURANSI 4",
			label: "ASURANSI 4"
		},
	]

	const [formData, setformData] = useState<IRegisterData>({
		derek: false,
	});

	const handleFormInput = (fieldName:string, value:any)=>{
		const newData:IRegisterData = {
			...formData,
			[fieldName] : value
		}

		setformData(newData)
	}

	const [selectedStatus, setSelectedStatus] = useState<SingleValue<string>|null>()

	async function handleSelectedStatus(e:SingleValue<string>) {
		setSelectedStatus(e);
		const val = ((e as unknown) as selectValue).value

		if (val === 'PRIBADI') {
			console.log("keluar disni gann");
			await handleFormInput('asuransi', '');
			await delay(1000);
			await handleFormInput('penanggungTJH','');
			await delay(1000);
			console.log('setelah diset')
			console.log(formData)
		}
		
		await handleFormInput("status", val)
		console.log(selectedStatus)
		console.log(formData);
	}

	const {config, handleHeightChange} = useLayout();


    return (
		<PageAnimate>
        
        {/* <!--begin::Toolbar--> */}
		<Umum onHeightChange={handleHeightChange} topButtons={<DefaultKlaimButtons />}>

							{/* <!--begin::Container--> */}
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-wrap flex-stack">
								{/* <!--begin::Page title--> */}
								<div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
									{/* <!--begin::Title--> */}
									{/* <!--begin::Trigger--> */}
									<button type="button" className="btn btn-light"
										data-kt-menu-trigger="click"
										data-kt-menu-placement="bottom-start">
										DATA PROFIL
										<span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
									</button>
									{/* <!--end::Trigger--> */}

									{/* <!--begin::Menu--> */}
									<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
										data-kt-menu="true">
										{detailKlaimRoutes.map((routeLink) => {
											return <>
											{/* <!--begin::Menu item--> */}
											<div className="menu-item px-3">
												<Link to={routeLink.path} className="menu-link px-3">
													{routeLink.label}
												</Link>
											</div>
											{/* <!--end::Menu item--> */}
											</>
										})}
									</div>
									{/* <!--end::Menu--> */}

									{/* <!--end::Title--> */}
									
								</div>
								{/* <!--end::Page title--> */}
								{/* <!--begin::Action group--> */}
								<div className="d-flex align-items-center overflow-auto">
									{/* <!--begin::Wrapper--> */}
									<div className="d-flex align-items-center flex-shrink-0">
										
										<div className="flex-shrink-0">
											
                                            <a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Download </a>
                                            <Link to="/klaim/new/sketsa" className="btn btn-sm btn-success me-lg-1" >OK </Link>
											
										</div>
									</div>
									{/* <!--end::Wrapper--> */}
									
								</div>
								{/* <!--end::Action group--> */}
							</div>
							
							{/* <!--end::Container--> */}
						</Umum>
						
						{/* <!--end::Toolbar--> */}
						{/* <!--begin::Post--> */}
						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
								<div className="card mb-5 mb-xl-8">
									{/* <!--begin::Header--> */}
									
									
									{/* <!--end::Header--> */}
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											{/* <!--begin::Table container--> */}
											<div className="row">
												
												<div className=" col-sm-6">
													{/* <!--begin::Table--> */}
													<form action="" className="form mb-15" method="post" id="kt_careers_form">
														{/* <!--begin::Input group--> */}
														<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 col-sm-6">
															
															{/* <!--begin::Table body--> */}
															<tbody>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NO. WO :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder="" value='SK00121001' readOnly/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NO POLISI :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" value={formData.noPolisi} placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NO. RANGKA :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={1}>
																		<input type="text" className="input-estimasi form-control form-control-solid" value={formData.noRangka} placeholder=""/>
																	</td>
																	<td>
																		<div className='d-flex'>
																		<button className="btn btn-icon btn-active-light-primary active mx-1">
																		<span className="svg-icon svg-icon-primary svg-icon-2x">
																			<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24" height="24"/>
																				<path d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z" fill="#000000" fill-rule="nonzero"/>
																			</g>
																			</svg>
																		</span>
																		</button>
																		<button className="btn btn-icon btn-active-light-primary active">
																		<FontAwesomeIcon icon={faPlus} />
																		</button>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">JENIS KEND :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<Select options={jenisKendaraanOpts} />
																		{/* <select className="form-select" aria-label="Select example">
																			<option>PILIH JENIS KENDARAAN</option>
																			<option value="mobil">MOBIL</option>
																			<option value="bis">BIS</option>
																			<option value="truk">TRUK</option>
																			<option value="truk_gandeng">TRUK GANDENG</option>
																			<option value="mobil_umum">MOBIL UMUM</option>
																			<option value="bis_umum">BIS UMUM</option>
																			<option value="truk_umum">TRUK UMUM</option>
																			<option value="truk_gandeng_umum">TRUK GANDENG UMUM</option>
																		</select> */}
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">MERK :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">TYPE :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NAMA MOBIL :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NAMA PEMILIK :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NO. MESIN :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">TAHUN :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NAMA AGEN :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<Select options={jenisCustomerOpts} />
																		{/* <select className="form-select" aria-label="Select example">
																			<option>PILIH JENIS CUSTOMER</option>
																			<option value="STANDARD">STANDARD</option>
																			<option value="REGULER">REGULER</option>
																			<option value="VIP">VIP</option>
																			
																		</select> */}
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">JENIS CUSTOMER:</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<Select options={jenisCustomerOpts} />
																		{/* <select className="form-select" aria-label="Select example">
																			<option>PILIH JENIS CUSTOMER</option>
																			<option value="STANDARD">STANDARD</option>
																			<option value="REGULER">REGULER</option>
																			<option value="VIP">VIP</option>
																			
																		</select> */}
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">WARNA :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">STATUS :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																		<Select options={statusOpts} value={selectedStatus} onChange={data => handleSelectedStatus(data)}/>
																		{/* <select className="form-select" aria-label="Select example">
																			<option>PILIH JENIS PENANGGUNG</option>
																			<option value="PRIBADI">PRIBADI</option>
																			<option value="ASURANSI">ASURANSI</option>
																			
																		</select> */}
																	</td>
																</tr>
																{ (formData.status === 'ASURANSI UMUM' || formData.status === 'ASURANSI SEBAGAI TJH' || formData.status === 'ASURANSI PENANGGUNG TJH' ) && <tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">ASURANSI:</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<Select options={asuransiDummyOpts} />
																		{/* <select className="form-select" aria-label="Select example">
																			<option>PILIH JENIS CUSTOMER</option>
																			<option value="STANDARD">STANDARD</option>
																			<option value="REGULER">REGULER</option>
																			<option value="VIP">VIP</option>
																			
																		</select> */}
																	</td>
																</tr> }
																{ (formData.status === 'ASURANSI SEBAGAI TJH' || formData.status === 'ASURANSI PENANGGUNG TJH' ) && <tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">PENANGGUNG TJH :</a>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder="" value={formData.penanggungTJH ?? ''} onChange={e=>handleFormInput('penanggungTJH', e.currentTarget.value)}/>
																	</td>
																</tr> }
																
																
															</tbody>
															{/* <!--end::Table body--> */}
														</table>
													</form>
													
													{/* <!--end::Table--> */}
												</div>
												<div className=" col-sm-6">
													<form action="" className="form mb-15" method="post" id="kt_careers_form">
														{/* <!--begin::Input group--> */}
														<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 col-sm-6">
															
															{/* <!--begin::Table body--> */}
															<tbody>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NO POLIS :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">HARGA PERTANGGUNGAN:</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">LIMIT TJH:</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NOMINAL 1 OR:</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td rowSpan={2}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">PERIODE POLIS :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" style={{width: '10%', paddingLeft: 'initial'}}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6" >DARI </a>
																	</td>
																		
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																	
																</tr>
																
																<tr>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" style={{width: '10%'}}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6" >SAMPAI</a>
																	</td>
																		
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																			<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td rowSpan={2}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">PERIODE STNK 5 TAHUN :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" style={{width: '10%', paddingLeft: 'initial'}}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6" >DARI </a>
																	</td>
																		
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																	
																</tr>
																
																<tr>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" style={{width: '10%'}}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6" >SAMPAI </a>
																	</td>
																		
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																			<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td rowSpan={2}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">PERIODE STNK PAJAK :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" style={{width: '10%', paddingLeft: 'initial'}}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6" >DARI </a>
																	</td>
																		
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																	
																</tr>
																
																<tr>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" style={{width: '10%'}}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6" >SAMPAI</a>
																	</td>
																		
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																			<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td rowSpan={2}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">PERIODE SIM :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" style={{width: '10%', paddingLeft: 'initial'}}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6" >DARI </a>
																	</td>
																		
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																	
																</tr>
																
																<tr>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" style={{width: '10%'}}>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6" >SAMPAI</a>
																	</td>
																		
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																			<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">JENIS SIM :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																		<Select options={jenisSimOpts} />
																		{/* <select className="form-select" aria-label="Select example">
																			<option>PILIH JENIS SIM</option>
																			<option value="A">A</option>
																			<option value="B">B</option>
																			<option value="B1">B1</option>
																			<option value="B2">B2</option>
																			<option value="A_UMUM">A UMUM</option>
																			<option value="B_UMUM">B UMUM</option>
																			<option value="B1_UMUM">B1 UMUM</option>
																			<option value="B2_UMUM">B2 UMUM</option>
																		</select> */}
																	</td>
																</tr>
																<tr>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" >
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NO HP 1:</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																	
																</tr>
																<tr>
																<td className="text-dark fw-bolder text-hover-primary fs-6"> 
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NO HP 2:</a>
																</td>
																	
																<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}> 
																	<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																</td>	
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">TANGGAL REGISTER :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																		<input type="date" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">TANGGAL MOBIL MASUK :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																		<input type="date" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">TANGGAL JANJI KELUAR :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																		<input type="date" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">DEREK :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																	<div className="form-check form-switch form-check-custom form-check-solid">
																		<input className="form-check-input  h-30px w-60px" type="checkbox" value="true" id="flexSwitchDefault" defaultChecked={false} checked={formData.derek} onChange={handleChange}/>
																	</div>
																	</td>
																</tr>
																{formData.derek &&<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NOMINAL DEREK :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={1}>
																	<span >
																		<input id="input1" className="form-check-input widget-13-check" type="checkbox" value="1"  defaultChecked={!checked}  onChange={() => setChecked(checked)} />
																		<label htmlFor="input1" className='mx-1'>Ditagihkan?</label>
																	</span>
																	</td>
																	
																</tr> }
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">KELAS KERUSAKAN :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3}>
																	<Select options={jenisKelasKerusakanOpts} isDisabled value={{value: 'RINGAN', label:'RINGAN'}}/>
																	{/* <select className="form-select" aria-label="Select example" disabled>
																			<option>PILIH KELAS KERUSAKAN</option>
																			<option value="RINGAN">RINGAN</option>
																			<option value="MEDIUM">MEDIUM</option>
																			<option value="BERAT">BERAT</option>
																		</select> */}
																	</td>
																</tr>
																<tr>
																	<td>
																		<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">NO KLAIM :</a>
																	</td>
																	
																	<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={3} >
																		<input type="text" className="input-estimasi form-control form-control-solid" placeholder=""/>
																	</td>
																</tr>
															</tbody>
															{/* <!--end::Table body--> */}
														</table>
													</form>
													
													{/* <!--end::Table--> */}
												</div>
											</div>
											
											
											{/* <!--end::Table container--> */}
										</div>
										
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
						</div>
						{/* <!--end::Post--> */}
					
						</PageAnimate>
    )
}
