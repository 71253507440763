import React, { useState } from 'react'
import {Modal, Spinner }from 'react-bootstrap';
import ModalWrapper from './ModalWrapper';
import { useGlobalModalContext } from '../../context/GlobalModals';
import Select, { SingleValue } from 'react-select'
import {delay} from '../../tools/delay'
import http from '../../api/http';
import { jasaAPI } from '../../api/jasa';
import { useNavigate } from "react-router-dom";
import { PageAnimate } from '../../components/PageAnimate'
import { AddJasa } from '../../api/jasa/types';


interface showData {
    showGlobal: boolean
    afterSubmit: Function
} 

export default function InsertJasaModal({showGlobal=false, afterSubmit}:showData) {
    const [show, setShow] = useState(showGlobal);

    const { hideModal } = useGlobalModalContext()

    const handleClose = async () => {
        setShow(false);
        await delay(500);
        hideModal();
    }

    function callDelete() {
        // return http.post(url,{id: id})
        return delay(1000);
    }

    const showModal = () => {
        setShow(true);
    }

    const [prefixJasa, setPrefix] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [kodeCharacter, setKodeCharacter] = useState("");
    const [hargaAsuransi, setHargaAsuransi] = useState<number|undefined>();
    const [hargaStandardRingan, setHargaStandardRingan] = useState<number|undefined>();
    const [hargaStandardBerat, setHargaStandardBerat] = useState<number|undefined>();
    const [hargaRegulerRingan, setHargaRegulerRingan] = useState<number|undefined>();
    const [hargaRegulerBerat, setHargaRegulerBerat] = useState<number|undefined>();
    const [hargaVipRingan, setHargaVipRingan] = useState<number|undefined>();
    const [hargaVipBerat, setHargaVipBerat] = useState<number|undefined>();
    const [hargaKenteng, setHargaKenteng] = useState<number|undefined>();
    const [hargaCat, setHargaCat] = useState<number|undefined>();
    const [hargaPoles, setHargaPoles] = useState<number|undefined>();
    const [hargaPasang, setHargaPasang] = useState<number|undefined>();

    const prefixOpts:any[] =[
		{
			value: "GC",
			label: "G/C"
		},
		{
			value: "KC",
			label: "K/C"
		},
		{
			value: "BP",
			label: "B/P"
		},
		{
			value: "LAINNYA",
			label: "LAINNYA"
		},
	]

    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsSubmitting(true);

        const jsonInput:AddJasa = {
            prefix: prefixJasa,
            serviceType: serviceType,
            keterangan: keterangan,
            kodeCharacter: kodeCharacter,
            hargaAsuransi: hargaAsuransi??0,
            hargaStandardRingan: hargaStandardRingan??0,
            hargaStandardBerat: hargaStandardBerat??0,
            hargaRegulerRingan: hargaRegulerRingan??0,
            hargaRegulerBerat: hargaRegulerBerat??0,
            hargaVipRingan: hargaVipRingan??0,
            hargaVipBerat: hargaVipBerat??0,
            hargaKenteng: hargaKenteng??0,
            hargaCat: hargaCat??0,
            hargaPoles: hargaPoles??0,
            hargaPasang: hargaPasang??0,
        };

        const addJasa = await jasaAPI.createJasa(jsonInput);
        setIsSubmitting(false);
        afterSubmit();
        handleClose();


    }

    const [isSubmitting, setIsSubmitting] = useState(false);

    async function submitDelete(){
        try {
            setIsSubmitting(true);
            await callDelete();
            setIsSubmitting(false);
            handleClose();
        } catch (e) {
            setIsSubmitting(false);
        }
        
    }

    const [selectedPrefix, setSelectedPrefix] = useState<SingleValue<string>|null>()

    type selectValue = {
		value: string,
		label: string
	}

	async function handleSelectedPrefix(e:SingleValue<string>) {
		setSelectedPrefix(e);
		const val = ((e as unknown) as selectValue).value
        setPrefix(val)
		// await setPrefix(val)
		// console.log(val);
	}



    return (
        <>
        <ModalWrapper>
            <Modal title={'INSERT DATA'}
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered
            size="xl"
            keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>TAMBAH JASA</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form className="form mb-15" id="kt_careers_form">
                            {/* <!--begin::Input group--> */}
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 col-6">
                                
                                {/* <!--begin::Table body--> */}
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">SERVICE TYPE :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                        <Select options={prefixOpts} value={selectedPrefix} onChange={data =>handleSelectedPrefix(data)} />
                                        </td>
                                    </tr>
                                    {prefixJasa !== 'LAINNYA'  &&<tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Service Type :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setServiceType(e.target.value)} value={serviceType} required />
                                        </td>
                                    </tr>}
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">KETERANGAN :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setKeterangan(e.target.value)} value={keterangan} required />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">KODE :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                        <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setKodeCharacter(e.target.value)} value={kodeCharacter} required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Harga Asuransi :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setHargaAsuransi(parseInt(e.target.value))} value={hargaAsuransi} required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2}>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">HARGA STANDARD :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2} style={{paddingLeft: 'initial'}}>
                                            <input type="text" className="form-control form-control-solid" placeholder="RINGAN" onChange={(e) => setHargaStandardRingan(parseInt(e.target.value))} value={hargaStandardRingan}required/>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                    <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2} style={{paddingLeft: 'initial'}}>
                                            <input type="text" className="form-control form-control-solid" placeholder="BERAT" onChange={(e) => setHargaStandardBerat(parseInt(e.target.value))} value={hargaStandardBerat}required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2}>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">HARGA REGULER :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2} style={{paddingLeft: 'initial'}}>
                                            <input type="text" className="form-control form-control-solid" placeholder="RINGAN" onChange={(e) => setHargaRegulerRingan(parseInt(e.target.value))} value={hargaRegulerRingan}required/>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                    <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2} style={{paddingLeft: 'initial'}}>
                                            <input type="text" className="form-control form-control-solid" placeholder="BERAT" onChange={(e) => setHargaRegulerBerat(parseInt(e.target.value))} value={hargaRegulerBerat}required/>
                                    </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2}>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">HARGA VIP :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2} style={{paddingLeft: 'initial'}}>
                                            <input type="text" className="form-control form-control-solid" placeholder="RINGAN" onChange={(e) => setHargaVipRingan(parseInt(e.target.value))} value={hargaVipRingan}required/>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2} style={{paddingLeft: 'initial'}}>
                                            <input type="text" className="form-control form-control-solid" placeholder="BERAT" onChange={(e) => setHargaVipBerat(parseInt(e.target.value))} value={hargaVipBerat}required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Harga Kenteng :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setHargaKenteng(parseInt(e.target.value))} value={hargaKenteng} required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Harga Cat :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setHargaCat(parseInt(e.target.value))} value={hargaCat} required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Harga Poles :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setHargaPoles(parseInt(e.target.value))} value={hargaPoles} required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">Harga Pasang :</a>
                                        </td>
                                        <td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={2}>
                                            <input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => setHargaPasang(parseInt(e.target.value))} value={hargaPasang} required/>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                                {/* <!--end::Table body--> */}
                            </table>
                </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={handleClose}>Batal</button>
                    <button type="button" className="btn btn-primary" disabled={kodeCharacter ==='' || isSubmitting} onClick={handleSubmit}>{isSubmitting? <Spinner animation="border" style={{
                // marginLeft:"50vw",
                // display:"flex",
                // flex: 1,
                // marginTop:"50vh",
                justifyContent: 'center',
                alignItems:'center'}} /> : 'SIMPAN'}</button>
                </Modal.Footer>
            </Modal>
        </ModalWrapper>
        </>
    )
                    }
