import { SantosoConfig } from "./types";

const getConf = (env:string):SantosoConfig => {
    console.log(env);
    if (env === 'production') {
        return {
            backendUrl: "https://backend-santoso.hendranata.com",
        }
    } else if (env === 'staging') {
        return{
            backendUrl: "https://api.office.santosobengkel.id",
        }
    }
    return {
        backendUrl: "http://127.0.0.1:3500"
    }
}

const conf = getConf(process.env.NODE_ENV ?? 'development');

export default conf;