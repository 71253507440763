import React from 'react'
import { Link } from 'react-router-dom'
import { PageAnimate } from '../../components/PageAnimate'
import Umum  from '../../components/klaim/Umum'
import { PageLink, PageTitle, useLayout } from '../../context/core';
import NotesButton from '../../components/klaim/NotesButton';
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';
import DefaultKlaimButtons from '../../components/buttons/DefaultKlaimButtons';
import { detailKlaimRoutes } from '../../routes/page-route-list';

export default function SuratSalvage() {
    const [checked, setChecked] = React.useState(true);
	const options = [
    {label: "one", value: 1, style: { color: 'red' }},
    {label: "two", value: 2, style: { color: 'blue' }}
    // more options...
	];

	const BreadCrumbs: Array<PageLink> = [
		{
		  title: 'Santoso',
		  path: '/',
		  isSeparator: false,
		  isActive: false,
		},
		{
		  title: '',
		  path: '',
		  isSeparator: true,
		  isActive: false,
		},
		{
			title: 'Klaim Baru',
			path: '',
			isSeparator: false,
			isActive: false,
		  },
		  {
			title: '',
			path: '',
			isSeparator: true,
			isActive: false,
		  },
	];

	const {config, handleHeightChange} = useLayout();

	const {showModal} = useGlobalModalContext();

	function showImageUploadModal() {
        showModal(MODAL_TYPES.IMAGE_UPLOAD, {
        });
    }

	
    return (
		<PageAnimate>
			<PageTitle breadcrumbs={BreadCrumbs}>Surat Salvage</PageTitle>
			<Umum onHeightChange={handleHeightChange} topButtons={<DefaultKlaimButtons />}>
            {/* <!--begin::Page title--> */}
            <div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex d-block flex-row align-items-start me-3 mb-5 mb-lg-0">
                {/* <!--begin::Title--> */}
                {/* <!--begin::Trigger--> */}
                <button type="button" className="btn btn-light"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-start">
                    Surat Salvage
                    <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
                </button>
                {/* <!--end::Trigger--> */}

                {/* <!--begin::Menu--> */}
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                    data-kt-menu="true">
                    {detailKlaimRoutes.map((routeLink) => {
						return <>
						{/* <!--begin::Menu item--> */}
						<div className="menu-item px-3">
							<Link to={routeLink.path} className="menu-link px-3">
								{routeLink.label}
							</Link>
						</div>
						{/* <!--end::Menu item--> */}
						</>
					})}
                </div>
                {/* <!--end::Menu--> */}

                {/* <!--end::Title--> */}
            </div>
            {/* <!--end::Page title--> */}
            {/* <!--begin::Action group--> */}
            <div className="d-flex align-items-center overflow-auto">
                {/* <!--begin::Wrapper--> */}
                <div className="d-flex align-items-center flex-shrink-0">
                <input type="text" className="form-control me-lg-3" placeholder="T.AWAL" style={{maxWidth:'150px'}}/>
                <input type="text" className="form-control me-lg-3" placeholder="T.AKHIR" style={{maxWidth:'150px'}}/>
                    <div className="flex-shrink-0">
                        
					<NotesButton />
					<a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">EDIT </a>
                        <Link to="/klaim/new/spk" className="btn btn-sm btn-success me-lg-1">OK </Link>
                        
                    </div>
                </div>
                {/* <!--end::Wrapper--> */}
                
            </div>
            {/* <!--end::Action group--> */}
        </Umum>

						{/* <!--begin::Post--> */}

						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
								<div className="card mb-5 mb-xl-8">
									{/* <!--begin::Header--> */}
                                    <div className="card-header border-0 pt-5">
                                        {/* <h3>SURAT SALVAGE</h3> */}
                                    </div>
                                    {/* <!--end::Header--> */}
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											{/* <!--begin::Table container--> */}
											<div className="table-responsive table-limited-height-300">
												{/* <!--begin::Table--> */}
												<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
													{/* <!--begin::Table head-->/ */}
													<thead>
														<tr className="fw-bolder text-muted">
                                                            <td className=" th-estimasi-sticky">No</td>
                                                            <td className=" th-estimasi-sticky">Kode</td>
															<td className=" th-estimasi-sticky">Nama Sparepart</td>
                                                            <td className=" th-estimasi-sticky">Number Part</td>
                                                            <td className=" th-estimasi-sticky">Foto Rusak</td>
															<td className=" th-estimasi-sticky">ST</td>
                                                            <td className=" th-estimasi-sticky">Ket</td>
															<td className=" th-estimasi-sticky">Hilang</td>
                                                            <td className=" th-estimasi-sticky">Tidak di supply</td>
                                                            <td className=" th-estimasi-sticky">Keterangan</td>
                                                            
														</tr>
                                                    </thead>
													{/* <!--end::Table head--> */}
													{/* <!--begin::Table body--> */}
													<tbody>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">1</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td>
															    <input className="form-check-input widget-13-check" type="checkbox" value="1"  defaultChecked={checked}  onChange={() => setChecked(!checked)} />
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
                                                                -
                                                            </td>
														</tr>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">2</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td>
                                                            <button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
                                                                -
                                                            </td>
														</tr>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">3</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td>
                                                            <button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
                                                                -
                                                            </td>
														</tr>
													</tbody>
													{/* <!--end::Table body--> */}
												</table>
												{/* <!--end::Table--> */}
											</div>
											{/* <!--end::Table container--> */}
										</div>
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
                            
						</div>
						{/* <!--end::Post--> */}
						</PageAnimate>
    )
}
