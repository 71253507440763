import React from 'react'
import { Link } from 'react-router-dom'
import { PageAnimate } from '../../components/PageAnimate'
import Umum  from '../../components/klaim/Umum'
import { PageLink, PageTitle, useLayout } from '../../context/core';
import NotesButton from '../../components/klaim/NotesButton';
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';
import EstimasiButtons from '../../components/buttons/EstimasiButtons';
import {detailKlaimRoutes} from '../../routes/page-route-list'

export default function EstimasiA() {
    const [checked, setChecked] = React.useState(true);
	const options = [
    {label: "one", value: 1, style: { color: 'red' }},
    {label: "two", value: 2, style: { color: 'blue' }}
    // more options...
	];

	const BreadCrumbs: Array<PageLink> = [
		{
		  title: 'Santoso',
		  path: '/',
		  isSeparator: false,
		  isActive: false,
		},
		{
		  title: '',
		  path: '',
		  isSeparator: true,
		  isActive: false,
		},
		{
			title: 'Klaim Baru',
			path: '',
			isSeparator: false,
			isActive: false,
		  },
		  {
			title: '',
			path: '',
			isSeparator: true,
			isActive: false,
		  },
	];

	const {config, handleHeightChange} = useLayout();

	const {showModal} = useGlobalModalContext();

	function showImageUploadModal() {
        showModal(MODAL_TYPES.IMAGE_UPLOAD, {
        });
    }

	
    return (
		<PageAnimate>
			<PageTitle breadcrumbs={BreadCrumbs}>Estimasi A</PageTitle>
			<Umum onHeightChange={handleHeightChange} topButtons={<EstimasiButtons />}>
            {/* <!--begin::Page title--> */}
            <div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex d-block flex-row align-items-start me-3 mb-5 mb-lg-0">
                {/* <!--begin::Title--> */}
                {/* <!--begin::Trigger--> */}
                <button type="button" className="btn btn-light"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-start">
                    Estimasi A
                    <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
                </button>
                {/* <!--end::Trigger--> */}

                {/* <!--begin::Menu--> */}
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                    data-kt-menu="true">
					{detailKlaimRoutes.map((routeLink) => {
						return <>
						{/* <!--begin::Menu item--> */}
						<div className="menu-item px-3">
							<Link to={routeLink.path} className="menu-link px-3">
								{routeLink.label}
							</Link>
						</div>
						{/* <!--end::Menu item--> */}
						</>
					})}
                </div>
                {/* <!--end::Menu--> */}

				<Link to="/klaim/new/sketsa" className="btn mx-3 btn-light me-3">Sketsa</Link>


                {/* <!--end::Title--> */}
            </div>
            {/* <!--end::Page title--> */}
            {/* <!--begin::Action group--> */}
            <div className="d-flex align-items-center overflow-auto">
                {/* <!--begin::Wrapper--> */}
                <div className="d-flex align-items-center flex-shrink-0">
                <input type="text" className="form-control me-lg-3" placeholder="T.AWAL" style={{maxWidth:'150px'}}/>
                <input type="text" className="form-control me-lg-3" placeholder="T.AKHIR" style={{maxWidth:'150px'}}/>
                    <div className="flex-shrink-0">
                        
					<NotesButton />
					<a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">EDIT </a>
                        <Link to="/klaim/new/spk" className="btn btn-sm btn-success me-lg-1">OK </Link>
                        
                    </div>
                </div>
                {/* <!--end::Wrapper--> */}
                
            </div>
            {/* <!--end::Action group--> */}
        </Umum>

						{/* <!--begin::Post--> */}

						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
								<div className="card mb-5 mb-xl-8">
									{/* <!--begin::Header--> */}
                                    <div className="card-header border-0 pt-5">
                                        <div id="kt_docs_search_handler_basic">
                                        {/* <!--begin::Input Form--> */}
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Cari Harga Sparepart" style={{maxWidth:'150px'}}/>
                                            <div className="input-group-append">
                                              <button className="btn btn-active-secondary" type="button">
                                                <i className="fa fa-search"></i>
                                              </button>	
                                            </div>
                                        </div>
                                        {/* <!--end::Form--> */}
                                        
                                        </div>
                                        
                                        <div className="d-flex align-items-center py-1">
                                            {/* <!--begin::Wrapper--> */}
                                            
                                            {/* <!--end::Wrapper--> */}
                                            <input type="text" className="form-control me-lg-2" placeholder="AWAL" style={{maxWidth:'150px'}}/>
                                            <input type="text" className="form-control me-lg-2" placeholder="AKHIR" style={{maxWidth:'150px'}}/>
                                            <input type="text" className="form-control me-lg-2" placeholder="DISCOUNT" style={{maxWidth:'150px'}}/>
                                            {/* <!--begin::Button--> */}
                                            <a href="#" className="btn btn-sm me-lg-2"><i className="fas fa-envelope-open-text fs-4 me-2"></i></a>
                                            <a href="#" className="btn btn-sm me-lg-2" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">
                                                <span className="svg-icon svg-icon-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                                    </svg>
                                                </span>
                                            </a>
                                            
                            
                                            {/* <!--end::Button--> */}
                                        </div>
                                    </div>
                                    {/* <!--end::Header--> */}
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											{/* <!--begin::Table container--> */}
											<div className="table-responsive table-limited-height-300">
												{/* <!--begin::Table--> */}
												<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-0">
													{/* <!--begin::Table head-->/ */}
													<thead>
														<tr className="fw-bolder text-muted">
                                                            <th className="min-w-120px th-estimasi-left-sticky sticky-col first-col">No</th>
                                                            <th className="min-w-120px th-estimasi-left-sticky sticky-col second-col">Kode</th>
															<th className="min-w-120px th-estimasi-left-sticky sticky-col third-col ">Nama Sparepart</th>
                                                            <th className="min-w-120px th-estimasi-sticky">Number Part</th>
                                                            <th className="min-w-120px th-estimasi-sticky">Ket</th>
															<th className="min-w-120px th-estimasi-sticky">Foto Rusak</th>
															<th className="min-w-150px th-estimasi-sticky">Hilang</th>
                                                            <th className="min-w-120px th-estimasi-sticky">SLVG Tidak Ready</th>
                                                            <th className="min-w-120px th-estimasi-sticky">Butuh Beli SLVG</th>
                                                            <th className="min-w-150px th-estimasi-sticky">ST</th>
															<th className="min-w-120px th-estimasi-sticky">Cat Abu / Hitam</th>
                                                            <th className="min-w-120px th-estimasi-sticky">Photo</th>
                                                            <th className="min-w-120px th-estimasi-sticky">Harga Baru</th>
                                                            <th className="min-w-120px th-estimasi-sticky">%</th>
                                                            <th className="min-w-150px th-estimasi-sticky">Status Cust</th>
                                                            <th className="min-w-150px th-estimasi-sticky">Stock / Beli</th>
															<th className="min-w-120px th-estimasi-sticky" th-estimasi-sticky>Repair</th>
														</tr>
													</thead>
													{/* <!--end::Table head--> */}
													{/* <!--begin::Table body--> */}
													<tbody>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col first-col">1</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col second-col">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col third-col">BUMPER</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
															<button onClick={showImageUploadModal}  className="btn btn-sm btn-secondary">LIHAT</button>

															</td>
                                                            <td>
															<label>
															<select className="form-select" data-control="select2">
																<option value="2">2</option>
																<option value="1">1</option>
																<option></option>
																
															</select>
															</label>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option value="2">2</option>
																<option value="1">1</option>
																<option value="3">3</option>
																<option value="4">4</option>
															</select>
															</label>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  defaultChecked={checked}  onChange={() => setChecked(!checked)} />
																</div>
															</td>
                                                            <td>
															<button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>

															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option value="baru">BARU</option>
																<option value="bekas">BEKAS</option>
																<option value="imitasi">IMITASI</option>
															</select>
															</label>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option value="stok">STOK</option>
																<option value="beli">BELI</option>
															</select>
															</label>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">KERJA</td>
														</tr>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col first-col">2</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col second-col">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col third-col">B</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
															<button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option></option>
																<option value="1">1</option>
																<option value="2">2</option>
															</select>
															</label>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option value="2">2</option>
																<option value="1">1</option>
																<option value="3">3</option>
																<option value="4">4</option>
															</select>
															</label>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option value="baru">BARU</option>
																<option value="bekas">BEKAS</option>
																<option value="imitasi">IMITASI</option>
															</select>
															</label>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option value="stok">STOK</option>
																<option value="beli">BELI</option>
															</select>
															</label>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">KERJA</td>
														</tr>
                                                        <tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col first-col">3</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col second-col">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col third-col">A (SALVAGE)</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
																<button className="btn btn-sm btn-secondary">LIHAT</button>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option></option>
																<option value="1">1</option>
																<option value="2">2</option>
															</select>
															</label>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option value="2">2</option>
																<option value="1">1</option>
																<option value="3">3</option>
																<option value="4">4</option>
															</select>
															</label>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option value="baru">BARU</option>
																<option value="bekas">BEKAS</option>
																<option value="imitasi">IMITASI</option>
															</select>
															</label>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option style={{ backgroundColor: 'red', color: 'blue' }} value="stok">STOK</option>
																<option value="beli">BELI</option>
															</select>
															</label>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">PENDING</td>
														</tr>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col first-col">4</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col second-col">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col third-col">TEST SCROLL</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
																<button className="btn btn-sm btn-secondary">LIHAT</button>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option></option>
																<option value="1">1</option>
																<option value="2">2</option>
															</select>
															</label>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option value="2">2</option>
																<option value="1">1</option>
																<option value="3">3</option>
																<option value="4">4</option>
															</select>
															</label>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1" />
																</div>
															</td>
                                                            <td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"  />
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option value="baru">BARU</option>
																<option value="bekas">BEKAS</option>
																<option value="imitasi">IMITASI</option>
															</select>
															</label>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">
															<label>
															<select className="form-select" data-control="select2">
																<option style={{ backgroundColor: 'red', color: 'blue' }} value="stok">STOK</option>
																<option value="beli">BELI</option>
															</select>
															</label>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">PENDING</td>
														</tr>
													</tbody>
													{/* <!--end::Table body--> */}
												</table>
												{/* <!--end::Table--> */}
											</div>
											{/* <!--end::Table container--> */}
										</div>
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
                            
						</div>
						{/* <!--end::Post--> */}
                       {/* <!--begin::Post2--> */}
						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
									<div className="card mb-5 mb-xl-8">
										{/* <!--begin::Header--> */}
										<div className="card-header border-0 pt-5">
											<div id="kt_docs_search_handler_basic">
											{/* <!--begin::Input Form--> */}
											<div className="input-group">
												<input type="text" className="form-control" placeholder="Cari Harga Jasa"/>
												<div className="input-group-append">
												  <button className="btn btn-active-secondary" type="button">
													<i className="fa fa-search"></i>
												  </button>	
												</div>
											</div>
											{/* <!--end::Form--> */}
											
											</div>
											
											<div className="d-flex align-items-center py-1">
												{/* <!--begin::Wrapper--> */}
												
												{/* <!--end::Wrapper--> */}
                                                <input type="text" className="form-control me-lg-2" placeholder="AWAL" style={{maxWidth:'150px'}}/>
                                                <input type="text" className="form-control me-lg-2" placeholder="AKHIR" style={{maxWidth:'150px'}}/>
                                                <input type="text" className="form-control me-lg-2" placeholder="DISCOUNT" style={{maxWidth:'150px'}}/>
												{/* <!--begin::Button--> */}
                                                <a href="#" className="btn btn-sm me-lg-2" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">
                                                    <span className="svg-icon svg-icon-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                </a>
												
								
												{/* <!--end::Button--> */}
											</div>
										</div>
										{/* <!--end::Header--> */}
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											<div className="table-responsive table-limited-height-300">
												{/* <!--begin::Table--> */}
												
												<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-0">
													{/* <!--begin::Table head--> */}
													<thead>
														<tr className="fw-bolder text-muted">
                                                            <th className="min-w-120px th-estimasi-sticky sticky-col first-col">No</th>
                                                            <th className="min-w-120px th-estimasi-sticky sticky-col second-col">Kode</th>
															<th className="min-w-120px th-estimasi-sticky">Nama Jasa</th>
															<th className="min-w-120px th-estimasi-sticky">Foto Rusak</th>
															<th className="min-w-120px th-estimasi-sticky">Kelas</th>
                                                            <th className="min-w-120px th-estimasi-sticky">ST</th>
															<th className="min-w-120px th-estimasi-sticky">Ket</th>
                                                            <th className="min-w-120px th-estimasi-sticky">Harga</th>
                                                            <th className="min-w-120px th-estimasi-sticky">%</th>
                                                            <th className="min-w-120px th-estimasi-sticky">Cat Abu / Hitam</th>
															<th className="min-w-120px th-estimasi-sticky">Repair</th>
														</tr>
													</thead>
													{/* <!--end::Table head--> */}
													{/* <!--begin::Table body--> */}
													<tbody>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col first-col">1</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col second-col">JKRM00001</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">G/C BUMPER</td>
															<td>
																<button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>
															</td>
                                                            <td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">R</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">2</a>
															</td>
															<td>
															<label>
															<select className="form-select form-select-solid" data-control="select2">
																<option value="1">CBS</option>
																<option value="2">SBR</option>
																<option value="2">SPR</option>
															</select>
															</label>
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">1500000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
															<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" checked={checked}  onClick={e => setChecked(!checked)}/>
																</div>															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">KERJA</td>
														</tr>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col first-col">2</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col second-col">JGRM00002</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">G/C A</td>
															<td>
															<button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>
															</td>
                                                            <td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">M</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">2</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
																<label>
																	<select className="form-select form-select-solid" data-control="select2">
																		<option value="1">CBS</option>
																		<option value="2">SBR</option>
																		<option value="2">SPR</option>
																	</select>
																	</label>
																</a>
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">2000000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"/>
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">KERJA</td>
														</tr>
                                                        <tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col first-col">3</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col second-col">JBRM00001</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">B/P A</td>
															<td>
															<button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>
															</td>
                                                            <td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">B</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">1</a>
															</td>
															<td>
															<label>
															<select className="form-select form-select-solid" data-control="select2">
																<option value="1">CBS</option>
																<option value="2">SBR</option>
																<option value="2">SPR</option>
															</select>
															</label>
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">150000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"/>
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">PENDING</td>
														</tr>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col first-col">4</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col second-col">JBRM00001</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">B/P A</td>
															<td>
															<button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>
															</td>
                                                            <td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">B</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">1</a>
															</td>
															<td>
															<label>
															<select className="form-select form-select-solid" data-control="select2">
																<option value="1">CBS</option>
																<option value="2">SBR</option>
																<option value="2">SPR</option>
															</select>
															</label>
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">150000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"/>
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">PENDING</td>
														</tr>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col first-col">5</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6 sticky-col second-col">JBRM00001</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">B/P A</td>
															<td>
															<button className="btn btn-sm btn-secondary">LIHAT</button>
															</td>
                                                            <td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">B</a>
															</td>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">1</a>
															</td>
															<td>
															<label>
															<select className="form-select form-select-solid" data-control="select2">
																<option value="1">CBS</option>
																<option value="2">SBR</option>
																<option value="2">SPR</option>
															</select>
															</label>
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">150000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
																<div className="form-check form-check-sm form-check-custom form-check-solid">
																	<input className="form-check-input widget-13-check" type="checkbox" value="1"/>
																</div>
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">PENDING</td>
														</tr>
													</tbody>
													{/* <!--end::Table body--> */}
												</table>
												{/* <!--end::Table--> */}
											</div>
											
										</div>
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
						</div>
						
						{/* <!--end::Post--> */}
                      
					
						</PageAnimate>
    )
}
