import React from 'react'
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';

export default function NotesButton() {
    const {showModal} = useGlobalModalContext();

    function handleNote() {
        showModal(MODAL_TYPES.NOTES_WO, {
        });
    }
    return (
        <button className="btn btn-sm btn-danger me-lg-1" onClick={handleNote}>NOTES </button>

    )
}
