import { AxiosResponse } from "axios";
import { Sparepart } from "./types"
import http from '../http'
import {auth} from '../../adapters/Firebase'
import {APIPaths} from '../paths';

interface SparepartAPI {
    getSparepart: () => Promise<AxiosResponse<Sparepart[]>>,
    createSparepart: (newSparepart:Sparepart) => Promise<AxiosResponse>,
    updateSparepart: (newSparepart:Sparepart) => Promise<AxiosResponse>,
    deleteSparepart: (uuid: string) => Promise<AxiosResponse>
}

export const sparepartAPI: SparepartAPI = {
    getSparepart: async () => {
        let idToken = await auth.currentUser!.getIdToken();
        console.log(idToken);
        let response = await http.get(APIPaths.getSparepartList, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response;
    },
    
    createSparepart: async (newSparepart) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = await http.post(APIPaths.addSparepart, newSparepart, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },

    updateSparepart: async (newSparepart) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = await http.post(APIPaths.updateSparepart, newSparepart, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },

    deleteSparepart: async (uuid) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = await http.post(APIPaths.deleteSparepart, {uuid: uuid}, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    
}