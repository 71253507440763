import {IPageRoute} from './types'

export const detailKlaimRoutes:IPageRoute[] = [
    {
        label: 'REGISTER GAMBAR',
        path: '/register/gambar',
    },
    {
        label: 'DATA PROFIL',
        path: '/register/data',
    },
    {
        label: 'SKETSA',
        path: '/klaim/new/sketsa',
    },
    {
        label: 'CHECKLIST',
        path: '/klaim/new/checklist',
    },
    {
        label: 'ESTIMASI',
        path: '/klaim/new/estimasi/a',
    },
    {
        label: 'SPK',
        path: '/klaim/new/spk',
    },
    {
        label: 'SURAT SALVAGE',
        path: '/klaim/new/surat-salvage',
    },
]