import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Select, { SingleValue } from 'react-select'
import { useGlobalModalContext } from '../../context/GlobalModals'
import { delay } from '../../tools/delay'
import ModalWrapper from './ModalWrapper'

interface showData {
    showGlobal: boolean
    afterSubmit: Function
} 

interface selectValue {
	value: string
	label: string
}

const flagStatuses:Array<any> = [
	{
		value: 'SANGAT_URGENT',
		label: "Sangat Urgent"
	},
	{
		value: 'URGENT',
		label: "Urgent"
	},
	{
		value: 'BIASA',
		label: "Biasa"
	},
]

export default function ToDoListDetail({showGlobal=false, afterSubmit}:showData) {

	const [show, setShow] = useState(showGlobal);
	const [showSetFlag, setFlag] = useState(false);
	const [selectedOption, setSelectedOption] = useState<SingleValue<string>|null>();

	const openSetFlag = (data:ITDLData) => {
		setTDLdata(data);
		const selOpt = flagStatuses.find(
			(vals) => vals.value === data.flag
			);
		setSelectedOption(selOpt);
		setFlag(true)
	}

	const handleSelectChange = (data:SingleValue<string>) => {
        // console.log(data);
        if(data) {

        }
        setSelectedOption(data);
    }

	const closeSetFlag = () => {
		setFlag(false)
	}

    const { hideModal } = useGlobalModalContext()

    const handleClose = async () => {
        setShow(false);
        handleReset();
        await delay(500);
        hideModal();
    }

	function handleReset() {
        Array.from(document.querySelectorAll("input")).forEach(
          input => (input.value = "")
        );
      };

	interface ITDLData {
		flag: string
		noWo: string
		namaMobil: string
		noPol: string
		pemilik: string
		agenPic: string
		asuransi: string
		timerAdmin: number
		timerLapangan: number
	}

	const [TDLdata, setTDLdata] = useState<ITDLData|null>();

	const contohData:ITDLData[] = [
		{
			flag:'SANGAT_URGENT',
			noWo: 'SN032201002',
			namaMobil: 'Toyota Avanza GT 2.1 2020',
			noPol: 'H 1234 CK',
			pemilik: 'PT ABC',
			agenPic: 'Kholik Umam',
			asuransi: 'Asuransi Etiqa',
			timerAdmin: 5,
			timerLapangan: 5

		},
		{
			flag:'URGENT',
			noWo: 'SN012201005',
			namaMobil: 'Toyota Agya GT 2.1 2020',
			noPol: 'H 4567 CK',
			pemilik: 'PT DEF',
			agenPic: 'Imroatun',
			asuransi: 'Asuransi Salvus',
			timerAdmin: 25,
			timerLapangan: 19

		},
		{
			flag:'BIASA',
			noWo: 'SN022201009',
			namaMobil: 'Toyota Innova GT 2.1 2020',
			noPol: 'H 9987 CK',
			pemilik: 'PT GHI',
			agenPic: 'Rusdi',
			asuransi: 'Asuransi Adira',
			timerAdmin: 9,
			timerLapangan: 8

		}
	];

        return (
		<>
        <ModalWrapper>
            <Modal title={'Rincian To-Do List'}
            show={show}
            onHide={handleClose}
            centered
            size="xl"
            keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Rincian To-Do List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
					<table className='table table-dotted'>
						<thead>
							<tr>
								<th className='text-center'>
									FLAG
								</th>
								<th>
									No. Wo
								</th>
								<th>
									Nama Mobil
								</th>
								<th>
									NoPol
								</th>
								<th>
									Pemilik
								</th>
								<th>
									Agen/PIC
								</th>
								<th>
									Asuransi
								</th>
								<th>
									Timer<br />Admin
								</th>
								<th>
									Timer<br />Lapangan
								</th>
							</tr>
						</thead>
						<tbody>
							{contohData.map(data => {
								return (
									<tr>
										<td className={`${data.flag==='SANGAT_URGENT'?'bg-danger text-white':data.flag==='URGENT'?'bg-warning':''} c-pointer text-hover-primary text-center`} onClick={e => openSetFlag(data)}>
											{data.flag.replace('_', ' ')}
										</td>
										<td>
											{data.noWo}
										</td>
										<td>
											{data.namaMobil}
										</td>
										<td>
											{data.noPol}
										</td>
										<td>
											{data.pemilik}
										</td>
										<td>
											{data.agenPic}
										</td>
										<td>
											{data.asuransi}
										</td>
										<td>
											{data.timerAdmin}
										</td>
										<td>
											{data.timerLapangan}
										</td>
									</tr>
								)
							})}
							
						</tbody>
					</table>
                
                </Modal.Body>
            </Modal>
			<Modal title={'Set Flag'}
            show={showSetFlag}
            onHide={closeSetFlag}
            backdrop
            centered
            keyboard>
				<Modal.Header>
				<Modal.Title>Ubah Flag {TDLdata?.noWo}</Modal.Title>
				</Modal.Header>
                <Modal.Body style={{}}>
					<div className="mb-3 row">
						<label className="col-sm-2 col-form-label">Flag</label>
						<div className="col-sm-10">
							<Select value={selectedOption} options={flagStatuses} onChange={selectedOption=> handleSelectChange(selectedOption)}/>
							
						</div>
					</div>
                </Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={closeSetFlag}>Ubah</button>
				</Modal.Footer>
            </Modal>
        </ModalWrapper>
        </>
    )
}
