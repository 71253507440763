import React, { useEffect, useState } from 'react'
import {Modal, Button, Spinner, Image }from 'react-bootstrap';
import ModalWrapper from './ModalWrapper';
import Select, { SingleValue } from 'react-select'
import { Notes } from '../../api/notes/types';
import { notesAPI } from '../../api/notes';
import { DateTime, Settings } from 'luxon';
import { getAuth } from '@firebase/auth';
import { useGlobalModalContext } from '../../context/GlobalModals';
import {delay} from '../../tools/delay'
import ImageUploading from 'react-images-uploading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'


interface showData {
    showGlobal: boolean
    afterSubmit: Function
    windowTitle?: string
    existingUrl: any[]
} 

export default function ImageUploadModal({showGlobal=false, windowTitle="Foto Rusak"}:showData) {
    const [show, setShow] = useState(showGlobal);
    const [imageShow, setImageShow] = useState(false);

    const { hideModal } = useGlobalModalContext()

    const handleClose = async () => {
        setShow(false);
        await delay(500);
        hideModal();
    }

    const handleImageClose = async () => {
        setImageShow(false);
        setImgToShow("");
    }



    const showModal = () => {
        setShow(true);
    }

    Settings.defaultLocale = "id";
    const dt = DateTime.now()
    dt.setLocale('id')

    const [images, setImages] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [imgToShow, setImgToShow] = useState("");

    const onImageClick = (imgUrl:string) => {
        setImgToShow(imgUrl);
        setImageShow(true);
    }



    async function saveNote(note:any){
        try {
            console.log()
            setIsSubmitting(true);
            await delay(1000);
            setIsSubmitting(false);
            handleClose();
        } catch (e) {
            setIsSubmitting(false);
        }
        
    }

    const onChange = (imageList:any, addUpdateIndex:any) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
      };

    const maxNumber = 10;


    return (
        <>
        <ModalWrapper>
            <Modal title={windowTitle}
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered
            size="xl"
            keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{windowTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    }) => (
                    // write your building UI
                    <div className="d-flex flex-wrap">
                        <button
                        style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                        className="btn btn-lg btn-secondary my-3 mx-2"
                        {...dragProps}
                        >
                        <FontAwesomeIcon icon={faPlus} />
                        </button>
                        &nbsp;
                        {imageList.map((image, index) => (
                            // {/* <!--begin::Image input--> */}
                            <>
								<div className="image-input image-input-outline mr-6 px-2 my-3" data-kt-image-input="true" style={{backgroundImage : `url(${image['data_url']})`}}>
                                    {/* <!--begin::Image preview wrapper--> */}
												<div className="image-input-wrapper w-200px h-130px " style={{backgroundImage: `url(${image['data_url']})`, backgroundPosition:'center'}} onClick={e => onImageClick(image['data_url'])}></div>
												{/* <!--end::Image preview wrapper--> */}
                                    
                                    {/* <!--begin::Edit button--> */}
                                    <label onClick={() => onImageUpdate(index)} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    data-bs-dismiss="click"
                                    title="Change image">
                                        <i className="bi bi-pencil-fill fs-7"></i>

                                        {/* <!--begin::Inputs--> */}
                                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" multiple/>
                                        <input type="hidden" name="avatar_remove" />
                                        {/* <!--end::Inputs--> */}
                                    </label>
                                    {/* <!--end::Edit button--> */}

                                    {/* <!--begin::Cancel button--> */}
                                    <span onClick={() => onImageRemove(index)} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                    data-kt-image-input-action="cancel"
                                    data-bs-toggle="tooltip"
                                    data-bs-dismiss="click"
                                    title="Cancel avatar">
                                        <i className="bi bi-x fs-2"></i>
                                    </span>
                                    {/* <!--end::Cancel button--> */}

                                    {/* <!--begin::Remove button--> */}
                                    <span onClick={() => onImageRemove(index)} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                    data-kt-image-input-action="remove"
                                    data-bs-toggle="tooltip"
                                    data-bs-dismiss="click"
                                    title="Remove image">
                                        <i className="bi bi-x fs-2"></i>
                                    </span>
                                {/* <!--end::Remove button--> */}
                            </div>
                        {/* // <!--end::Image input--> */}
                        {/* <div key={index} className="image-item">
                            <img src={image['data_url']} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                            <button >Update</button>
                            </div>
                        </div> */}
                        </>
                        ))}
                    </div>
                    )}
                </ImageUploading>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={e=> saveNote(images)}>{isSubmitting? <Spinner animation="border" style={{
                // marginLeft:"50vw",
                // display:"flex",
                // flex: 1,
                // marginTop:"50vh",
                justifyContent: 'center',
                alignItems:'center'}} /> : 'OK'}</button>
                </Modal.Footer>
            </Modal>
            <Modal title={'Preview image'}
            show={imageShow}
            onHide={handleImageClose}
            backdrop
            centered
            size="xl"
            keyboard>
                <Modal.Body style={{}}>
                    <Image src={imgToShow} style={{maxWidth:'100%', maxHeight: '100%'}}/>
                </Modal.Body>
            </Modal>
        </ModalWrapper>
        </>
    )
}
