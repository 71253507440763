import React from 'react'
import { Link } from 'react-router-dom'
import { PageAnimate } from '../../components/PageAnimate'
import Umum  from '../../components/klaim/Umum'
import NotesButton from '../../components/klaim/NotesButton';
import { PageLink, PageTitle, useLayout } from '../../context/core';
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';
import DefaultKlaimButtons from '../../components/buttons/DefaultKlaimButtons';
import { detailKlaimRoutes } from '../../routes/page-route-list';

export default function Spk() {
    const [checked, setChecked] = React.useState(true);
	const options = [
    {label: "one", value: 1, style: { color: 'red' }},
    {label: "two", value: 2, style: { color: 'blue' }}
    // more options...
];
const BreadCrumbs: Array<PageLink> = [
    {
      title: 'Santoso',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
        title: 'Klaim Baru',
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
];
const {showModal} = useGlobalModalContext();

function showImageUploadModal() {
    showModal(MODAL_TYPES.IMAGE_UPLOAD, {
    });
}

    const {config, handleHeightChange} = useLayout();
    return (
		<PageAnimate>
                        <PageTitle breadcrumbs={BreadCrumbs}>SPK</PageTitle>

        <Umum onHeightChange={handleHeightChange} topButtons={<DefaultKlaimButtons />}>
            {/* <!--begin::Page title--> */}
            <div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex d-block flex-row align-items-start me-3 mb-5 mb-lg-0">
                {/* <!--begin::Title--> */}
                {/* <!--begin::Trigger--> */}
                <button type="button" className="btn btn-light"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-start">
                    SPK
                    <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
                </button>
                {/* <!--end::Trigger--> */}

                {/* <!--begin::Menu--> */}
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                    data-kt-menu="true">
                    {detailKlaimRoutes.map((routeLink) => {
						return <>
						{/* <!--begin::Menu item--> */}
						<div className="menu-item px-3">
							<Link to={routeLink.path} className="menu-link px-3">
								{routeLink.label}
							</Link>
						</div>
						{/* <!--end::Menu item--> */}
						</>
					})}
                </div>
                {/* <!--end::Menu--> */}


                {/* <!--end::Title--> */}
            </div>
            {/* <!--end::Page title--> */}
            {/* <!--begin::Action group--> */}
            <div className="d-flex align-items-center overflow-auto">
                {/* <!--begin::Wrapper--> */}
                <div className="d-flex align-items-center flex-shrink-0">
                <input type="text" className="form-control me-lg-3" placeholder="T.AWAL"/>
                <input type="text" className="form-control me-lg-3" placeholder="T.AKHIR"/>
                    <div className="flex-shrink-0">
                        
                    <NotesButton />
                    <a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Edit </a>
                        <Link to="/klaim/new/checklist" className="btn btn-sm btn-success me-lg-1">OK </Link>
                        
                    </div>
                </div>
                {/* <!--end::Wrapper--> */}
                
            </div>
            {/* <!--end::Action group--> */}
        </Umum>

						{/* <!--begin::Post--> */}

						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
								<div className="card mb-5 mb-xl-8">
									{/* <!--begin::Header--> */}
                                    <div className="card-header border-0 pt-5">
                                        <div id="kt_docs_search_handler_basic">
                                        {/* <!--begin::Input Form--> */}
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Cari Harga Jasa"/>
                                            <div className="input-group-append">
                                              <button className="btn btn-active-secondary" type="button">
                                                <i className="fa fa-search"></i>
                                              </button>	
                                            </div>
                                        </div>
                                        {/* <!--end::Form--> */}
                                        
                                        </div>
                                        
                                        <div className="d-flex align-items-center py-1">
                                            {/* <!--begin::Wrapper--> */}
                                            
                                            {/* <!--end::Wrapper--> */}
                                            <input type="text" className="form-control me-lg-2" placeholder="AWAL"/>
                                            <input type="text" className="form-control me-lg-2" placeholder="AKHIR"/>
                                            <input type="text" className="form-control me-lg-2" placeholder="DISCOUNT"/>
                                            {/* <!--begin::Button--> */}
                                            <a href="#" className="btn btn-sm me-lg-2"><i className="fas fa-envelope-open-text fs-4 me-2"></i></a>
                                            <a href="#" className="btn btn-sm me-lg-2" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">
                                                <span className="svg-icon svg-icon-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                                    </svg>
                                                </span>
                                            </a>
                                            
                            
                                            {/* <!--end::Button--> */}
                                        </div>
                                    </div>
                                    {/* <!--end::Header--> */}
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											{/* <!--begin::Table container--> */}
											<div className="table-responsive">
												{/* <!--begin::Table--> */}
												<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
													{/* <!--begin::Table head--> */}
													<thead>
														<tr className="fw-bolder text-muted">
                                                            <th className="min-w-120px">No</th>
                                                            <th className="min-w-120px">Kode</th>
															<th className="min-w-120px">Nama Jasa</th>
															<th className="min-w-120px">Foto Rusak</th>
															<th className="min-w-120px">Pengajuan</th>
                                                            <th className="min-w-120px">Disetujui</th>
															<th className="min-w-120px">Disc</th>
                                                            <th className="min-w-120px">Total</th>
                                                            <th className="min-w-120px">Kelas</th>
                                                            <th className="min-w-120px">ST</th>
															<th className="min-w-120px">Ket</th>
														</tr>
													</thead>
													{/* <!--end::Table head--> */}
													{/* <!--begin::Table body--> */}
													<tbody>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">1</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">JKRM00001</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">K/C PIPI RH</td>
															<td>
                                                            <button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>														
															</td>
                                                            <td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">1.500.000</a>
															</td>
															<td>
                                                            <input type="text" className="form-control me-lg-3" placeholder="1.500.000"/>
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
                                                            <input type="text" className="form-control me-lg-3" placeholder=""/>
                                                            </td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">1.500.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">R</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">3</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">SBR</td>
														</tr>
														<tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">2</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">JKRM00002</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">G/C KAP MESIN</td>
															<td>
                                                            <button className="btn btn-sm btn-secondary">LIHAT</button>												
															</td>
                                                            <td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">2.000.000</a>
															</td>
															<td>
																
                                                                <input type="text" className="form-control me-lg-3" placeholder="2.000.000"/>
                                                                
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
                                                                <input type="text" className="form-control me-lg-3" placeholder=""/>
                                                            </td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">2.000.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">M</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">2</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
														</tr>
                                                        <tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">3</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">JKRM00003</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">B/P KAP MESIN</td>
															<td>
                                                            <button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>												
															</td>
                                                            <td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">150.000</a>
															</td>
															<td>
																
                                                                <input type="text" className="form-control me-lg-3" placeholder=""/>
                                                                
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
                                                            <input type="text" className="form-control me-lg-3" placeholder=""/>
                                                            </td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">150.000</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">B</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">1</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
														</tr>
													</tbody>
													{/* <!--end::Table body--> */}
												</table>
												{/* <!--end::Table--> */}
											</div>
											{/* <!--end::Table container--> */}
										</div>
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
                            
						</div>
						{/* <!--end::Post--> */}
                       {/* <!--begin::Post2--> */}
						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
									<div className="card mb-5 mb-xl-8">
										{/* <!--begin::Header--> */}
										<div className="card-header border-0 pt-5">
											<div id="kt_docs_search_handler_basic">
											{/* <!--begin::Input Form--> */}
											<div className="input-group">
												<input type="text" className="form-control" placeholder="Cari Harga Sparepart"/>
												<div className="input-group-append">
												  <button className="btn btn-active-secondary" type="button">
													<i className="fa fa-search"></i>
												  </button>	
												</div>
											</div>
											{/* <!--end::Form--> */}
											
											</div>
											
											<div className="d-flex align-items-center py-1">
												{/* <!--begin::Wrapper--> */}
												
												{/* <!--end::Wrapper--> */}
                                                <input type="text" className="form-control me-lg-2" placeholder="AWAL"/>
                                                <input type="text" className="form-control me-lg-2" placeholder="AKHIR"/>
                                                <input type="text" className="form-control me-lg-2" placeholder="DISCOUNT"/>
												{/* <!--begin::Button--> */}
                                                <a href="#" className="btn btn-sm me-lg-2" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">
                                                    <span className="svg-icon svg-icon-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                </a>
												
								
												{/* <!--end::Button--> */}
											</div>
										</div>
										{/* <!--end::Header--> */}
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											<div className="table-responsive">
												{/* <!--begin::Table--> */}
												<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
													{/* <!--begin::Table head-->/ */}
													<thead>
														<tr className="fw-bolder text-muted">
                                                            <th className="min-w-120px">No</th>
                                                            <th className="min-w-120px">Kode</th>
															<th className="min-w-120px">Nama Sparepart</th>
															<th className="min-w-120px">Foto Rusak</th>
															<th className="min-w-120px">Pengajuan</th>
                                                            <th className="min-w-120px">Disetujui</th>
															<th className="min-w-120px">Disc</th>
                                                            <th className="min-w-120px">Total</th>
                                                            <th className="min-w-120px">Kelas</th>
                                                            <th className="min-w-120px">ST</th>
															<th className="min-w-120px">Ket</th>
														</tr>
													</thead>
													{/* <!--end::Table head--> */}
													{/* <!--begin::Table body--> */}
													<tbody>
                                                    <tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">1</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
                                                            <button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>														
															</td>
                                                            <td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">-</a>
															</td>
															<td>
                                                            <input type="text" className="form-control me-lg-3" placeholder=""/>
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
                                                            <input type="text" className="form-control me-lg-3" placeholder=""/>
                                                            </td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
													</tr>
                                                    <tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">2</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
                                                            <button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>														
															</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
                                                            <input type="text" className="form-control me-lg-3" placeholder=""/>
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
                                                            <input type="text" className="form-control me-lg-3" placeholder=""/>
                                                            </td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
													</tr>
                                                    <tr>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">3</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td>
                                                            <button onClick={showImageUploadModal} className="btn btn-sm btn-secondary">LIHAT</button>															
															</td>
                                                            <td>
																<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">-</a>
															</td>
															<td>
                                                            <input type="text" className="form-control me-lg-3" placeholder=""/>
															</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">
                                                            <input type="text" className="form-control me-lg-3" placeholder=""/>
                                                            </td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
															<td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
                                                            <td className="text-dark fw-bolder text-hover-primary fs-6">-</td>
													</tr>
													</tbody>
													{/* <!--end::Table body--> */}
												</table>
												{/* <!--end::Table--> */}
											</div>
											
										</div>
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
						</div>
						
						{/* <!--end::Post--> */}
                      
					
						</PageAnimate>
    )
}
