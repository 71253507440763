import React from 'react'
import { Link } from 'react-router-dom'
import DefaultKlaimButtons from '../../components/buttons/DefaultKlaimButtons'
import Umum from '../../components/klaim/Umum'
import { useLayout } from '../../context/core';

export default function RegisterProfil() {
	const {config, handleHeightChange} = useLayout();

    return (
		<>
        
						{/* <!--begin::Toolbar--> */}
						<Umum onHeightChange={handleHeightChange} topButtons={<DefaultKlaimButtons />}>

							{/* <!--begin::Container--> */}
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-wrap flex-stack flex-fill flex-grow">
								{/* <!--begin::Page title--> */}
								<div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
									{/* <!--begin::Title--> */}
									{/* <!--begin::Trigger--> */}
									<button type="button" className="btn btn-light"
										data-kt-menu-trigger="click"
										data-kt-menu-placement="bottom-start">
										Register Profil
										<span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
									</button>
									{/* <!--end::Trigger--> */}

									{/* <!--begin::Menu--> */}
									<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
										data-kt-menu="true">
										{/* <!--begin::Menu item--> */}
										<div className="menu-item px-3">
											<a href="#" className="menu-link px-3">
												Register Profil
											</a>
										</div>
										{/* <!--end::Menu item--> */}

										{/* <!--begin::Menu item--> */}
										<div className="menu-item px-3">
											<a href="#" className="menu-link px-3">
												Register Gambar
											</a>
										</div>
										{/* <!--end::Menu item--> */}
									</div>
									{/* <!--end::Menu--> */}

									{/* <!--end::Title--> */}
									
								</div>
								{/* <!--end::Page title--> */}
								{/* <!--begin::Action group--> */}
								<div className="d-flex align-items-center overflow-auto ">
									{/* <!--begin::Wrapper--> */}
									<div className="d-flex align-items-center flex-shrink-0 ">
										
										<div className="flex-shrink-0">
											<a href="#" className="btn btn-sm btn-secondary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">DATABASE </a>
                                            <a href="#" className="btn btn-sm btn-danger me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">DELETE </a>
											<a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">EDIT </a>
                                            <Link to="/klaim/new/sketsa" className="btn btn-sm btn-success me-lg-1">OK </Link>
											
										</div>
									</div>
									{/* <!--end::Wrapper--> */}
									
								</div>
								{/* <!--end::Action group--> */}
							</div>
							
							{/* <!--end::Container--> */}
						</Umum>
						{/* <!--end::Toolbar--> */}
						{/* <!--begin::Post--> */}
						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
								<div className="card mb-5 mb-xl-8">
									{/* <!--begin::Header--> */}
									<div className="card-header border-0 pt-5">
										<h3 className="card-title align-items-start flex-column">
											DATA PROFIL
											{/* <!-- <span className="text-muted mt-1 fw-bold fs-7">Database</span> --> */}
										</h3>
										
									{/* <!--end::Main wrapper--> */}
									</div>
									
									{/* <!--end::Header--> */}
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											{/* <!--begin::Form--> */}
											<form action="" className="form mb-15" method="post" id="kt_careers_form">
												{/* <!--begin::Input group--> */}
												<div className="d-flex flex-column mb-5 fv-row">
													{/* <!--begin::Label--> */}
													<label className="fs-5 fw-bold mb-2 required">NAMA PEMILIK</label>
													{/* <!--end::Label--> */}
													{/* <!--begin::Input--> */}
													<input className="form-control form-control-solid" placeholder="" name="website" />
													{/* <!--end::Input--> */}
												</div>
												<div className="d-flex flex-column mb-5 fv-row">
													{/* <!--begin::Label--> */}
													<label className="fs-5 fw-bold mb-2 required">NAMA MOBIL</label>
													{/* <!--end::Label--> */}
													{/* <!--begin::Input--> */}
													<input className="form-control form-control-solid" placeholder="" name="website" />
													{/* <!--end::Input--> */}
												</div>
												<div className="d-flex flex-column mb-5 fv-row">
													{/* <!--begin::Label--> */}
													<label className="fs-5 fw-bold mb-2 required">NOMOR POLISI</label>
													{/* <!--end::Label--> */}
													{/* <!--begin::Input--> */}
													<input className="form-control form-control-solid" placeholder="" name="website" />
													{/* <!--end::Input--> */}
												</div>
												<div className="d-flex flex-column mb-5 fv-row">
													{/* <!--begin::Label--> */}
													<label className="fs-5 fw-bold mb-2 required">NO HP</label>
													{/* <!--end::Label--> */}
													{/* <!--begin::Input--> */}
													<input className="form-control form-control-solid" placeholder="" name="website" />
													{/* <!--end::Input--> */}
												</div>
												{/* <!--end::Input group--> */}
												{/* <!--begin::Separator--> */}
												<div className="separator mb-8"></div>
												{/* <!--end::Separator--> */}
												{/* <!--begin::Submit--> */}
											   
												{/* <!--end::Submit--> */}
											</form>
											{/* <!--end::Form--> */}
										</div>
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
						</div>
						{/* <!--end::Post--> */}
					
						</>
    )
}
