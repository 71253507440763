import React, { useEffect  } from 'react'
import { PageAnimate } from '../components/PageAnimate';
import {useLayout, ILayout, PageTitle} from '../context/core'
import NotesCard from '../components/notes/NotesCard';
import { MODAL_TYPES, useGlobalModalContext } from '../context/GlobalModals';


export default function Dashboard() {

    const {config, setLayout} = useLayout();

    let newLayoutConfig:ILayout = {
        ...config,
    };
    newLayoutConfig.toolbar.layout = 'noToolbar';

    useEffect(() => {
        setLayout(newLayoutConfig);
    }, []);

    const {showModal} = useGlobalModalContext();

    function showTDLModal(){
        showModal(MODAL_TYPES.TODOLIST_DETAIL);
    }
    

    return (
        <PageAnimate>
                  <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
        {/* // <!--begin::Post--> */}
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        {/* <!--begin::Container--> */}
                        <div id="kt_content_container" className="container-fluid">
                            {/* <!--begin::Row--> */}
                            <div className="row gy-5 g-xl-8">
                                {/* <!--begin::Col--> */}
                                <div className="col-md-6">
                                    {/* <!--begin::List Widget 5--> */}
                                    <div className="card">
                                        {/* <!--begin::Header--> */}
                                        <div className="card-header align-items-center border-0 mt-4">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="fw-bolder mb-2 text-dark">To-Do List</span>
                                            </h3>
                                        </div>
                                        {/* <!--end::Header--> */}
                                        {/* <!--begin::Body--> */}
                                        <div className="card-body pt-5">
                                            {/* <!--begin::Accordion--> */}
                                            <div className="accordion" id="kt_accordion_1">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="kt_accordion_1_header_1">
                                                        <button className="accordion-button fs-4 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_1" aria-expanded="true" aria-controls="kt_accordion_1_body_1">
															SA
														</button>
                                                    </h2>
                                                    <div id="kt_accordion_1_body_1" className="accordion-collapse collapse show" aria-labelledby="kt_accordion_1_header_1">
                                                        <div className="accordion-body">
                                                            <table className="table table-row-dashed">
                                                                <thead>
                                                                <tr>
                                                                    <th>To-Do</th>
                                                                    <th>
                                                                        Belum Dikerjakan
                                                                    </th>
                                                                    <th>
                                                                        Selesai<br/>Hari Ini
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td width="75%">
                                                                        Belum Beli
                                                                    </td>
                                                                    <td width="15%" className='c-pointer text-hover-primary'    onClick={showTDLModal}>
                                                                        45
                                                                    </td>
                                                                    <td width="20%" className='c-pointer text-hover-primary'   onClick={showTDLModal}> 
                                                                        45
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td >
                                                                        Belum Pengajuan
                                                                    </td>
                                                                    <td className='c-pointer text-hover-primary'  onClick={showTDLModal}>
                                                                        35
                                                                    </td>
                                                                    <td className='c-pointer text-hover-primary'  onClick={showTDLModal}>
                                                                        35
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="kt_accordion_1_header_2">
                                                        <button className="accordion-button fs-4 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_2" aria-expanded="false" aria-controls="kt_accordion_1_body_2">
														SPAREPART
														</button>
                                                    </h2>
                                                    <div id="kt_accordion_1_body_2" className="accordion-collapse collapse" aria-labelledby="kt_accordion_1_header_2">
                                                        <div className="accordion-body">
                                                            <table className="table table-row-dashed">
                                                            <thead>
                                                                <tr>
                                                                    <th>To-Do</th>
                                                                    <th>
                                                                        Belum Dikerjakan
                                                                    </th>
                                                                    <th>
                                                                        Selesai<br />Hari Ini
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td width="75%">
                                                                        Belum Beli
                                                                    </td>
                                                                    <td width="15%"className='c-pointer text-hover-primary' onClick={showTDLModal}>
                                                                        45
                                                                    </td>
                                                                    <td width="20%" className='c-pointer text-hover-primary' onClick={showTDLModal}>
                                                                        45
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td >
                                                                        Belum Pengajuan
                                                                    </td>
                                                                    <td className='c-pointer text-hover-primary' onClick={showTDLModal}>
                                                                        35
                                                                    </td>
                                                                    <td className='c-pointer text-hover-primary' onClick={showTDLModal}>
                                                                        35
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="kt_accordion_1_header_3">
                                                        <button className="accordion-button fs-4 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_3" aria-expanded="false" aria-controls="kt_accordion_1_body_3">
														PERTUKANGAN
														</button>
                                                    </h2>
                                                    <div id="kt_accordion_1_body_3" className="accordion-collapse collapse" aria-labelledby="kt_accordion_1_header_3">
                                                        <div className="accordion-body">
                                                            <table className="table table-row-dashed">
                                                            <thead>
                                                                <tr>
                                                                    <th>To-Do</th>
                                                                    <th>
                                                                        Belum Dikerjakan
                                                                    </th>
                                                                    <th>
                                                                        Selesai<br />Hari Ini
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td width="75%">
                                                                        Belum Beli
                                                                    </td>
                                                                    <td width="15%" className='c-pointer text-hover-primary' onClick={showTDLModal}>
                                                                        45
                                                                    </td>
                                                                    <td width="20%" className='c-pointer text-hover-primary' onClick={showTDLModal}>
                                                                        45
                                                                    </td> 
                                                                </tr>
                                                                <tr>
                                                                    <td >
                                                                        Belum Pengajuan
                                                                    </td>
                                                                    <td className='c-pointer text-hover-primary' onClick={showTDLModal}>
                                                                        35
                                                                    </td>
                                                                    <td className='c-pointer text-hover-primary' onClick={showTDLModal}>
                                                                        35
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!--end::Accordion--> */}
                                        </div>
                                        {/* <!--end: Card Body--> */}
                                    </div>
                                    {/* <!--end: List Widget 5--> */}
                                </div>
                                {/* <!--end::Col--> */}
                                {/* <!--begin::Col--> */}
                                <div className="col-md-6">
                                    {/* <!--begin::List Widget 5--> */}
                                    <NotesCard />
                                    {/* <!--end: List Widget 5--> */}
                                </div>
                                {/* <!--end::Col--> */}
                            </div>
                            {/* <!--end::Row--> */}
                        </div>
                        {/* <!--end::Container--> */}
                    </div>
                    {/* <!--end::Post--> */}
                    {/* <NotesBaru /> */}
                    </PageAnimate>
    )
}
