import { AxiosResponse } from "axios";
import { Jasa } from "./types"
import { AddJasa } from "./types"
import http from '../http'
import {auth} from '../../adapters/Firebase'
import {APIPaths} from '../paths';

interface JasaAPI {
    getJasa: () => Promise<AxiosResponse<Jasa[]>>,
    createJasa: (newjasa:AddJasa) => Promise<AxiosResponse>,
    deleteJasa: (uuid: string) => Promise<AxiosResponse>
}

export const jasaAPI: JasaAPI = {
    getJasa: async () => {
        let idToken = await auth.currentUser!.getIdToken();
        console.log(idToken);
        let response = http.get(APIPaths.getListJasa, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    
    createJasa: async (newnote) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.post(APIPaths.addJasa, newnote, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    
    deleteJasa: async (uuid) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = await http.post(APIPaths.deleteJasa, {uuid: uuid}, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    
}