import { AxiosResponse } from "axios";
import { Asuransi } from "./types"
import http from '../http'
import {auth} from '../../adapters/Firebase'
import {APIPaths} from '../paths';

interface AsuransiAPI {
    getAsuransi: () => Promise<AxiosResponse<Asuransi[]>>,
    createAsuransi: (newasuransi:Asuransi) => Promise<AxiosResponse>,
    updateAsuransi: (newasuransi:Asuransi) => Promise<AxiosResponse>,
    deleteAsuransi: (uuid: string) => Promise<AxiosResponse>
}

export const asuransiAPI: AsuransiAPI = {
    getAsuransi: async () => {
        let idToken = await auth.currentUser!.getIdToken();
        console.log(idToken);
        let response = http.get(APIPaths.getAsuransiList, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    
    createAsuransi: async (newasuransi) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.post(APIPaths.addAsuransi, newasuransi, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    updateAsuransi: async (newasuransi) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = http.post(APIPaths.updateAsuransi, newasuransi, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    
    deleteAsuransi: async (uuid) => {
        let idToken = await auth.currentUser!.getIdToken();
        let response = await http.post(APIPaths.deleteAsuransi, {uuid: uuid}, {headers: {'Authorization': 'Bearer ' + idToken}})

        return response
    },
    
}