import React, { useEffect, useState } from 'react'
import {Modal, Button, Spinner }from 'react-bootstrap';
import ModalWrapper from './ModalWrapper';
import Select, { SingleValue } from 'react-select'
import { Notes } from '../../api/notes/types';
import { notesAPI } from '../../api/notes';
import { DateTime, Settings } from 'luxon';
import { getAuth } from '@firebase/auth';
import { useGlobalModalContext } from '../../context/GlobalModals';
import {delay} from '../../tools/delay'

interface showData {
    showGlobal: boolean
    afterSubmit: Function
} 

export default function NotesBaru({showGlobal=false, afterSubmit}:showData) {
    const [show, setShow] = useState(showGlobal);

    const { hideModal } = useGlobalModalContext()

    const handleClose = async () => {
        setShow(false);
        handleReset();
        clearNotes();
        await delay(500);
        hideModal();
    }

    interface selectValue {
        value: string
        label: string
    }

    const handleSelectChange = (data:SingleValue<string>) => {
        // console.log(data);
        if(data) {

        }
        handleInputs('toID', ((data as unknown) as selectValue).value);
        setSelectedOption(data);
    }

    const showModal = () => {
        setShow(true);
    }

    Settings.defaultLocale = "id";
    const dt = DateTime.now()
    dt.setLocale('id')

    const [user, setUser] = useState<Array<any>>([])
    const [noteMsg, setNoteMsg] = useState<Notes>({})
    const [selectedOption, setSelectedOption] = useState<SingleValue<string>|null>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    function clearNotes() {
        setNoteMsg({});
        setSelectedOption(null);
    }

    async function getUser(){
        const res = await notesAPI.userNotes();
        const arrayUsers = res.data.map((data) => {
            return {
                value: data.uid,
                label: data.name ?? data.email ?? data.uid
            };
        })
        setUser(arrayUsers);
    }

    async function submitNewNotes(note:Notes){
        try {
            console.log()
            setIsSubmitting(true);
            await notesAPI.createNote(note);
            setIsSubmitting(false);
            handleReset();
            clearNotes();
            afterSubmit();
            handleClose();
        } catch (e) {
            setIsSubmitting(false);
        }
        
    }

    function handleInputs(key:string, input:string) {
        var currInput:Notes = {
            ...noteMsg,
            [key]:input
        }
        setNoteMsg(currInput);
        console.log(noteMsg);
    }

    useEffect(() => {
        getUser();
    }, [])

    function handleReset() {
        Array.from(document.querySelectorAll("input")).forEach(
          input => (input.value = "")
        );
      };

    return (
        <>
        <ModalWrapper>
            <Modal title={'Kirim Notes Baru'}
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered
            size="lg"
            keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Kirim notes baru</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="mb-3 row">
                        <label  className="col-sm-2 col-form-label">Tanggal</label>
                        <div className="col-sm-10">
                            <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value={dt.toFormat("DDD")} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label">Dari</label>
                        <div className="col-sm-10">
                            <input type="text" readOnly className="form-control-plaintext" id="inputPassword" value={getAuth().currentUser!.displayName??getAuth().currentUser!.email??getAuth().currentUser!.uid} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label">Kepada</label>
                        <div className="col-sm-10">
                            <Select value={selectedOption} options={user} onChange={selectedOption=> handleSelectChange(selectedOption)}/>
                            
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label">Pesan</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" onChange={e=> handleInputs('msg', e.currentTarget.value)} value={noteMsg.msg}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={handleClose}>Batal</button>
                    <button type="button" className="btn btn-primary" disabled={isSubmitting || noteMsg.toID == null || noteMsg.msg== null} onClick={e=> submitNewNotes(noteMsg)}>{isSubmitting? <Spinner animation="border" style={{
                // marginLeft:"50vw",
                // display:"flex",
                // flex: 1,
                // marginTop:"50vh",
                justifyContent: 'center',
                alignItems:'center'}} /> : 'Kirim'}</button>
                </Modal.Footer>
            </Modal>
        </ModalWrapper>
        </>
    )
}
