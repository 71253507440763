import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DefaultKlaimButtons from '../../components/buttons/DefaultKlaimButtons'
import Umum from '../../components/klaim/Umum'
import { PageAnimate } from '../../components/PageAnimate'
import { useLayout } from '../../context/core'
import ImageUploading from 'react-images-uploading';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Modal,Image } from 'react-bootstrap'
import MultiImgUpload from '../../components/register/MultiImgUpload';
import { detailKlaimRoutes } from '../../routes/page-route-list'


export default function RegisterGambar() {
	const {config, handleHeightChange} = useLayout();

	


	

	const onChange = (target:string, imageList:any) => {
        // data for submit
		const newImg = {
			...images,
			[target]: imageList
		}
        setImages(newImg);
      };

	const [images, setImages] = useState<any>({});


	

    const maxNumber = 10;
	
	const imageData = [
		"KTP",
		"SIM",
		"POLIS",
		"STNK",
		"FORM_KLAIM",
		"SURAT_KETERANGAN_KEPOLISIAN",
		"SURAT_KETERANGAN",
		"SURAT_DAMAI"
	]

    return (
		<PageAnimate>
        
        {/* <!--begin::Toolbar--> */}
		<Umum onHeightChange={handleHeightChange} topButtons={<DefaultKlaimButtons />}>

							{/* <!--begin::Container--> */}
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-wrap flex-stack">
								{/* <!--begin::Page title--> */}
								<div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
									{/* <!--begin::Title--> */}
									{/* <!--begin::Trigger--> */}
									<button type="button" className="btn btn-light"
										data-kt-menu-trigger="click"
										data-kt-menu-placement="bottom-start">
										Register Gambar
										<span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
									</button>
									{/* <!--end::Trigger--> */}

									{/* <!--begin::Menu--> */}
									<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
										data-kt-menu="true">
										{detailKlaimRoutes.map((routeLink) => {
											return <>
											{/* <!--begin::Menu item--> */}
											<div className="menu-item px-3">
												<Link to={routeLink.path} className="menu-link px-3">
													{routeLink.label}
												</Link>
											</div>
											{/* <!--end::Menu item--> */}
											</>
										})}
									</div>
									{/* <!--end::Menu--> */}

									{/* <!--end::Title--> */}
									
								</div>
								{/* <!--end::Page title--> */}
								{/* <!--begin::Action group--> */}
								<div className="d-flex align-items-center overflow-auto">
									{/* <!--begin::Wrapper--> */}
									<div className="d-flex align-items-center flex-shrink-0">
										
										<div className="flex-shrink-0">
											{/* <a href="#" className="btn btn-sm btn-danger me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Tambah List </a> */}
                                            <a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">Download </a>
                                            <Link to="/register/data" className="btn btn-sm btn-success me-lg-1" id="kt_toolbar_primary_button">OK </Link>
											
										</div>
									</div>
									{/* <!--end::Wrapper--> */}
									
								</div>
								{/* <!--end::Action group--> */}
							</div>
							
							{/* <!--end::Container--> */}
						</Umum>
						{/* <!--end::Toolbar--> */}
						{/* <!--begin::Post--> */}
						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
								<div className="card mb-5 mb-xl-8">
									{/* <!--begin::Header--> */}
									<div className="card-header border-0 pt-5">
										<h1 className="card-title align-items-start flex-column">
											FOTO DOKUMEN
											{/* <!-- <span className="text-muted mt-1 fw-bold fs-7">Database</span> --> */}
										</h1>
										<div className="card-toolbar">
											
											{/* <!--end::Actions--> */}
											
										</div>
										
									{/* <!--end::Main wrapper--> */}
									</div>
									
									{/* <!--end::Header--> */}
										{/* <!--begin::Body--> */}
										
										<div className="card-body py-3 ">
											{imageData.map((img) => {
												return <MultiImgUpload target={img} images={images[img]} onChangeCallback={onChange} maxNumber={10}/>
											})}
											
										</div>

									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
						</div>
						{/* <!--end::Post--> */}
						
						</PageAnimate>
    )
}
