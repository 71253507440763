import React, { useEffect, useState } from 'react'
import {Modal, Button, Spinner }from 'react-bootstrap';
import ModalWrapper from './ModalWrapper';
import Select, { SingleValue } from 'react-select'
import { Notes } from '../../api/notes/types';
import { notesAPI } from '../../api/notes';
import { DateTime, Settings } from 'luxon';
import { getAuth } from '@firebase/auth';
import { useGlobalModalContext } from '../../context/GlobalModals';
import {delay} from '../../tools/delay'
import Form from 'react-bootstrap/Form'

interface showData {
    showGlobal: boolean
} 

export default function PrintWO({showGlobal=false}:showData) {
    const [show, setShow] = useState(showGlobal);

    const { hideModal } = useGlobalModalContext()

    const handleClose = async () => {
        setShow(false);
        clearData();
        await delay(500);
        hideModal();
    }

    type printOpts = {
        option: string,
        label: string
    }
    const printOptions:printOpts[] = [
        {
            option: "CHECKLIST",
            label: "Checklist",
        },
        {
            option: "ESTIMASI",
            label: "Estimasi",
        },
        {
            option: "PENYERAHAN_MOBIL",
            label: "Penyerahan Mobil",
        },
        {
            option: "SEMUA_FOTO",
            label: "Semua Foto",
        },
        {
            option: "FOTO_RUSAK",
            label: "Foto Rusak",
        },
        {
            option: "FOTO_BB",
            label: "Foto BB",
        },
        {
            option: "FOTO_EPOXY",
            label: "Foto Epoxy",
        },
        {
            option: "FOTO_JADI",
            label: "Foto Jadi",
        },
        {
            option: "KWITANSI_OR",
            label: "Kwitansi OR",
        },
        {
            option: "KWITANSI_PERBAIKAN",
            label: "Kwitansi Perbaikan",
        },
        {
            option: "SURAT_SALVAGE",
            label: "Surat Salvage",
        },
        {
            option: "KARTU_WO_SPAREPART",
            label: "Kartu WO Divisi Sparepart",
        },
        {
            option: "KARTU_WO_BAHANCAT",
            label: "Kartu WO Divisi Bahan & Cat",
        },
        {
            option: "KARTU_WO_TUKANG",
            label: "Kartu WO Divisi Tukang",
        },
    ]

    const [selectedOptions, setSelectedOptions] = useState<printOpts[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const showModal = () => {
        setShow(true);
    }

    Settings.defaultLocale = "id";
    const dt = DateTime.now()
    dt.setLocale('id')


    function clearData() {
        setSelectedOptions([]);
    }


    async function submitPrint(data: printOpts[]){
        try {
            setIsSubmitting(true);
            await delay(1000);
            setIsSubmitting(false);
            clearData();
            handleClose();
        } catch (e) {
            setIsSubmitting(false);
        }
        
    }

    function handleCheck(data:printOpts) {
        let newOpts:printOpts[] = [...selectedOptions];
        let index = newOpts.findIndex(x => x.option === data.option)
        if(index > -1) {
            newOpts.splice(index,1);
        } else {
            newOpts.push(data);
        }
        setSelectedOptions(newOpts);
    }

    function getStatus(data:printOpts):boolean {
        let index = selectedOptions.findIndex(x => x.option === data.option);
        return (index > -1);
    }

    return (
        <>
        <ModalWrapper>
            <Modal title={'Print'}
            show={show}
            onHide={handleClose}
            backdrop="static"
            centered
            size="sm"
            keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Print</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { printOptions.map((data) => {
                        return <Form.Check 
                        className="mb-2"
                        type='checkbox'
                        id={data.option}
                        label={data.label}
                        checked={getStatus(data)}
                        onClick={()=>{handleCheck(data)}}
                      />
                    }) }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={handleClose}>Batal</button>
                    <button type="button" className="btn btn-primary" disabled={isSubmitting || selectedOptions.length<1 } onClick={e=> submitPrint(selectedOptions)}>{isSubmitting? <Spinner animation="border" style={{
                // marginLeft:"50vw",
                // display:"flex",
                // flex: 1,
                // marginTop:"50vh",
                justifyContent: 'center',
                alignItems:'center'}} /> : 'Print'}</button>
                </Modal.Footer>
            </Modal>
        </ModalWrapper>
        </>
    )
}
