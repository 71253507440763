import React, { MouseEventHandler } from "react";
// import '../../styles/style.bundle.css';
// import '../../styles/style.bundle';

type SketsaProps = {
  handleClick: MouseEventHandler,
  getColor: Function,
  getStatus: Function
}

const Sketsa =  ({handleClick, getColor, getStatus}:SketsaProps) =>  {
  return (
      <>
    <svg width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="50 150 980 780">
    
      <g data-name="BAN KIRI DEPAN" id="BAN_KIRI_DEPAN" onClick={handleClick} className="clickableSvg">
        <circle
          cx="249.02"
          cy="319.77"
          r="61.02"
          fill={getColor("BAN_KIRI_DEPAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></circle>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(235.4 325.48)"
        >
          BAN 
          {/* {getStatus("BAN_KIRI_DEPAN")? '('+getStatus("BAN_KIRI_DEPAN")+')':'' } */}
        </text>
      </g>
      <g data-name="VELG KIRI DEPAN" id="VELG_KIRI_DEPAN" onClick={handleClick} className="clickableSvg">
        <circle
          cx="119.02"
          cy="319.77"
          r="61.02"
          fill={getColor("VELG_KIRI_DEPAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></circle>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(102.4 325.48)"
        >
          VELG 
          {/* {getStatus("BAN_KIRI_DEPAN")? '('+getStatus("BAN_KIRI_DEPAN")+')':'' } */}
        </text>
      </g>
      <g data-name="BAN KANAN DEPAN" id="BAN_KANAN_DEPAN" onClick={handleClick} className="clickableSvg">
        <circle
          cx="831.02"
          cy="319.77"
          r="61.02"
          fill={getColor("BAN_KANAN_DEPAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          className="bg-red"
        ></circle>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(817.4 325.48)"
        >
          BAN
        </text>
      </g>
      <g data-name="VELG KANAN DEPAN" id="VELG_KANAN_DEPAN" onClick={handleClick} className="clickableSvg">
        <circle
          cx="961.02"
          cy="319.77"
          r="61.02"
          fill={getColor("VELG_KANAN_DEPAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          className="bg-red"
        ></circle>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(944.4 325.48)"
        >
          VELG
        </text>
      </g>
      <g data-name="BAN KIRI BELAKANG" id="BAN_KIRI_BELAKANG" onClick={handleClick} className="clickableSvg">
        <circle
          cx="249.5"
          cy="761.84"
          r="61.02"
          fill={getColor("BAN_KIRI_BELAKANG")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></circle>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(235.88 767.54)"
        >
          BAN
        </text>
      </g>
      <g data-name="VELG KIRI BELAKANG" id="VELG_KIRI_BELAKANG" onClick={handleClick} className="clickableSvg">
        <circle
          cx="119.5"
          cy="761.84"
          r="61.02"
          fill={getColor("VELG_KIRI_BELAKANG")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></circle>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(102.88 767.54)"
        >
          VELG
        </text>
      </g>
      <g data-name="BAN KANAN BELAKANG" id="BAN_KANAN_BELAKANG" onClick={handleClick} className="clickableSvg">
        <circle
          cx="831.5"
          cy="761.84"
          r="61.02"
          fill={getColor("BAN_KANAN_BELAKANG")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></circle>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(817.88 767.54)"
        >
          BAN
        </text>
      </g>
      <g data-name="VELG KANAN BELAKANG" id="VELG_KANAN_BELAKANG" onClick={handleClick} className="clickableSvg">
        <circle
          cx="961.5"
          cy="761.84"
          r="61.02"
          fill={getColor("VELG_KANAN_BELAKANG")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></circle>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(944.88 767.54)"
        >
          VELG
        </text>
      </g>
      <g data-name="FOGLAMP LH" id="FOGLAMP_LH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("FOGLAMP_LH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M311 185H408V222H311z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(319.26 207.53)"
        >
          FOGLAMP LH
        </text>
      </g>
      <g data-name="HEADLAMP LH" id="HEADLAMP_LH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("HEADLAMP_LH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M311 222H408V259H311z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(314.92 244.44)"
        >
          HEADLAMP LH
        </text>
      </g>
      <g data-name="FENDER LH" id="FENDER_LH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("FENDER_LH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M311 259H422V382H311z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(333 325.72)"
        >
          FENDER LH
        </text>
      </g>
      <g data-name="LAMBUNG LH" id="LAMBUNG_LH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("LAMBUNG_LH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M311.48 699.61H422.48V822.61H311.48z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(324.2 766.32)"
        >
          LAMBUNG LH
        </text>
      </g>
      <g data-name="STOPLAMP LH" id="STOPLAMP_LH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("STOPLAMP_LH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M311.48 822.61H408.48V859.61H311.48z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(316.46 845.12)"
        >
          STOPLAMP LH
        </text>
      </g>
      <g data-name="SENSOR LH" id="SENSOR_LH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("SENSOR_LH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M311.48 859.61H408.48V896.61H311.48z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(323.9 879.58)"
        >
          SENSOR LH
        </text>
      </g>
      <g data-name="LIST PINTU DEPAN LH" id="LIST_PINTU_DEPAN_LH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("LIST_PINTU_DEPAN_LH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M331.32 382.39H399.75V539.9H331.32z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="rotate(90 -23.265 383.535)"
        >
          PINTU DEPAN LH
        </text>
      </g>
      <g data-name="LIST PINTU BELAKANG LH" id="LIST_PINTU_BELAKANG_LH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("LIST_PINTU_BELAKANG_LH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M331.32 539.9H399.75V699.61H331.32z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="rotate(90 -95.915 456.185)"
        >
          PINTU BELAKANG LH
        </text>
      </g>
      <g data-name="LIST PLANK LH" id="LIST_PLANK_LH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("LIST_PLANK_LH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M311 382H331.32V699.61H311z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="rotate(90 -89.01 404.69)"
        >
          LIST PLANK LH
        </text>
      </g>
      <g data-name="LIST PINTU DEPAN KIRI" id="LIST_PINTU_DEPAN_KIRI" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("LIST_PINTU_DEPAN_KIRI")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M399.74 382H422V540H399.74z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="rotate(90 1.695 403.865)"
        >
          LIST PINTU DEPAN
        </text>
      </g>
      <g data-name="LIST PINTU BELAKANG KIRI" id="LIST_PINTU_BELAKANG_KIRI" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("LIST_PINTU_BELAKANG_KIRI")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M399.74 539.77H422V699.7H399.74z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="rotate(90 -70.555 476.115)"
        >
          LIST PINTU BELAKANG
        </text>
      </g>
      <g data-name="FENDER RH" id="FENDER_RH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("FENDER_RH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M659 259H770V382H659z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(679.92 325.72)"
        >
          FENDER RH
        </text>
      </g>
      <g data-name="LAMBUNG RH" id="LAMBUNG_RH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("LAMBUNG_RH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M659.48 699.61H770.48V822.61H659.48z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(670.62 766.32)"
        >
          LAMBUNG RH
        </text>
      </g>
      <g data-name="STOPLAMP RH" id="STOPLAMP_RH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("STOPLAMP_RH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M673.48 822.61H770.48V859.61H673.48z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(676.77 845.12)"
        >
          STOPLAMP RH
        </text>
      </g>
      <g data-name="SENSOR RH" id="SENSOR_RH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("SENSOR_RH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M673.48 859.61H770.48V896.61H673.48z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(685.22 879.58)"
        >
          SENSOR RH
        </text>
      </g>
      <g data-name="PINTU DEPAN RH" id="PINTU_DEPAN_RH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("PINTU_DEPAN_RH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M678.86 382.49H747.29V540H678.86z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="matrix(0 -1 1 0 720.75 516)"
        >
          PINTU DEPAN RH
        </text>
      </g>
      <g data-name="PINTU BELAKANG RH" id="PINTU_BELAKANG_RH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("PINTU_BELAKANG_RH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M678.86 540H747.29V699.71H678.86z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="rotate(-90 704.31 -16.44)"
        >
          PINTU BELAKANG RH
        </text>
      </g>
      <g data-name="LIST PLANK RH" id="LIST_PLANK_RH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("LIST_PLANK_RH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M747.29 382.1H769.55V699.71H747.29z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="rotate(-90 676.75 -88.23)"
        >
          LIST PLANK RH
        </text>
      </g>
      <g data-name="LIST PINTU DEPAN KANAN" id="LIST_PINTU_DEPAN_KANAN" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("LIST_PINTU_DEPAN_KANAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M658.54 382.1H678.86V539.77H658.54z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="rotate(-90 597.51 -77.75)"
        >
          LIST PINTU DEPAN
        </text>
      </g>
      <g data-name="LIST PINTU BELAKANG KANAN" id="LIST_PINTU_BELAKANG_KANAN" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("LIST_PINTU_BELAKANG_KANAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M658.54 539.77H678.86V699.6H658.54z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="rotate(-90 683.03 8.77)"
        >
          LIST PINTU BELAKANG
        </text>
      </g>
      <g data-name="FOGLAMP RH" id="FOGLAMP_RH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("FOGLAMP_RH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M673 185H770V222H673z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(680.07 207.53)"
        >
          FOGLAMP RH
        </text>
      </g>
      <g data-name="HEADLAMP RH" id="HEADLAMP_RH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("HEADLAMP_RH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M673 222H770V259H673z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(675.73 244.44)"
        >
          HEADLAMP RH
        </text>
      </g>
      <g data-name="BUMPER DEPAN" id="BUMPER_DEPAN" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("BUMPER_DEPAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M408 222H673V259H408z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(484.06 247.18)"
        >
          BUMPER DEPAN
        </text>
      </g>
      <g data-name="GRILL DEPAN" id="GRILL_DEPAN" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("GRILL_DEPAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M422 259H659V285.62H422z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(494.16 277.54)"
        >
          GRILL DEPAN
        </text>
      </g>
      <g data-name="KAP MESIN" id="KAP_MESIN" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KAP_MESIN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M422 285.62H659V382H422z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          letterSpacing=".01em"
          transform="translate(501.47 340.28)"
        >
          KAP MESIN
        </text>
      </g>
      <g data-name="KACA DEPAN" id="KACA_DEPAN" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KACA_DEPAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M658.54 382.1L422 382 491.15 461.24 590.72 461.12 658.54 382.1z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(520.58 425.48)"
        >KACA
        </text>
      </g>
      <g data-name="KACA UPPER KIRI" id="KACA_UPPER_KIRI" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KACA_UPPER_KIRI")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M422 461.24H491.15V539.9H422z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(437.38 506.23)"
        >KACA
        </text>
      </g>
      <g data-name="KACA LOWER KIRI" id="KACA_LOWER_KIRI" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KACA_LOWER_KIRI")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M422 539.91H491.15V619.86H422z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(437.38 584.74)"
        >KACA
        </text>
      </g>
      <g data-name="KACA UPPER KANAN" id="KACA_UPPER_KANAN" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KACA_UPPER_KANAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M590.72 461.12H658.54V539.78H590.72z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(604.71 506.23)"
        >KACA
        </text>
      </g>
      <g data-name="KACA LOWER KANAN" id="KACA_LOWER_KANAN" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KACA_LOWER_KANAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M590.72 539.78H658.54V619.73H590.72z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(604.71 584.74)"
        >KACA
        </text>
      </g>
      <g data-name="KACA BELAKANG" id="KACA_BELAKANG" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KACA_BELAKANG")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M422 699.61L658.54 699.71 590.72 619.73 491.15 619.85 422 699.61z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(520.58 665.48)"
        >KACA
        </text>
      </g>
      <g data-name="ROOF" id="ROOF" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("ROOF")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M491.15 461.24H590.72V619.75H491.15z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          letterSpacing=".003em"
          transform="translate(520.06 545.99)"
        >
          ROOF
        </text>
      </g>
      <g data-name="SPIOLER" id="SPIOLER" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("SPIOLER")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M422.48 699.61H659.48V724.17H422.48z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(511.15 717.56)"
        >
          SPIOLER
        </text>
      </g>
      <g data-name="BAGASI" id="BAGASI" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("BAGASI")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M422.48 724.17H659.48V795.99H422.48z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(513.92 764.84)"
        >
          BAGASI
        </text>
      </g>
      <g data-name="KACA KIRI ATAS" id="KACA_KIRI_ATAS" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KACA_KIRI_ATAS")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M422 382L422 461.24 491.15 461.24 422 382z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(426.52 443.59)"
        >KACA
        </text>
      </g>
      <g data-name="KACA KIRI BAWAH" id="KACA_KIRI_BAWAH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KACA_KIRI_BAWAH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M422 699.61L491.15 619.85 422 619.85 422 699.61z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(426.52 642.74)"
        >KACA
        </text>
      </g>
      <g data-name="KACA KANAN ATAS" id="KACA_KANAN_ATAS" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KACA_KANAN_ATAS")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M658.54 461.12L658.54 382.1 590.72 461.12 658.54 461.12z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(616.73 443.59)"
        >KACA
        </text>
      </g>
      <g data-name="KACA KANAN BAWAH" id="KACA_KANAN_BAWAH" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("KACA_KANAN_BAWAH")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M658.54 699.71L658.54 619.73 590.72 619.73 658.54 699.71z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(616.73 642.74)"
        >KACA
        </text>
      </g>
      <g data-name="EXT. BUMPER DEPAN" id="EXT_BUMPER_DEPAN" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("EXT_BUMPER_DEPAN")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M408 196H673V222H408z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(467.69 215.31)"
        >
          EXT. BUMPER DEPAN
        </text>
      </g>
      <g data-name="EXT. BUMPER BELAKANG" id="EXT_BUMPER_BELAKANG" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("EXT_BUMPER_BELAKANG")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M408.48 859.61H673.48V885.61H408.48z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(453.17 877.54)"
        >
          EXT. BUMPER BELAKANG
        </text>
      </g>
      <g data-name="BUMPER BELAKANG" id="BUMPER_BELAKANG" onClick={handleClick} className="clickableSvg">
        <path
          fill={getColor("BUMPER_BELAKANG")}
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M408.48 822.61H673.48V859.61H408.48z"
        ></path>
        <text
          fontFamily="Poppins"
          fontSize="13.7"
          transform="translate(469.54 845.67)"
        >
          BUMPER BELAKANG
        </text>
      </g>
        <g data-name="MOLDING BAGASI" id="MOLDING_BAGASI" onClick={handleClick} className="clickableSvg">
         
          <path
            fill={getColor("MOLDING_BAGASI")}
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M422.48 795.99H659.48V822.61H422.48z"
            data-name="MOLDING BAGASI"
          ></path>
           <text
                fontFamily="Poppins"
                fontSize="13.7"
                transform="translate(475.69 816.31)"
              >
            MOLDING BAGASI 
            {/* {getStatus("MOLDING_BAGASI")? '('+getStatus("MOLDING_BAGASI")+')':'' } */}
          </text>
        </g>
    </svg>
    
    </>
  );
}

export default Sketsa;