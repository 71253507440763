import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function KlaimBaru() {
    const navigate = useNavigate();

    function nav(event:any) {
        navigate(event.target.dataset.link);
    }
        return (
        // <!--start: klaim baru -->
		<div className="modal fade" id="kt_modal_2">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Input Klaim</h5>
	
						{/* <!--begin::Close--> */}
						<div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
							<span className="svg-icon svg-icon-2x"></span>
						</div>
						{/* <!--end::Close--> */}
					</div>
	
					<div className="modal-body">
						<div className="d-flex flex-column">
							<Link to="/register/gambar" onClick={nav} data-link="/register/gambar" data-bs-toggle="modal" data-bs-target="#kt_modal_2" className="btn btn-primary btn-block input-block-level my-2">Klaim Baru</Link>
							<Link to="/register/profil" onClick={nav} data-link="/register/profil" data-bs-toggle="modal" data-bs-target="#kt_modal_2" className="btn btn-success btn-block input-block-level my-2">Calon Konsumen</Link>
							<a className="btn btn-warning btn-block input-block-level my-2" data-bs-toggle="modal" data-bs-target="#kt_modal_2">Komplain</a>
						</div>
	
					</div>
				</div>
			</div>
		</div>
		// <!--end: klaim baru -->
    )
}
