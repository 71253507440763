import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PageAnimate } from '../../components/PageAnimate'
import ChecklistLain from '../../components/modals/ChecklistLain'
import Umum from '../../components/klaim/Umum';
import { Table } from 'react-bootstrap';
import ChecklistItem from '../../components/klaim/ChecklistItem';
import { PageLink, PageTitle, useLayout } from '../../context/core';
import NotesButton from '../../components/klaim/NotesButton';
import DefaultKlaimButtons from '../../components/buttons/DefaultKlaimButtons';
import { detailKlaimRoutes } from '../../routes/page-route-list';

export default function Checklist() {
	const BreadCrumbs: Array<PageLink> = [
		{
		  title: 'Santoso',
		  path: '/',
		  isSeparator: false,
		  isActive: false,
		},
		{
		  title: '',
		  path: '',
		  isSeparator: true,
		  isActive: false,
		},
		{
			title: 'Klaim Baru',
			path: '',
			isSeparator: false,
			isActive: false,
		  },
		  {
			title: '',
			path: '',
			isSeparator: true,
			isActive: false,
		  },
	];

	const initialJSON = [
		{
			id: "STNK",
			name: "STNK",
			qty: 0
		},
		{
			id: "KUNCI_KONTAK",
			name: "KUNCI KONTAK",
			qty: 0
		},
		{
			id: "EMBLEM_DEPAN",
			name: "EMBLEM DEPAN",
			qty: 0
		},
		{
			id: "EMBLEM_KIRI",
			name: "EMBLEM KIRI",
			qty: 0
		},
		{
			id: "DOP_RODA",
			name: "DOP RODA",
			qty: 0
		},
		{
			id: "SPION_LUAR_KIRI",
			name: "SPION LUAR KIRI",
			qty: 0
		},
		{
			id: "EMBLEM_BELAKANG",
			name: "EMBLEM BELAKANG",
			qty: 0
		},
		{
			id: "HARBOT_BAGASI",
			name: "HARBOT BAGASI",
			qty: 0
		},
		{
			id: "KARPET_BAGASI",
			name: "KARPET BAGASI",
			qty: 0
		},
		{
			id: "BAN_SEREP",
			name: "BAN SEREP",
			qty: 0
		},
		{
			id: "KUNCI-KUNCI",
			name: "KUNCI-KUNCI",
			qty: 0
		},
		{
			id: "DONGKRAK",
			name: "DONGKRAK",
			qty: 0
		},
		{
			id: "KUNCI_RODA",
			name: "KUNCI RODA",
			qty: 0
		},
		{
			id: "SEGITIGA_PENGAMAN",
			name: "SEGITIGA PENGAMAN",
			qty: 0
		},
		{
			id: "SPION_LUAR_KANAN",
			name: "SPION LUAR KANAN",
			qty: 0
		},
		{
			id: "EMBLEM_KANAN",
			name: "EMBLEM KANAN",
			qty: 0
		},
		{
			id: "WIPER_KACA",
			name: "WIPER KACA",
			qty: 0
		},
		{
			id: "WIPER_LAMPU",
			name: "WIPER LAMPU",
			qty: 0
		},
		{
			id: "ANTENA_RADIO",
			name: "ANTENA RADIO",
			qty: 0
		},
		{
			id: "SPOILER",
			name: "SPOILER",
			qty: 0
		},
		{
			id: "KARPET_RODA",
			name: "KARPET RODA",
			qty: 0
		},
		{
			id: "SUN_VISOR",
			name: "SUN VISOR",
			qty: 0
		},
		{
			id: "VARIASI_GANTUNGAN",
			name: "VARIASI GANTUNGAN",
			qty: 0
		},
		{
			id: "SPION_DALAM",
			name: "SPION DALAM",
			qty: 0
		},
		{
			id: "RADIO_TAPE",
			name: "RADIO+TAPE",
			qty: 0
		},
		{
			id: "CHARGER_SOCKET",
			name: "CHARGER SOCKET",
			qty: 0
		},
		{
			id: "HEADRESS",
			name: "HEADRESS",
			qty: 0
		},
		{
			id: "PEGANGAN_TANGAN",
			name: "PEGANGAN TANGAN",
			qty: 0
		},
		{
			id: "WANGI-WANGIAN",
			name: "WANGI-WANGIAN",
			qty: 0
		},
		{
			id: "ASBAK",
			name: "ASBAK",
			qty: 0
		},
		{
			id: "BANTAL",
			name: "BANTAL",
			qty: 0
		},
		{
			id: "KUNCI_STANG",
			name: "KUNCI STANG",
			qty: 0
		},
		{
			id: "KARPET_ALAS_KAKI",
			name: "KARPET ALAS KAKI",
			qty: 0
		},
		{
			id: "CHARGER_HP",
			name: "CHARGER HP",
			qty: 0
		},
		{
			id: "CD",
			name: "CD",
			qty: 0
		},
		{
			id: "PAYUNG",
			name: "PAYUNG",
			qty: 0
		},
		{
			id: "KOTAK_OBAT",
			name: "KOTAK OBAT",
			qty: 0
		},
		{
			id: "BULU_AYAM",
			name: "BULU AYAM",
			qty: 0
		},
		{
			id: "KARTU_ELEKTRONIK",
			name: "KARTU ELEKTRONIK",
			qty: 0
		},
	]

	const [data, setData] = useState(initialJSON);

	async function onValueChanges(idToChange:string, value:number) {
		// return null;
		const index = data.findIndex(
			(datum) => datum['id'] === idToChange
		)
		if (index>=0) {
			let newData = [...data];
			if(value===data[index]['qty']) {
				newData[index] = {...newData[index], qty: 0};
			} else {
				newData[index] = {...newData[index], qty: value};
			}
			await setData(newData);
		}
		console.log(data);
		console.log(index);
	}

	function getSelectedTrueFalse(idToLookFor:string, value:number) {
		// return false;
		const result = data.find(
			(datum) => datum['id'] === idToLookFor && value === datum['qty']
		)
		console.log("result: ", result);
		if (result) return true;
		return false;
	}
	
	const {config, handleHeightChange} = useLayout();

    
    return (
		<PageAnimate>
			            <PageTitle breadcrumbs={BreadCrumbs}>Checklist</PageTitle>

                {/* <!--begin::Toolbar--> */}
						<Umum onHeightChange={handleHeightChange} topButtons={<DefaultKlaimButtons />}>
							{/* <!--begin::Container--> */}
							<div id="kt_toolbar_container" className=" d-flex flex-wrap flex-stack flex-grow flex-fill">
								{/* <!--begin::Page title--> */}
								<div data-kt-swapper="false" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
									{/* <!--begin::Title--> */}
									{/* <!--begin::Trigger--> */}
									<button type="button" className="btn btn-light"
										data-kt-menu-trigger="click"
										data-kt-menu-placement="bottom-start">
										Checklist
										<span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
									</button>
									{/* <!--end::Trigger--> */}

									{/* <!--begin::Menu--> */}
									<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
										data-kt-menu="true">
										{detailKlaimRoutes.map((routeLink) => {
											return <>
											{/* <!--begin::Menu item--> */}
											<div className="menu-item px-3">
												<Link to={routeLink.path} className="menu-link px-3">
													{routeLink.label}
												</Link>
											</div>
											{/* <!--end::Menu item--> */}
											</>
										})}
														</div>
									{/* <!--end::Menu--> */}

									{/* <!--end::Title--> */}
									
								</div>
								{/* <!--end::Page title--> */}
								{/* <!--begin::Action group--> */}
								<div className="d-flex align-items-center overflow-auto">
									{/* <!--begin::Wrapper--> */}
									<div className="d-flex align-items-center flex-shrink-0">
										
										<div className="flex-shrink-0">
										<NotesButton />
                                            <a href="#" className="btn btn-sm btn-primary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="kt_toolbar_primary_button">EDIT</a>
											<Link to="/klaim/new/estimasi/a" className="btn btn-sm btn-success me-lg-1" >OK</Link>
											
										</div>
									</div>
									{/* <!--end::Wrapper--> */}
									
								</div>
								{/* <!--end::Action group--> */}
							</div>
							
							{/* <!--end::Container--> */}
						</Umum>

						{/* <!--end::Toolbar--> */}
						{/* <!--begin::Post--> */}
						<div className="post d-flex flex-column-fluid" id="kt_post">
							{/* <!--begin::Container--> */}
							<div id="kt_content_container" className="container-fluid">
								{/* <!--begin::Tables Widget 13--> */}
								<div className="card mb-5 mb-xl-8">
										{/* <!--begin::Body--> */}
										<div className="card-body py-3">
											{/* <!--begin::Table container--> */}
											<div className="row">
												
												<div className=" col-sm-6 ">
													<div className='headersticky'>
													<h3>BARANG</h3>
													</div>
													{/* <!--begin::Table--> */}
													<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 col-sm-6">
														{/* <!--begin::Table head--> */}
														<thead>
															<tr className="fw-bolder">
																
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>No</th>
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>Keterangan</th>
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>1</th>
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>2</th>
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>3</th>
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>4</th>
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>5</th>
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>Jumlah</th>
															</tr>
														</thead>
														{/* <!--end::Table head--> */}
														{/* <!--begin::Table body--> */}
														<tbody>
														{data.map((singleData, index) => (
															<ChecklistItem data={singleData} onValueChange={onValueChanges} getSelected={getSelectedTrueFalse} index={index+1} />
														))}
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"></a>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">LAIN LAIN</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6" colSpan={5}>
																	<a href="#" className="btn btn-sm btn-secondary me-lg-1" data-bs-toggle="modal" data-bs-target="#kt_modal_3" id="kt_toolbar_primary_button">Tambah List </a>
																</td>
															</tr>
														</tbody>
														{/* <!--end::Table body--> */}
													</table>
													
													{/* <!--end::Table--> */}
												</div>
												<div className=" col-sm-6 px-20">
													<div className='headersticky'>
													<h3>Kelistrikan</h3>
													</div>
													{/* <!--begin::Table--> */}
													<Table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 col-sm-6">
														{/* <!--begin::Table head--> */}
														<thead>
															<tr className="fw-bolder ">
																
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>No</th>
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>Keterangan</th>
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>BAIK</th>
																<th className="min-w-140px checklist_th" style={{position : 'sticky', top: 340, background: "white"}}>TIDAK</th>
															</tr>
														</thead>
														{/* <!--end::Table head--> */}
														{/* <!--begin::Table body--> */}
														<tbody>
															<tr>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">1</a>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6"> 
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">LAMPU BODY</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="1" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="1" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
																
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">2</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">LAMPU KABIN</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="2" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="2" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">3</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">KLAKSON</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="3" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="3" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">4</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">WIPER KACA</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="4" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="4" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">5</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">ALARM</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="5" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="5" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">6</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">RADIO + TAPE</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="6" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="6" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">7</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">REMOTE SPION</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="7" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="7" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">8</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">AC</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="8" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="8" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">9</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">POWER WINDOW</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="9" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="9" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">10</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">CENTRAL LOCK</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="10" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="10" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">11</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">KACA FILM</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="11" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="11" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary fs-6">12</a>
																</td>
																<td>
																	<a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">CHARGER SOCKET</a>
																	{/* <!-- <span className="text-muted fw-bold text-muted d-block fs-7">Code: PH</span> --> */}
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="12" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			BAIK
																		</label>
																	</div>
																</td>
																<td className="text-dark fw-bolder text-hover-primary fs-6">
																	<div className="form-check form-check-custom form-check-solid">
																		<input className="form-check-input" name="12" type="radio" value="" id="flexRadioSm"  />
																		<label className="form-check-label" htmlFor="flexCheckDefault">
																			TIDAK
																		</label>
																	</div>
																</td>
															</tr>
														</tbody>
														{/* <!--end::Table body--> */}
													</Table>
													
													{/* <!--end::Table--> */}
												</div>
											</div>
											
											
											{/* <!--end::Table container--> */}
										</div>
										
										{/* <!--begin::Body--> */}
									</div>
									
									{/* <!--end::Tables Widget 13--> */}
							</div>
							{/* <!--end::Container--> */}
						</div>
						{/* <!--end::Post--> */}
                        <ChecklistLain/>
						</PageAnimate>
    )
}
