import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import './styles/plugins/global/plugins.bundle';
// import './styles/style.bundle';
import "bootstrap/dist/js/bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/sass/style.scss'
import './styles/sass/style.react.scss'
import './styles/sass/plugins.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.scss';
import {Provider} from 'react-redux';
import store from './store/index';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
