import React, { useState, createContext, useContext } from 'react';
import { CreateModal } from '../components/modals/ModalCreate';
import NotesBaru from '../components/modals/NotesBaru'
import DeleteConfirmation from '../components/modals/DeleteConfirmation'
import  ModalWrapper from '../components/modals/ModalWrapper';
import DataA from '../components/modals/DataA'
import DownloadWO from '../components/modals/DownloadWO';
import PrintWO from '../components/modals/PrintWO';
import NotesWO from '../components/modals/NotesWO';
import ImageUploadModal from '../components/modals/ImageUploadModal';
import InsertSparepartModal from '../components/modals/InserSparepartModal';
import InsertJasaModal from '../components/modals/InsertJasaModal';
import InsertAsuransi from '../components/modals/InsertAsuransiModal';
import InsertAgenModal from '../components/modals/InsertAgenModal';
import ToDoListDetail from '../components/modals/ToDoListDetail';


export const MODAL_TYPES = {
 "CREATE_MODAL":'CREATE_MODAL',
 "DATA_A_MODAL": 'DATA_A_MODAL',
 "DOWNLOAD_WO": 'DOWNLOAD_WO',
 "PRINT_WO": 'PRINT_WO',
 "NOTES_WO": 'NOTES_WO',
 "IMAGE_UPLOAD": 'IMAGE_UPLOAD',
 "DELETE_CONFIRMATION": "DELETE_CONFIRMATION",
 "INSERT_SPAREPART_MODAL": "INSERT_SPAREPART_MODAL",
 "INSERT_JASA_MODAL": "INSERT_JASA_MODAL",
 "INSERT_ASURANSI_MODAL":"INSERT_ASURANSI_MODAL",
 "INSERT_AGEN_MODAL":"INSERT_AGEN_MODAL",
 "TODOLIST_DETAIL":"TODOLIST_DETAIL",
};

const MODAL_COMPONENTS: any = {
 [MODAL_TYPES.CREATE_MODAL]: NotesBaru,
 [MODAL_TYPES.DATA_A_MODAL]: DataA,
 [MODAL_TYPES.DOWNLOAD_WO]: DownloadWO,
 [MODAL_TYPES.PRINT_WO]: PrintWO,
 [MODAL_TYPES.NOTES_WO]: NotesWO,
 [MODAL_TYPES.IMAGE_UPLOAD]: ImageUploadModal,
 [MODAL_TYPES.DELETE_CONFIRMATION]:DeleteConfirmation,
 [MODAL_TYPES.INSERT_SPAREPART_MODAL]:InsertSparepartModal,
 [MODAL_TYPES.INSERT_JASA_MODAL]:InsertJasaModal,
 [MODAL_TYPES.INSERT_ASURANSI_MODAL]:InsertAsuransi,
 [MODAL_TYPES.INSERT_AGEN_MODAL]:InsertAgenModal,
 [MODAL_TYPES.TODOLIST_DETAIL]:ToDoListDetail,
};

type GlobalModalContext = {
 showModal: (modalType: string, modalProps?: any) => void;
 hideModal: () => void;
 store: any;
};

const initalState: GlobalModalContext = {
 showModal: () => {},
 hideModal: () => {},
 store: {},
};


interface defaultModalValue {
    modalType?: string|null
    modalProps?: any|null
}

const defaultModalStore:defaultModalValue = {
    modalType: null,
    modalProps: null
}

const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC<{}> = ({ children }) => {
 const [store, setStore] = useState(defaultModalStore);
 const { modalType, modalProps } = store || defaultModalStore;

 const showModal = (modalType: string, modalProps: any = {}) => {
   setStore({
     ...store,
     modalType,
     modalProps,
   });
   console.log(store);
 };

 const hideModal = () => {
   setStore({
     ...store,
     modalType: null,
     modalProps: {},
   });
 };

 const renderComponent = () => {
   const ModalComponent = MODAL_COMPONENTS[modalType!];
   if (!modalType || !ModalComponent) {
     return null;
   }
   return <ModalWrapper><ModalComponent id="global-modal" {...modalProps} showGlobal={true}/></ModalWrapper>;
 };

 return (
   <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
     {renderComponent()}
     {children}
   </GlobalModalContext.Provider>
 );
};