import React from 'react';
import { MODAL_TYPES, useGlobalModalContext } from '../../context/GlobalModals';

export default function EstimasiButtons() {

    const {showModal} = useGlobalModalContext();


    function handleInfoDetail(){
        showModal(MODAL_TYPES.DATA_A_MODAL, {});
    }

    function handleDownload(){
        showModal(MODAL_TYPES.DOWNLOAD_WO, {});
    }
    function handlePrint(){
        showModal(MODAL_TYPES.PRINT_WO, {});
    }


    return <>
            <div className="d-flex mb-4 me-auto">
            <button className="btn btn-sm btn-bg-light btn-active-color-primary me-3" onClick={handleDownload}>Download</button>
            <button className="btn btn-sm btn-bg-light btn-active-color-primary me-3" onClick={handlePrint}>Print</button>
            <button className="btn btn-sm btn-bg-light btn-active-color-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Duplikat</button>
            <button className="btn btn-sm btn-bg-light btn-active-color-primary me-3" onClick={handleInfoDetail}>Info Detail</button>
            </div>
            <div className="d-flex">
            {/* <!--begin::Trigger--> */}
            <button type="button" className="btn btn-light"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start">
                Pakai Estimasi A
                <span className="svg-icon svg-icon-5 rotate-180 ms-3 me-0">▼</span>
            </button>
            {/* <!--end::Trigger--> */}

            {/* <!--begin::Menu--> */}
            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                data-kt-menu="true">
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                        Estimasi B
                </div>
                {/* <!--end::Menu item--> */}

                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                        Estimasi C
                </div>
                {/* <!--end::Menu item--> */}
            </div>
            {/* <!--end::Menu--> */}
        </div>
        {/* <!--end::Actions--> */}
        </>;
}
