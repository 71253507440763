import {IPageRoute} from './types'

export const masterDataRoutes:IPageRoute[] = [
    {
        label: 'JASA',
        path: '/database/jasa',
    },
    {
        label: 'SPAREPART',
        path: '/database/sparepart',
    },
    {
        label: 'ASURANSI',
        path: '/database/asuransi',
    },
    {
        label: 'AGEN',
        path: '/database/agen',
    },
    {
        label: 'KENDARAAN',
        path: '/database/kendaraan',
    },
]